// generated with @7nohe/openapi-react-query-codegen@1.6.1 

import { UseQueryResult } from "@tanstack/react-query";
import { AccountStatementService, AppPersonalizationsService, AppVariablesService, ArticlesService, CatalogsService, CheckUserPermissionService, ClientFlowSearchService, ClientListSearchService, ClientSearchService, ContactDetailsService, DemographicDataService, DownloadedDataService, DynamicNamesService, EmploymentDataService, FieldsService, FlowDocumentsService, FlowStageDocumentsService, FlowStagesService, FormatsService, GenerateLetterService, GroupsService, GuaranteeOwnersService, GuaranteesService, HolidaysService, ImportService, LiabilitiesService, ManagementService, MonthlyPaymentsService, NotificationsService, OperatingFlowsService, OrderItemsService, OtherPersonsService, PaymentAgreementsService, PersonalReferencesService, PersonsAddendumService, PreviewSegmentationService, PromisesService, QuotesService, RequestService, ReschedulesService, RolesPermissionsService, RolesService, StrategiesService, StrategyFormatsService, UrlsService, UsersService } from "../requests/services.gen";
export type AccountStatementServiceGetAccountStatementDefaultResponse = Awaited<ReturnType<typeof AccountStatementService.getAccountStatement>>;
export type AccountStatementServiceGetAccountStatementQueryResult<TData = AccountStatementServiceGetAccountStatementDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAccountStatementServiceGetAccountStatementKey = "AccountStatementServiceGetAccountStatement";
export const UseAccountStatementServiceGetAccountStatementKeyFn = ({ cia, cliente, dataSource, ffinal, finicio, grupo, outFilename, reportEncoding, reportFormat, reportLocale, reportName, xDbCatalog }: {
  cia: string;
  cliente: string;
  dataSource: string;
  ffinal: string;
  finicio: string;
  grupo: string;
  outFilename?: string;
  reportEncoding: string;
  reportFormat: string;
  reportLocale: string;
  reportName: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useAccountStatementServiceGetAccountStatementKey, ...(queryKey ?? [{ cia, cliente, dataSource, ffinal, finicio, grupo, outFilename, reportEncoding, reportFormat, reportLocale, reportName, xDbCatalog }])];
export type AppPersonalizationsServiceGetAppPersonalizationsDefaultResponse = Awaited<ReturnType<typeof AppPersonalizationsService.getAppPersonalizations>>;
export type AppPersonalizationsServiceGetAppPersonalizationsQueryResult<TData = AppPersonalizationsServiceGetAppPersonalizationsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAppPersonalizationsServiceGetAppPersonalizationsKey = "AppPersonalizationsServiceGetAppPersonalizations";
export const UseAppPersonalizationsServiceGetAppPersonalizationsKeyFn = ({ sequential, xDbCatalog }: {
  sequential: number;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useAppPersonalizationsServiceGetAppPersonalizationsKey, ...(queryKey ?? [{ sequential, xDbCatalog }])];
export type AppVariablesServiceGetAppVariablesDefaultResponse = Awaited<ReturnType<typeof AppVariablesService.getAppVariables>>;
export type AppVariablesServiceGetAppVariablesQueryResult<TData = AppVariablesServiceGetAppVariablesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAppVariablesServiceGetAppVariablesKey = "AppVariablesServiceGetAppVariables";
export const UseAppVariablesServiceGetAppVariablesKeyFn = ({ id, xDbCatalog }: {
  id: number;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useAppVariablesServiceGetAppVariablesKey, ...(queryKey ?? [{ id, xDbCatalog }])];
export type AppVariablesServiceGetAppVariablesByNameDefaultResponse = Awaited<ReturnType<typeof AppVariablesService.getAppVariablesByName>>;
export type AppVariablesServiceGetAppVariablesByNameQueryResult<TData = AppVariablesServiceGetAppVariablesByNameDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAppVariablesServiceGetAppVariablesByNameKey = "AppVariablesServiceGetAppVariablesByName";
export const UseAppVariablesServiceGetAppVariablesByNameKeyFn = ({ name, xDbCatalog }: {
  name: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useAppVariablesServiceGetAppVariablesByNameKey, ...(queryKey ?? [{ name, xDbCatalog }])];
export type AppVariablesServiceGetAppVariablesByTypeDefaultResponse = Awaited<ReturnType<typeof AppVariablesService.getAppVariablesByType>>;
export type AppVariablesServiceGetAppVariablesByTypeQueryResult<TData = AppVariablesServiceGetAppVariablesByTypeDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAppVariablesServiceGetAppVariablesByTypeKey = "AppVariablesServiceGetAppVariablesByType";
export const UseAppVariablesServiceGetAppVariablesByTypeKeyFn = ({ type, xDbCatalog }: {
  type: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useAppVariablesServiceGetAppVariablesByTypeKey, ...(queryKey ?? [{ type, xDbCatalog }])];
export type AppVariablesServiceGetAppVariablesByTypeAndUserIdDefaultResponse = Awaited<ReturnType<typeof AppVariablesService.getAppVariablesByTypeAndUserId>>;
export type AppVariablesServiceGetAppVariablesByTypeAndUserIdQueryResult<TData = AppVariablesServiceGetAppVariablesByTypeAndUserIdDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAppVariablesServiceGetAppVariablesByTypeAndUserIdKey = "AppVariablesServiceGetAppVariablesByTypeAndUserId";
export const UseAppVariablesServiceGetAppVariablesByTypeAndUserIdKeyFn = ({ type, userid, xDbCatalog }: {
  type: string;
  userid: number;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useAppVariablesServiceGetAppVariablesByTypeAndUserIdKey, ...(queryKey ?? [{ type, userid, xDbCatalog }])];
export type ArticlesServiceGetArticlesDefaultResponse = Awaited<ReturnType<typeof ArticlesService.getArticles>>;
export type ArticlesServiceGetArticlesQueryResult<TData = ArticlesServiceGetArticlesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useArticlesServiceGetArticlesKey = "ArticlesServiceGetArticles";
export const UseArticlesServiceGetArticlesKeyFn = ({ clientCode, xDbCatalog }: {
  clientCode: number;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useArticlesServiceGetArticlesKey, ...(queryKey ?? [{ clientCode, xDbCatalog }])];
export type CatalogsServiceGetCatalogsByStatusDefaultResponse = Awaited<ReturnType<typeof CatalogsService.getCatalogsByStatus>>;
export type CatalogsServiceGetCatalogsByStatusQueryResult<TData = CatalogsServiceGetCatalogsByStatusDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCatalogsServiceGetCatalogsByStatusKey = "CatalogsServiceGetCatalogsByStatus";
export const UseCatalogsServiceGetCatalogsByStatusKeyFn = ({ status, xDbCatalog }: {
  status: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useCatalogsServiceGetCatalogsByStatusKey, ...(queryKey ?? [{ status, xDbCatalog }])];
export type CatalogsServiceGetCatalogsByDescriptionDefaultResponse = Awaited<ReturnType<typeof CatalogsService.getCatalogsByDescription>>;
export type CatalogsServiceGetCatalogsByDescriptionQueryResult<TData = CatalogsServiceGetCatalogsByDescriptionDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCatalogsServiceGetCatalogsByDescriptionKey = "CatalogsServiceGetCatalogsByDescription";
export const UseCatalogsServiceGetCatalogsByDescriptionKeyFn = ({ description, xDbCatalog }: {
  description: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useCatalogsServiceGetCatalogsByDescriptionKey, ...(queryKey ?? [{ description, xDbCatalog }])];
export type CatalogsServiceGetCatalogsGroupByColumnDefaultResponse = Awaited<ReturnType<typeof CatalogsService.getCatalogsGroupByColumn>>;
export type CatalogsServiceGetCatalogsGroupByColumnQueryResult<TData = CatalogsServiceGetCatalogsGroupByColumnDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCatalogsServiceGetCatalogsGroupByColumnKey = "CatalogsServiceGetCatalogsGroupByColumn";
export const UseCatalogsServiceGetCatalogsGroupByColumnKeyFn = ({ column, xDbCatalog }: {
  column: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useCatalogsServiceGetCatalogsGroupByColumnKey, ...(queryKey ?? [{ column, xDbCatalog }])];
export type CatalogsServiceGetCatalogsDefaultResponse = Awaited<ReturnType<typeof CatalogsService.getCatalogs>>;
export type CatalogsServiceGetCatalogsQueryResult<TData = CatalogsServiceGetCatalogsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCatalogsServiceGetCatalogsKey = "CatalogsServiceGetCatalogs";
export const UseCatalogsServiceGetCatalogsKeyFn = ({ type, xDbCatalog }: {
  type: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useCatalogsServiceGetCatalogsKey, ...(queryKey ?? [{ type, xDbCatalog }])];
export type CheckUserPermissionServiceGetCheckUserPermissionDefaultResponse = Awaited<ReturnType<typeof CheckUserPermissionService.getCheckUserPermission>>;
export type CheckUserPermissionServiceGetCheckUserPermissionQueryResult<TData = CheckUserPermissionServiceGetCheckUserPermissionDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useCheckUserPermissionServiceGetCheckUserPermissionKey = "CheckUserPermissionServiceGetCheckUserPermission";
export const UseCheckUserPermissionServiceGetCheckUserPermissionKeyFn = ({ permissionId, userName, xDbCatalog }: {
  permissionId?: string;
  userName?: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useCheckUserPermissionServiceGetCheckUserPermissionKey, ...(queryKey ?? [{ permissionId, userName, xDbCatalog }])];
export type ClientFlowSearchServiceGetClientFlowSearchDefaultResponse = Awaited<ReturnType<typeof ClientFlowSearchService.getClientFlowSearch>>;
export type ClientFlowSearchServiceGetClientFlowSearchQueryResult<TData = ClientFlowSearchServiceGetClientFlowSearchDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useClientFlowSearchServiceGetClientFlowSearchKey = "ClientFlowSearchServiceGetClientFlowSearch";
export const UseClientFlowSearchServiceGetClientFlowSearchKeyFn = ({ client, contract, flowType, identification, name, xDbCatalog }: {
  client?: string;
  contract?: string;
  flowType?: string;
  identification?: string;
  name?: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useClientFlowSearchServiceGetClientFlowSearchKey, ...(queryKey ?? [{ client, contract, flowType, identification, name, xDbCatalog }])];
export type ClientListSearchServiceGetClientListSearchDefaultResponse = Awaited<ReturnType<typeof ClientListSearchService.getClientListSearch>>;
export type ClientListSearchServiceGetClientListSearchQueryResult<TData = ClientListSearchServiceGetClientListSearchDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useClientListSearchServiceGetClientListSearchKey = "ClientListSearchServiceGetClientListSearch";
export const UseClientListSearchServiceGetClientListSearchKeyFn = ({ strategyCode, xDbCatalog }: {
  strategyCode?: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useClientListSearchServiceGetClientListSearchKey, ...(queryKey ?? [{ strategyCode, xDbCatalog }])];
export type ClientSearchServiceGetClientSearchDefaultResponse = Awaited<ReturnType<typeof ClientSearchService.getClientSearch>>;
export type ClientSearchServiceGetClientSearchQueryResult<TData = ClientSearchServiceGetClientSearchDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useClientSearchServiceGetClientSearchKey = "ClientSearchServiceGetClientSearch";
export const UseClientSearchServiceGetClientSearchKeyFn = ({ client, clientType, contactInfo, contract, currentBalance, daysLate, delinquentBalance, identification, name, pageNumber, productType, promise, rowsPerPage, status1, status2, workplace, xDbCatalog }: {
  client?: string;
  clientType?: string;
  contactInfo?: string;
  contract?: string;
  currentBalance?: string;
  daysLate?: string;
  delinquentBalance?: string;
  identification?: string;
  name?: string;
  pageNumber?: number;
  productType?: string;
  promise?: string;
  rowsPerPage?: number;
  status1?: string;
  status2?: string;
  workplace?: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useClientSearchServiceGetClientSearchKey, ...(queryKey ?? [{ client, clientType, contactInfo, contract, currentBalance, daysLate, delinquentBalance, identification, name, pageNumber, productType, promise, rowsPerPage, status1, status2, workplace, xDbCatalog }])];
export type ContactDetailsServiceGetContactDetailsDefaultResponse = Awaited<ReturnType<typeof ContactDetailsService.getContactDetails>>;
export type ContactDetailsServiceGetContactDetailsQueryResult<TData = ContactDetailsServiceGetContactDetailsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useContactDetailsServiceGetContactDetailsKey = "ContactDetailsServiceGetContactDetails";
export const UseContactDetailsServiceGetContactDetailsKeyFn = ({ client, xDbCatalog }: {
  client: number;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useContactDetailsServiceGetContactDetailsKey, ...(queryKey ?? [{ client, xDbCatalog }])];
export type DemographicDataServiceGetDemographicDataDefaultResponse = Awaited<ReturnType<typeof DemographicDataService.getDemographicData>>;
export type DemographicDataServiceGetDemographicDataQueryResult<TData = DemographicDataServiceGetDemographicDataDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useDemographicDataServiceGetDemographicDataKey = "DemographicDataServiceGetDemographicData";
export const UseDemographicDataServiceGetDemographicDataKeyFn = ({ client, xDbCatalog }: {
  client: number;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useDemographicDataServiceGetDemographicDataKey, ...(queryKey ?? [{ client, xDbCatalog }])];
export type DownloadedDataServiceGetDownloadedDataByPageNumberDefaultResponse = Awaited<ReturnType<typeof DownloadedDataService.getDownloadedDataByPageNumber>>;
export type DownloadedDataServiceGetDownloadedDataByPageNumberQueryResult<TData = DownloadedDataServiceGetDownloadedDataByPageNumberDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useDownloadedDataServiceGetDownloadedDataByPageNumberKey = "DownloadedDataServiceGetDownloadedDataByPageNumber";
export const UseDownloadedDataServiceGetDownloadedDataByPageNumberKeyFn = ({ pageNumber, resultsPerPage, xDbCatalog }: {
  pageNumber?: number;
  resultsPerPage?: number;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useDownloadedDataServiceGetDownloadedDataByPageNumberKey, ...(queryKey ?? [{ pageNumber, resultsPerPage, xDbCatalog }])];
export type DownloadedDataServiceGetDownloadedDataByContractDefaultResponse = Awaited<ReturnType<typeof DownloadedDataService.getDownloadedDataByContract>>;
export type DownloadedDataServiceGetDownloadedDataByContractQueryResult<TData = DownloadedDataServiceGetDownloadedDataByContractDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useDownloadedDataServiceGetDownloadedDataByContractKey = "DownloadedDataServiceGetDownloadedDataByContract";
export const UseDownloadedDataServiceGetDownloadedDataByContractKeyFn = ({ contract, xDbCatalog }: {
  contract: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useDownloadedDataServiceGetDownloadedDataByContractKey, ...(queryKey ?? [{ contract, xDbCatalog }])];
export type DownloadedDataServiceGetDownloadedDataByIdentificationDefaultResponse = Awaited<ReturnType<typeof DownloadedDataService.getDownloadedDataByIdentification>>;
export type DownloadedDataServiceGetDownloadedDataByIdentificationQueryResult<TData = DownloadedDataServiceGetDownloadedDataByIdentificationDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useDownloadedDataServiceGetDownloadedDataByIdentificationKey = "DownloadedDataServiceGetDownloadedDataByIdentification";
export const UseDownloadedDataServiceGetDownloadedDataByIdentificationKeyFn = ({ identification, xDbCatalog }: {
  identification: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useDownloadedDataServiceGetDownloadedDataByIdentificationKey, ...(queryKey ?? [{ identification, xDbCatalog }])];
export type DownloadedDataServiceGetDownloadedDataByNameDefaultResponse = Awaited<ReturnType<typeof DownloadedDataService.getDownloadedDataByName>>;
export type DownloadedDataServiceGetDownloadedDataByNameQueryResult<TData = DownloadedDataServiceGetDownloadedDataByNameDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useDownloadedDataServiceGetDownloadedDataByNameKey = "DownloadedDataServiceGetDownloadedDataByName";
export const UseDownloadedDataServiceGetDownloadedDataByNameKeyFn = ({ name, xDbCatalog }: {
  name: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useDownloadedDataServiceGetDownloadedDataByNameKey, ...(queryKey ?? [{ name, xDbCatalog }])];
export type DownloadedDataServiceGetDownloadedDataByOriginalBalanceDefaultResponse = Awaited<ReturnType<typeof DownloadedDataService.getDownloadedDataByOriginalBalance>>;
export type DownloadedDataServiceGetDownloadedDataByOriginalBalanceQueryResult<TData = DownloadedDataServiceGetDownloadedDataByOriginalBalanceDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useDownloadedDataServiceGetDownloadedDataByOriginalBalanceKey = "DownloadedDataServiceGetDownloadedDataByOriginalBalance";
export const UseDownloadedDataServiceGetDownloadedDataByOriginalBalanceKeyFn = ({ originalBalance, xDbCatalog }: {
  originalBalance: number;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useDownloadedDataServiceGetDownloadedDataByOriginalBalanceKey, ...(queryKey ?? [{ originalBalance, xDbCatalog }])];
export type DownloadedDataServiceGetDownloadedDataByCurrentBalanceDefaultResponse = Awaited<ReturnType<typeof DownloadedDataService.getDownloadedDataByCurrentBalance>>;
export type DownloadedDataServiceGetDownloadedDataByCurrentBalanceQueryResult<TData = DownloadedDataServiceGetDownloadedDataByCurrentBalanceDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useDownloadedDataServiceGetDownloadedDataByCurrentBalanceKey = "DownloadedDataServiceGetDownloadedDataByCurrentBalance";
export const UseDownloadedDataServiceGetDownloadedDataByCurrentBalanceKeyFn = ({ currentBalance, xDbCatalog }: {
  currentBalance: number;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useDownloadedDataServiceGetDownloadedDataByCurrentBalanceKey, ...(queryKey ?? [{ currentBalance, xDbCatalog }])];
export type DownloadedDataServiceGetDownloadedDataByPastDueBalanceDefaultResponse = Awaited<ReturnType<typeof DownloadedDataService.getDownloadedDataByPastDueBalance>>;
export type DownloadedDataServiceGetDownloadedDataByPastDueBalanceQueryResult<TData = DownloadedDataServiceGetDownloadedDataByPastDueBalanceDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useDownloadedDataServiceGetDownloadedDataByPastDueBalanceKey = "DownloadedDataServiceGetDownloadedDataByPastDueBalance";
export const UseDownloadedDataServiceGetDownloadedDataByPastDueBalanceKeyFn = ({ pastDueBalance, xDbCatalog }: {
  pastDueBalance: number;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useDownloadedDataServiceGetDownloadedDataByPastDueBalanceKey, ...(queryKey ?? [{ pastDueBalance, xDbCatalog }])];
export type DownloadedDataServiceGetDownloadedDataByPastDueDaysDefaultResponse = Awaited<ReturnType<typeof DownloadedDataService.getDownloadedDataByPastDueDays>>;
export type DownloadedDataServiceGetDownloadedDataByPastDueDaysQueryResult<TData = DownloadedDataServiceGetDownloadedDataByPastDueDaysDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useDownloadedDataServiceGetDownloadedDataByPastDueDaysKey = "DownloadedDataServiceGetDownloadedDataByPastDueDays";
export const UseDownloadedDataServiceGetDownloadedDataByPastDueDaysKeyFn = ({ pastDueDays, xDbCatalog }: {
  pastDueDays: number;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useDownloadedDataServiceGetDownloadedDataByPastDueDaysKey, ...(queryKey ?? [{ pastDueDays, xDbCatalog }])];
export type DownloadedDataServiceGetDownloadedDataByPastDueRangeDefaultResponse = Awaited<ReturnType<typeof DownloadedDataService.getDownloadedDataByPastDueRange>>;
export type DownloadedDataServiceGetDownloadedDataByPastDueRangeQueryResult<TData = DownloadedDataServiceGetDownloadedDataByPastDueRangeDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useDownloadedDataServiceGetDownloadedDataByPastDueRangeKey = "DownloadedDataServiceGetDownloadedDataByPastDueRange";
export const UseDownloadedDataServiceGetDownloadedDataByPastDueRangeKeyFn = ({ pastduerange, xDbCatalog }: {
  pastduerange: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useDownloadedDataServiceGetDownloadedDataByPastDueRangeKey, ...(queryKey ?? [{ pastduerange, xDbCatalog }])];
export type DownloadedDataServiceGetDownloadedDataByLastPaymentDateDefaultResponse = Awaited<ReturnType<typeof DownloadedDataService.getDownloadedDataByLastPaymentDate>>;
export type DownloadedDataServiceGetDownloadedDataByLastPaymentDateQueryResult<TData = DownloadedDataServiceGetDownloadedDataByLastPaymentDateDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useDownloadedDataServiceGetDownloadedDataByLastPaymentDateKey = "DownloadedDataServiceGetDownloadedDataByLastPaymentDate";
export const UseDownloadedDataServiceGetDownloadedDataByLastPaymentDateKeyFn = ({ lastPaymentDate, xDbCatalog }: {
  lastPaymentDate: Date;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useDownloadedDataServiceGetDownloadedDataByLastPaymentDateKey, ...(queryKey ?? [{ lastPaymentDate, xDbCatalog }])];
export type DownloadedDataServiceGetDownloadedDataByWorkplaceDefaultResponse = Awaited<ReturnType<typeof DownloadedDataService.getDownloadedDataByWorkplace>>;
export type DownloadedDataServiceGetDownloadedDataByWorkplaceQueryResult<TData = DownloadedDataServiceGetDownloadedDataByWorkplaceDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useDownloadedDataServiceGetDownloadedDataByWorkplaceKey = "DownloadedDataServiceGetDownloadedDataByWorkplace";
export const UseDownloadedDataServiceGetDownloadedDataByWorkplaceKeyFn = ({ workplace, xDbCatalog }: {
  workplace: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useDownloadedDataServiceGetDownloadedDataByWorkplaceKey, ...(queryKey ?? [{ workplace, xDbCatalog }])];
export type DownloadedDataServiceGetDownloadedDataByPhonesDefaultResponse = Awaited<ReturnType<typeof DownloadedDataService.getDownloadedDataByPhones>>;
export type DownloadedDataServiceGetDownloadedDataByPhonesQueryResult<TData = DownloadedDataServiceGetDownloadedDataByPhonesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useDownloadedDataServiceGetDownloadedDataByPhonesKey = "DownloadedDataServiceGetDownloadedDataByPhones";
export const UseDownloadedDataServiceGetDownloadedDataByPhonesKeyFn = ({ phones, xDbCatalog }: {
  phones: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useDownloadedDataServiceGetDownloadedDataByPhonesKey, ...(queryKey ?? [{ phones, xDbCatalog }])];
export type DynamicNamesServiceGetDynamicNamesDefaultResponse = Awaited<ReturnType<typeof DynamicNamesService.getDynamicNames>>;
export type DynamicNamesServiceGetDynamicNamesQueryResult<TData = DynamicNamesServiceGetDynamicNamesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useDynamicNamesServiceGetDynamicNamesKey = "DynamicNamesServiceGetDynamicNames";
export const UseDynamicNamesServiceGetDynamicNamesKeyFn = ({ key, xDbCatalog }: {
  key: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useDynamicNamesServiceGetDynamicNamesKey, ...(queryKey ?? [{ key, xDbCatalog }])];
export type EmploymentDataServiceGetEmploymentDataDefaultResponse = Awaited<ReturnType<typeof EmploymentDataService.getEmploymentData>>;
export type EmploymentDataServiceGetEmploymentDataQueryResult<TData = EmploymentDataServiceGetEmploymentDataDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useEmploymentDataServiceGetEmploymentDataKey = "EmploymentDataServiceGetEmploymentData";
export const UseEmploymentDataServiceGetEmploymentDataKeyFn = ({ client, xDbCatalog }: {
  client: number;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useEmploymentDataServiceGetEmploymentDataKey, ...(queryKey ?? [{ client, xDbCatalog }])];
export type FieldsServiceGetFieldsDefaultResponse = Awaited<ReturnType<typeof FieldsService.getFields>>;
export type FieldsServiceGetFieldsQueryResult<TData = FieldsServiceGetFieldsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useFieldsServiceGetFieldsKey = "FieldsServiceGetFields";
export const UseFieldsServiceGetFieldsKeyFn = ({ xDbCatalog }: {
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useFieldsServiceGetFieldsKey, ...(queryKey ?? [{ xDbCatalog }])];
export type FlowDocumentsServiceGetFlowDocumentsDefaultResponse = Awaited<ReturnType<typeof FlowDocumentsService.getFlowDocuments>>;
export type FlowDocumentsServiceGetFlowDocumentsQueryResult<TData = FlowDocumentsServiceGetFlowDocumentsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useFlowDocumentsServiceGetFlowDocumentsKey = "FlowDocumentsServiceGetFlowDocuments";
export const UseFlowDocumentsServiceGetFlowDocumentsKeyFn = ({ client, contract, flow, flowDate, xDbCatalog }: {
  client?: number;
  contract?: string;
  flow?: string;
  flowDate?: Date;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useFlowDocumentsServiceGetFlowDocumentsKey, ...(queryKey ?? [{ client, contract, flow, flowDate, xDbCatalog }])];
export type FlowStageDocumentsServiceGetFlowStageDocumentsDefaultResponse = Awaited<ReturnType<typeof FlowStageDocumentsService.getFlowStageDocuments>>;
export type FlowStageDocumentsServiceGetFlowStageDocumentsQueryResult<TData = FlowStageDocumentsServiceGetFlowStageDocumentsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useFlowStageDocumentsServiceGetFlowStageDocumentsKey = "FlowStageDocumentsServiceGetFlowStageDocuments";
export const UseFlowStageDocumentsServiceGetFlowStageDocumentsKeyFn = ({ client, contract, flow, flowDate, stage, xDbCatalog }: {
  client?: number;
  contract?: string;
  flow?: string;
  flowDate?: Date;
  stage?: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useFlowStageDocumentsServiceGetFlowStageDocumentsKey, ...(queryKey ?? [{ client, contract, flow, flowDate, stage, xDbCatalog }])];
export type FlowStageDocumentsServiceGetFlowStageDocumentsByFlowDefaultResponse = Awaited<ReturnType<typeof FlowStageDocumentsService.getFlowStageDocumentsByFlow>>;
export type FlowStageDocumentsServiceGetFlowStageDocumentsByFlowQueryResult<TData = FlowStageDocumentsServiceGetFlowStageDocumentsByFlowDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useFlowStageDocumentsServiceGetFlowStageDocumentsByFlowKey = "FlowStageDocumentsServiceGetFlowStageDocumentsByFlow";
export const UseFlowStageDocumentsServiceGetFlowStageDocumentsByFlowKeyFn = ({ client, contract, flow, flowDate, xDbCatalog }: {
  client?: number;
  contract?: string;
  flow?: string;
  flowDate?: Date;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useFlowStageDocumentsServiceGetFlowStageDocumentsByFlowKey, ...(queryKey ?? [{ client, contract, flow, flowDate, xDbCatalog }])];
export type FlowStagesServiceGetFlowStagesDefaultResponse = Awaited<ReturnType<typeof FlowStagesService.getFlowStages>>;
export type FlowStagesServiceGetFlowStagesQueryResult<TData = FlowStagesServiceGetFlowStagesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useFlowStagesServiceGetFlowStagesKey = "FlowStagesServiceGetFlowStages";
export const UseFlowStagesServiceGetFlowStagesKeyFn = ({ flowCode, xDbCatalog }: {
  flowCode: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useFlowStagesServiceGetFlowStagesKey, ...(queryKey ?? [{ flowCode, xDbCatalog }])];
export type FormatsServiceGetFormatsDefaultResponse = Awaited<ReturnType<typeof FormatsService.getFormats>>;
export type FormatsServiceGetFormatsQueryResult<TData = FormatsServiceGetFormatsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useFormatsServiceGetFormatsKey = "FormatsServiceGetFormats";
export const UseFormatsServiceGetFormatsKeyFn = ({ xDbCatalog }: {
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useFormatsServiceGetFormatsKey, ...(queryKey ?? [{ xDbCatalog }])];
export type FormatsServiceGetFormatsByChannelDefaultResponse = Awaited<ReturnType<typeof FormatsService.getFormatsByChannel>>;
export type FormatsServiceGetFormatsByChannelQueryResult<TData = FormatsServiceGetFormatsByChannelDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useFormatsServiceGetFormatsByChannelKey = "FormatsServiceGetFormatsByChannel";
export const UseFormatsServiceGetFormatsByChannelKeyFn = ({ channel, xDbCatalog }: {
  channel: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useFormatsServiceGetFormatsByChannelKey, ...(queryKey ?? [{ channel, xDbCatalog }])];
export type GroupsServiceGetGroupsDefaultResponse = Awaited<ReturnType<typeof GroupsService.getGroups>>;
export type GroupsServiceGetGroupsQueryResult<TData = GroupsServiceGetGroupsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useGroupsServiceGetGroupsKey = "GroupsServiceGetGroups";
export const UseGroupsServiceGetGroupsKeyFn = ({ xDbCatalog }: {
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useGroupsServiceGetGroupsKey, ...(queryKey ?? [{ xDbCatalog }])];
export type GuaranteeOwnersServiceGetGuaranteeOwnersDefaultResponse = Awaited<ReturnType<typeof GuaranteeOwnersService.getGuaranteeOwners>>;
export type GuaranteeOwnersServiceGetGuaranteeOwnersQueryResult<TData = GuaranteeOwnersServiceGetGuaranteeOwnersDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useGuaranteeOwnersServiceGetGuaranteeOwnersKey = "GuaranteeOwnersServiceGetGuaranteeOwners";
export const UseGuaranteeOwnersServiceGetGuaranteeOwnersKeyFn = ({ guaranteeNumber, xDbCatalog }: {
  guaranteeNumber: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useGuaranteeOwnersServiceGetGuaranteeOwnersKey, ...(queryKey ?? [{ guaranteeNumber, xDbCatalog }])];
export type GuaranteesServiceGetGuaranteesDefaultResponse = Awaited<ReturnType<typeof GuaranteesService.getGuarantees>>;
export type GuaranteesServiceGetGuaranteesQueryResult<TData = GuaranteesServiceGetGuaranteesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useGuaranteesServiceGetGuaranteesKey = "GuaranteesServiceGetGuarantees";
export const UseGuaranteesServiceGetGuaranteesKeyFn = ({ contract, xDbCatalog }: {
  contract: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useGuaranteesServiceGetGuaranteesKey, ...(queryKey ?? [{ contract, xDbCatalog }])];
export type HolidaysServiceGetHolidaysDefaultResponse = Awaited<ReturnType<typeof HolidaysService.getHolidays>>;
export type HolidaysServiceGetHolidaysQueryResult<TData = HolidaysServiceGetHolidaysDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useHolidaysServiceGetHolidaysKey = "HolidaysServiceGetHolidays";
export const UseHolidaysServiceGetHolidaysKeyFn = ({ xDbCatalog }: {
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useHolidaysServiceGetHolidaysKey, ...(queryKey ?? [{ xDbCatalog }])];
export type LiabilitiesServiceGetLiabilitiesDefaultResponse = Awaited<ReturnType<typeof LiabilitiesService.getLiabilities>>;
export type LiabilitiesServiceGetLiabilitiesQueryResult<TData = LiabilitiesServiceGetLiabilitiesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useLiabilitiesServiceGetLiabilitiesKey = "LiabilitiesServiceGetLiabilities";
export const UseLiabilitiesServiceGetLiabilitiesKeyFn = ({ clientCode, xDbCatalog }: {
  clientCode: number;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useLiabilitiesServiceGetLiabilitiesKey, ...(queryKey ?? [{ clientCode, xDbCatalog }])];
export type ManagementServiceGetManagementDetailsDefaultResponse = Awaited<ReturnType<typeof ManagementService.getManagementDetails>>;
export type ManagementServiceGetManagementDetailsQueryResult<TData = ManagementServiceGetManagementDetailsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useManagementServiceGetManagementDetailsKey = "ManagementServiceGetManagementDetails";
export const UseManagementServiceGetManagementDetailsKeyFn = ({ client, xDbCatalog }: {
  client: number;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useManagementServiceGetManagementDetailsKey, ...(queryKey ?? [{ client, xDbCatalog }])];
export type MonthlyPaymentsServiceGetMonthlyPaymentsDefaultResponse = Awaited<ReturnType<typeof MonthlyPaymentsService.getMonthlyPayments>>;
export type MonthlyPaymentsServiceGetMonthlyPaymentsQueryResult<TData = MonthlyPaymentsServiceGetMonthlyPaymentsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useMonthlyPaymentsServiceGetMonthlyPaymentsKey = "MonthlyPaymentsServiceGetMonthlyPayments";
export const UseMonthlyPaymentsServiceGetMonthlyPaymentsKeyFn = ({ clientCode, xDbCatalog }: {
  clientCode: number;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useMonthlyPaymentsServiceGetMonthlyPaymentsKey, ...(queryKey ?? [{ clientCode, xDbCatalog }])];
export type NotificationsServiceGetNotificationsDefaultResponse = Awaited<ReturnType<typeof NotificationsService.getNotifications>>;
export type NotificationsServiceGetNotificationsQueryResult<TData = NotificationsServiceGetNotificationsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useNotificationsServiceGetNotificationsKey = "NotificationsServiceGetNotifications";
export const UseNotificationsServiceGetNotificationsKeyFn = ({ user, xDbCatalog }: {
  user: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useNotificationsServiceGetNotificationsKey, ...(queryKey ?? [{ user, xDbCatalog }])];
export type OperatingFlowsServiceGetOperatingFlowsDefaultResponse = Awaited<ReturnType<typeof OperatingFlowsService.getOperatingFlows>>;
export type OperatingFlowsServiceGetOperatingFlowsQueryResult<TData = OperatingFlowsServiceGetOperatingFlowsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useOperatingFlowsServiceGetOperatingFlowsKey = "OperatingFlowsServiceGetOperatingFlows";
export const UseOperatingFlowsServiceGetOperatingFlowsKeyFn = ({ xDbCatalog }: {
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useOperatingFlowsServiceGetOperatingFlowsKey, ...(queryKey ?? [{ xDbCatalog }])];
export type OperatingFlowsServiceGetOperatingFlowsByFlowCodeDefaultResponse = Awaited<ReturnType<typeof OperatingFlowsService.getOperatingFlowsByFlowCode>>;
export type OperatingFlowsServiceGetOperatingFlowsByFlowCodeQueryResult<TData = OperatingFlowsServiceGetOperatingFlowsByFlowCodeDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useOperatingFlowsServiceGetOperatingFlowsByFlowCodeKey = "OperatingFlowsServiceGetOperatingFlowsByFlowCode";
export const UseOperatingFlowsServiceGetOperatingFlowsByFlowCodeKeyFn = ({ flowCode, xDbCatalog }: {
  flowCode: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useOperatingFlowsServiceGetOperatingFlowsByFlowCodeKey, ...(queryKey ?? [{ flowCode, xDbCatalog }])];
export type OrderItemsServiceGetAllOrderItemsDefaultResponse = Awaited<ReturnType<typeof OrderItemsService.getAllOrderItems>>;
export type OrderItemsServiceGetAllOrderItemsQueryResult<TData = OrderItemsServiceGetAllOrderItemsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useOrderItemsServiceGetAllOrderItemsKey = "OrderItemsServiceGetAllOrderItems";
export const UseOrderItemsServiceGetAllOrderItemsKeyFn = ({ xDbCatalog }: {
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useOrderItemsServiceGetAllOrderItemsKey, ...(queryKey ?? [{ xDbCatalog }])];
export type OrderItemsServiceGetOrderItemsByClientDefaultResponse = Awaited<ReturnType<typeof OrderItemsService.getOrderItemsByClient>>;
export type OrderItemsServiceGetOrderItemsByClientQueryResult<TData = OrderItemsServiceGetOrderItemsByClientDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useOrderItemsServiceGetOrderItemsByClientKey = "OrderItemsServiceGetOrderItemsByClient";
export const UseOrderItemsServiceGetOrderItemsByClientKeyFn = ({ client, xDbCatalog }: {
  client: number;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useOrderItemsServiceGetOrderItemsByClientKey, ...(queryKey ?? [{ client, xDbCatalog }])];
export type OtherPersonsServiceGetOtherPersonsDefaultResponse = Awaited<ReturnType<typeof OtherPersonsService.getOtherPersons>>;
export type OtherPersonsServiceGetOtherPersonsQueryResult<TData = OtherPersonsServiceGetOtherPersonsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useOtherPersonsServiceGetOtherPersonsKey = "OtherPersonsServiceGetOtherPersons";
export const UseOtherPersonsServiceGetOtherPersonsKeyFn = ({ clientCode, xDbCatalog }: {
  clientCode: number;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useOtherPersonsServiceGetOtherPersonsKey, ...(queryKey ?? [{ clientCode, xDbCatalog }])];
export type PaymentAgreementsServiceGetPaymentAgreementsDefaultResponse = Awaited<ReturnType<typeof PaymentAgreementsService.getPaymentAgreements>>;
export type PaymentAgreementsServiceGetPaymentAgreementsQueryResult<TData = PaymentAgreementsServiceGetPaymentAgreementsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const usePaymentAgreementsServiceGetPaymentAgreementsKey = "PaymentAgreementsServiceGetPaymentAgreements";
export const UsePaymentAgreementsServiceGetPaymentAgreementsKeyFn = ({ client, xDbCatalog }: {
  client: number;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [usePaymentAgreementsServiceGetPaymentAgreementsKey, ...(queryKey ?? [{ client, xDbCatalog }])];
export type PersonalReferencesServiceGetPersonalReferencesDefaultResponse = Awaited<ReturnType<typeof PersonalReferencesService.getPersonalReferences>>;
export type PersonalReferencesServiceGetPersonalReferencesQueryResult<TData = PersonalReferencesServiceGetPersonalReferencesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const usePersonalReferencesServiceGetPersonalReferencesKey = "PersonalReferencesServiceGetPersonalReferences";
export const UsePersonalReferencesServiceGetPersonalReferencesKeyFn = ({ client, xDbCatalog }: {
  client: number;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [usePersonalReferencesServiceGetPersonalReferencesKey, ...(queryKey ?? [{ client, xDbCatalog }])];
export type PersonsAddendumServiceGetPersonsAddendumDefaultResponse = Awaited<ReturnType<typeof PersonsAddendumService.getPersonsAddendum>>;
export type PersonsAddendumServiceGetPersonsAddendumQueryResult<TData = PersonsAddendumServiceGetPersonsAddendumDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const usePersonsAddendumServiceGetPersonsAddendumKey = "PersonsAddendumServiceGetPersonsAddendum";
export const UsePersonsAddendumServiceGetPersonsAddendumKeyFn = ({ clientCode, xDbCatalog }: {
  clientCode: number;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [usePersonsAddendumServiceGetPersonsAddendumKey, ...(queryKey ?? [{ clientCode, xDbCatalog }])];
export type PromisesServiceGetPromisesDefaultResponse = Awaited<ReturnType<typeof PromisesService.getPromises>>;
export type PromisesServiceGetPromisesQueryResult<TData = PromisesServiceGetPromisesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const usePromisesServiceGetPromisesKey = "PromisesServiceGetPromises";
export const UsePromisesServiceGetPromisesKeyFn = ({ client, xDbCatalog }: {
  client: number;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [usePromisesServiceGetPromisesKey, ...(queryKey ?? [{ client, xDbCatalog }])];
export type QuotesServiceGetQuotesDefaultResponse = Awaited<ReturnType<typeof QuotesService.getQuotes>>;
export type QuotesServiceGetQuotesQueryResult<TData = QuotesServiceGetQuotesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useQuotesServiceGetQuotesKey = "QuotesServiceGetQuotes";
export const UseQuotesServiceGetQuotesKeyFn = ({ client, xDbCatalog }: {
  client: number;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useQuotesServiceGetQuotesKey, ...(queryKey ?? [{ client, xDbCatalog }])];
export type QuotesServiceGetQuotesByClientContractDefaultResponse = Awaited<ReturnType<typeof QuotesService.getQuotesByClientContract>>;
export type QuotesServiceGetQuotesByClientContractQueryResult<TData = QuotesServiceGetQuotesByClientContractDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useQuotesServiceGetQuotesByClientContractKey = "QuotesServiceGetQuotesByClientContract";
export const UseQuotesServiceGetQuotesByClientContractKeyFn = ({ client, contract, xDbCatalog }: {
  client: number;
  contract: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useQuotesServiceGetQuotesByClientContractKey, ...(queryKey ?? [{ client, contract, xDbCatalog }])];
export type ReschedulesServiceGetReschedulesDefaultResponse = Awaited<ReturnType<typeof ReschedulesService.getReschedules>>;
export type ReschedulesServiceGetReschedulesQueryResult<TData = ReschedulesServiceGetReschedulesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useReschedulesServiceGetReschedulesKey = "ReschedulesServiceGetReschedules";
export const UseReschedulesServiceGetReschedulesKeyFn = ({ client, xDbCatalog }: {
  client: number;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useReschedulesServiceGetReschedulesKey, ...(queryKey ?? [{ client, xDbCatalog }])];
export type ReschedulesServiceGetReschedulesByDateAndExecutiveDefaultResponse = Awaited<ReturnType<typeof ReschedulesService.getReschedulesByDateAndExecutive>>;
export type ReschedulesServiceGetReschedulesByDateAndExecutiveQueryResult<TData = ReschedulesServiceGetReschedulesByDateAndExecutiveDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useReschedulesServiceGetReschedulesByDateAndExecutiveKey = "ReschedulesServiceGetReschedulesByDateAndExecutive";
export const UseReschedulesServiceGetReschedulesByDateAndExecutiveKeyFn = ({ date, executive, xDbCatalog }: {
  date: string;
  executive: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useReschedulesServiceGetReschedulesByDateAndExecutiveKey, ...(queryKey ?? [{ date, executive, xDbCatalog }])];
export type RolesServiceGetAllRolesDefaultResponse = Awaited<ReturnType<typeof RolesService.getAllRoles>>;
export type RolesServiceGetAllRolesQueryResult<TData = RolesServiceGetAllRolesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useRolesServiceGetAllRolesKey = "RolesServiceGetAllRoles";
export const UseRolesServiceGetAllRolesKeyFn = ({ xDbCatalog }: {
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useRolesServiceGetAllRolesKey, ...(queryKey ?? [{ xDbCatalog }])];
export type RolesPermissionsServiceGetPermissionsByRoleDefaultResponse = Awaited<ReturnType<typeof RolesPermissionsService.getPermissionsByRole>>;
export type RolesPermissionsServiceGetPermissionsByRoleQueryResult<TData = RolesPermissionsServiceGetPermissionsByRoleDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useRolesPermissionsServiceGetPermissionsByRoleKey = "RolesPermissionsServiceGetPermissionsByRole";
export const UseRolesPermissionsServiceGetPermissionsByRoleKeyFn = ({ roleId, xDbCatalog }: {
  roleId: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useRolesPermissionsServiceGetPermissionsByRoleKey, ...(queryKey ?? [{ roleId, xDbCatalog }])];
export type StrategiesServiceGetStrategiesDefaultResponse = Awaited<ReturnType<typeof StrategiesService.getStrategies>>;
export type StrategiesServiceGetStrategiesQueryResult<TData = StrategiesServiceGetStrategiesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useStrategiesServiceGetStrategiesKey = "StrategiesServiceGetStrategies";
export const UseStrategiesServiceGetStrategiesKeyFn = ({ xDbCatalog }: {
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useStrategiesServiceGetStrategiesKey, ...(queryKey ?? [{ xDbCatalog }])];
export type StrategyFormatsServiceGetStrategyFormatsDefaultResponse = Awaited<ReturnType<typeof StrategyFormatsService.getStrategyFormats>>;
export type StrategyFormatsServiceGetStrategyFormatsQueryResult<TData = StrategyFormatsServiceGetStrategyFormatsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useStrategyFormatsServiceGetStrategyFormatsKey = "StrategyFormatsServiceGetStrategyFormats";
export const UseStrategyFormatsServiceGetStrategyFormatsKeyFn = ({ codeStrategy, xDbCatalog }: {
  codeStrategy: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useStrategyFormatsServiceGetStrategyFormatsKey, ...(queryKey ?? [{ codeStrategy, xDbCatalog }])];
export type UrlsServiceGetUrlsDefaultResponse = Awaited<ReturnType<typeof UrlsService.getUrls>>;
export type UrlsServiceGetUrlsQueryResult<TData = UrlsServiceGetUrlsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUrlsServiceGetUrlsKey = "UrlsServiceGetUrls";
export const UseUrlsServiceGetUrlsKeyFn = ({ xDbCatalog }: {
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useUrlsServiceGetUrlsKey, ...(queryKey ?? [{ xDbCatalog }])];
export type UsersServiceGetAllUsersDefaultResponse = Awaited<ReturnType<typeof UsersService.getAllUsers>>;
export type UsersServiceGetAllUsersQueryResult<TData = UsersServiceGetAllUsersDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUsersServiceGetAllUsersKey = "UsersServiceGetAllUsers";
export const UseUsersServiceGetAllUsersKeyFn = ({ xDbCatalog }: {
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useUsersServiceGetAllUsersKey, ...(queryKey ?? [{ xDbCatalog }])];
export type UsersServiceGetUsersByRoleDefaultResponse = Awaited<ReturnType<typeof UsersService.getUsersByRole>>;
export type UsersServiceGetUsersByRoleQueryResult<TData = UsersServiceGetUsersByRoleDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUsersServiceGetUsersByRoleKey = "UsersServiceGetUsersByRole";
export const UseUsersServiceGetUsersByRoleKeyFn = ({ userRole, xDbCatalog }: {
  userRole: string;
  xDbCatalog: string;
}, queryKey?: Array<unknown>) => [useUsersServiceGetUsersByRoleKey, ...(queryKey ?? [{ userRole, xDbCatalog }])];
export type AppPersonalizationsServicePostAppPersonalizationMutationResult = Awaited<ReturnType<typeof AppPersonalizationsService.postAppPersonalization>>;
export type AppVariablesServicePostAppVariableMutationResult = Awaited<ReturnType<typeof AppVariablesService.postAppVariable>>;
export type ArticlesServicePostArticleMutationResult = Awaited<ReturnType<typeof ArticlesService.postArticle>>;
export type CatalogsServicePostCatalogsMutationResult = Awaited<ReturnType<typeof CatalogsService.postCatalogs>>;
export type ContactDetailsServicePostContactDetailsMutationResult = Awaited<ReturnType<typeof ContactDetailsService.postContactDetails>>;
export type DemographicDataServicePostDemographicDataMutationResult = Awaited<ReturnType<typeof DemographicDataService.postDemographicData>>;
export type DownloadedDataServicePostDownloadedDataMutationResult = Awaited<ReturnType<typeof DownloadedDataService.postDownloadedData>>;
export type DynamicNamesServicePostDynamicNamesMutationResult = Awaited<ReturnType<typeof DynamicNamesService.postDynamicNames>>;
export type EmploymentDataServicePostEmploymentDataMutationResult = Awaited<ReturnType<typeof EmploymentDataService.postEmploymentData>>;
export type FlowDocumentsServicePostFlowDocumentsMutationResult = Awaited<ReturnType<typeof FlowDocumentsService.postFlowDocuments>>;
export type FlowStageDocumentsServicePostAttachedFileMutationResult = Awaited<ReturnType<typeof FlowStageDocumentsService.postAttachedFile>>;
export type FlowStagesServicePostFlowStagesMutationResult = Awaited<ReturnType<typeof FlowStagesService.postFlowStages>>;
export type FormatsServicePostFormatsMutationResult = Awaited<ReturnType<typeof FormatsService.postFormats>>;
export type GenerateLetterServicePostGenerateLetterMutationResult = Awaited<ReturnType<typeof GenerateLetterService.postGenerateLetter>>;
export type GroupsServicePostGroupsMutationResult = Awaited<ReturnType<typeof GroupsService.postGroups>>;
export type GuaranteeOwnersServicePostGuaranteeOwnersMutationResult = Awaited<ReturnType<typeof GuaranteeOwnersService.postGuaranteeOwners>>;
export type GuaranteesServicePostGuaranteesMutationResult = Awaited<ReturnType<typeof GuaranteesService.postGuarantees>>;
export type HolidaysServicePostHolidaysMutationResult = Awaited<ReturnType<typeof HolidaysService.postHolidays>>;
export type ImportServicePostImportMutationResult = Awaited<ReturnType<typeof ImportService.postImport>>;
export type LiabilitiesServicePostLiabilitiesMutationResult = Awaited<ReturnType<typeof LiabilitiesService.postLiabilities>>;
export type ManagementServicePostManagementDetailsMutationResult = Awaited<ReturnType<typeof ManagementService.postManagementDetails>>;
export type MonthlyPaymentsServicePostMonthlyPaymentsMutationResult = Awaited<ReturnType<typeof MonthlyPaymentsService.postMonthlyPayments>>;
export type NotificationsServicePostNotificationMutationResult = Awaited<ReturnType<typeof NotificationsService.postNotification>>;
export type OperatingFlowsServicePostOperatingFlowsMutationResult = Awaited<ReturnType<typeof OperatingFlowsService.postOperatingFlows>>;
export type OrderItemsServicePostOrderItemsMutationResult = Awaited<ReturnType<typeof OrderItemsService.postOrderItems>>;
export type OtherPersonsServicePostOtherPersonsMutationResult = Awaited<ReturnType<typeof OtherPersonsService.postOtherPersons>>;
export type PaymentAgreementsServicePostPaymentAgreementMutationResult = Awaited<ReturnType<typeof PaymentAgreementsService.postPaymentAgreement>>;
export type PersonalReferencesServicePostPersonalReferencesMutationResult = Awaited<ReturnType<typeof PersonalReferencesService.postPersonalReferences>>;
export type PersonsAddendumServicePostPersonsAddendumMutationResult = Awaited<ReturnType<typeof PersonsAddendumService.postPersonsAddendum>>;
export type PreviewSegmentationServicePostPreviewSegmentationMutationResult = Awaited<ReturnType<typeof PreviewSegmentationService.postPreviewSegmentation>>;
export type PromisesServiceCreatePromiseMutationResult = Awaited<ReturnType<typeof PromisesService.createPromise>>;
export type QuotesServicePostQuotesMutationResult = Awaited<ReturnType<typeof QuotesService.postQuotes>>;
export type RequestServiceCallUrlMutationResult = Awaited<ReturnType<typeof RequestService.callUrl>>;
export type ReschedulesServicePostReschedulesMutationResult = Awaited<ReturnType<typeof ReschedulesService.postReschedules>>;
export type RolesServicePostRoleMutationResult = Awaited<ReturnType<typeof RolesService.postRole>>;
export type RolesPermissionsServicePostRolesPermissionMutationResult = Awaited<ReturnType<typeof RolesPermissionsService.postRolesPermission>>;
export type StrategiesServicePostStrategyMutationResult = Awaited<ReturnType<typeof StrategiesService.postStrategy>>;
export type StrategyFormatsServicePostStrategyFormatsMutationResult = Awaited<ReturnType<typeof StrategyFormatsService.postStrategyFormats>>;
export type UrlsServicePostUrlsMutationResult = Awaited<ReturnType<typeof UrlsService.postUrls>>;
export type UsersServicePostUsersAsyncMutationResult = Awaited<ReturnType<typeof UsersService.postUsersAsync>>;
export type AppPersonalizationsServicePutAppPersonalizationMutationResult = Awaited<ReturnType<typeof AppPersonalizationsService.putAppPersonalization>>;
export type AppVariablesServicePutAppVariableMutationResult = Awaited<ReturnType<typeof AppVariablesService.putAppVariable>>;
export type ArticlesServicePutArticleMutationResult = Awaited<ReturnType<typeof ArticlesService.putArticle>>;
export type CatalogsServicePutCatalogsMutationResult = Awaited<ReturnType<typeof CatalogsService.putCatalogs>>;
export type ContactDetailsServicePutContactDetailsMutationResult = Awaited<ReturnType<typeof ContactDetailsService.putContactDetails>>;
export type DemographicDataServicePutDemographicDataMutationResult = Awaited<ReturnType<typeof DemographicDataService.putDemographicData>>;
export type DownloadedDataServicePutDownloadedDataMutationResult = Awaited<ReturnType<typeof DownloadedDataService.putDownloadedData>>;
export type DynamicNamesServicePutDynamicNamesMutationResult = Awaited<ReturnType<typeof DynamicNamesService.putDynamicNames>>;
export type EmploymentDataServicePutEmploymentDataMutationResult = Awaited<ReturnType<typeof EmploymentDataService.putEmploymentData>>;
export type FieldsServicePutFieldsMutationResult = Awaited<ReturnType<typeof FieldsService.putFields>>;
export type FlowDocumentsServicePutFlowDocumentsMutationResult = Awaited<ReturnType<typeof FlowDocumentsService.putFlowDocuments>>;
export type FlowStageDocumentsServicePutAttachedFileMutationResult = Awaited<ReturnType<typeof FlowStageDocumentsService.putAttachedFile>>;
export type FlowStagesServicePutFlowStagesMutationResult = Awaited<ReturnType<typeof FlowStagesService.putFlowStages>>;
export type FormatsServicePutFormatsMutationResult = Awaited<ReturnType<typeof FormatsService.putFormats>>;
export type GroupsServicePutGroupsMutationResult = Awaited<ReturnType<typeof GroupsService.putGroups>>;
export type GuaranteeOwnersServicePutGuaranteeOwnersMutationResult = Awaited<ReturnType<typeof GuaranteeOwnersService.putGuaranteeOwners>>;
export type GuaranteesServicePutGuaranteesMutationResult = Awaited<ReturnType<typeof GuaranteesService.putGuarantees>>;
export type HolidaysServicePutHolidaysMutationResult = Awaited<ReturnType<typeof HolidaysService.putHolidays>>;
export type LiabilitiesServicePutLiabilitiesMutationResult = Awaited<ReturnType<typeof LiabilitiesService.putLiabilities>>;
export type ManagementServicePutManagementDetailsMutationResult = Awaited<ReturnType<typeof ManagementService.putManagementDetails>>;
export type MonthlyPaymentsServicePutMonthlyPaymentsMutationResult = Awaited<ReturnType<typeof MonthlyPaymentsService.putMonthlyPayments>>;
export type NotificationsServicePutNotificationMutationResult = Awaited<ReturnType<typeof NotificationsService.putNotification>>;
export type OperatingFlowsServicePutOperatingFlowsMutationResult = Awaited<ReturnType<typeof OperatingFlowsService.putOperatingFlows>>;
export type OrderItemsServicePutOrderItemsMutationResult = Awaited<ReturnType<typeof OrderItemsService.putOrderItems>>;
export type OtherPersonsServicePutOtherPersonsMutationResult = Awaited<ReturnType<typeof OtherPersonsService.putOtherPersons>>;
export type PaymentAgreementsServicePutPaymentAgreementMutationResult = Awaited<ReturnType<typeof PaymentAgreementsService.putPaymentAgreement>>;
export type PersonalReferencesServicePutPersonalReferencesMutationResult = Awaited<ReturnType<typeof PersonalReferencesService.putPersonalReferences>>;
export type PersonsAddendumServicePutPersonsAddendumMutationResult = Awaited<ReturnType<typeof PersonsAddendumService.putPersonsAddendum>>;
export type PromisesServiceUpdatePromiseMutationResult = Awaited<ReturnType<typeof PromisesService.updatePromise>>;
export type QuotesServicePutQuotesMutationResult = Awaited<ReturnType<typeof QuotesService.putQuotes>>;
export type ReschedulesServicePutReschedulesMutationResult = Awaited<ReturnType<typeof ReschedulesService.putReschedules>>;
export type RolesServicePutRoleMutationResult = Awaited<ReturnType<typeof RolesService.putRole>>;
export type RolesPermissionsServicePutRolesPermissionMutationResult = Awaited<ReturnType<typeof RolesPermissionsService.putRolesPermission>>;
export type StrategiesServicePutStrategyMutationResult = Awaited<ReturnType<typeof StrategiesService.putStrategy>>;
export type StrategyFormatsServicePutStrategyFormatsMutationResult = Awaited<ReturnType<typeof StrategyFormatsService.putStrategyFormats>>;
export type UrlsServicePutUrlsMutationResult = Awaited<ReturnType<typeof UrlsService.putUrls>>;
export type UsersServicePutUsersMutationResult = Awaited<ReturnType<typeof UsersService.putUsers>>;
export type AppPersonalizationsServiceDeleteAppPersonalizationMutationResult = Awaited<ReturnType<typeof AppPersonalizationsService.deleteAppPersonalization>>;
export type AppVariablesServiceDeleteAppVariableMutationResult = Awaited<ReturnType<typeof AppVariablesService.deleteAppVariable>>;
export type ArticlesServiceDeleteArticleMutationResult = Awaited<ReturnType<typeof ArticlesService.deleteArticle>>;
export type CatalogsServiceDeleteCatalogsMutationResult = Awaited<ReturnType<typeof CatalogsService.deleteCatalogs>>;
export type ContactDetailsServiceDeleteContactDetailsMutationResult = Awaited<ReturnType<typeof ContactDetailsService.deleteContactDetails>>;
export type DemographicDataServiceDeleteDemographicDataMutationResult = Awaited<ReturnType<typeof DemographicDataService.deleteDemographicData>>;
export type DownloadedDataServiceDeleteDownloadedDataMutationResult = Awaited<ReturnType<typeof DownloadedDataService.deleteDownloadedData>>;
export type DynamicNamesServiceDeleteDynamicNamesMutationResult = Awaited<ReturnType<typeof DynamicNamesService.deleteDynamicNames>>;
export type EmploymentDataServiceDeleteEmploymentDataMutationResult = Awaited<ReturnType<typeof EmploymentDataService.deleteEmploymentData>>;
export type FlowDocumentsServiceDeleteFlowDocumentsMutationResult = Awaited<ReturnType<typeof FlowDocumentsService.deleteFlowDocuments>>;
export type FlowStageDocumentsServiceDeleteAttachedFileMutationResult = Awaited<ReturnType<typeof FlowStageDocumentsService.deleteAttachedFile>>;
export type FlowStagesServiceDeleteFlowStagesMutationResult = Awaited<ReturnType<typeof FlowStagesService.deleteFlowStages>>;
export type FormatsServiceDeleteFormatsMutationResult = Awaited<ReturnType<typeof FormatsService.deleteFormats>>;
export type GroupsServiceDeleteGroupsMutationResult = Awaited<ReturnType<typeof GroupsService.deleteGroups>>;
export type GuaranteeOwnersServiceDeleteGuaranteeOwnersMutationResult = Awaited<ReturnType<typeof GuaranteeOwnersService.deleteGuaranteeOwners>>;
export type GuaranteesServiceDeleteGuaranteesMutationResult = Awaited<ReturnType<typeof GuaranteesService.deleteGuarantees>>;
export type HolidaysServiceDeleteHolidaysMutationResult = Awaited<ReturnType<typeof HolidaysService.deleteHolidays>>;
export type LiabilitiesServiceDeleteLiabilitiesMutationResult = Awaited<ReturnType<typeof LiabilitiesService.deleteLiabilities>>;
export type ManagementServiceDeleteManagementDetailsMutationResult = Awaited<ReturnType<typeof ManagementService.deleteManagementDetails>>;
export type MonthlyPaymentsServiceDeleteMonthlyPaymentsMutationResult = Awaited<ReturnType<typeof MonthlyPaymentsService.deleteMonthlyPayments>>;
export type NotificationsServiceDeleteNotificationMutationResult = Awaited<ReturnType<typeof NotificationsService.deleteNotification>>;
export type OperatingFlowsServiceDeleteOperatingFlowsMutationResult = Awaited<ReturnType<typeof OperatingFlowsService.deleteOperatingFlows>>;
export type OrderItemsServiceDeleteOrderItemsMutationResult = Awaited<ReturnType<typeof OrderItemsService.deleteOrderItems>>;
export type OtherPersonsServiceDeleteOtherPersonsMutationResult = Awaited<ReturnType<typeof OtherPersonsService.deleteOtherPersons>>;
export type PaymentAgreementsServiceDeletePaymentAgreementMutationResult = Awaited<ReturnType<typeof PaymentAgreementsService.deletePaymentAgreement>>;
export type PersonalReferencesServiceDeletePersonalReferencesMutationResult = Awaited<ReturnType<typeof PersonalReferencesService.deletePersonalReferences>>;
export type PersonsAddendumServiceDeletePersonsAddendumMutationResult = Awaited<ReturnType<typeof PersonsAddendumService.deletePersonsAddendum>>;
export type PromisesServiceDeletePromiseMutationResult = Awaited<ReturnType<typeof PromisesService.deletePromise>>;
export type QuotesServiceDeleteQuotesMutationResult = Awaited<ReturnType<typeof QuotesService.deleteQuotes>>;
export type ReschedulesServiceDeleteReschedulesMutationResult = Awaited<ReturnType<typeof ReschedulesService.deleteReschedules>>;
export type RolesServiceDeleteRoleMutationResult = Awaited<ReturnType<typeof RolesService.deleteRole>>;
export type RolesPermissionsServiceDeleteRolesPermissionMutationResult = Awaited<ReturnType<typeof RolesPermissionsService.deleteRolesPermission>>;
export type StrategiesServiceDeleteStrategyMutationResult = Awaited<ReturnType<typeof StrategiesService.deleteStrategy>>;
export type StrategyFormatsServiceDeleteStrategyFormatsMutationResult = Awaited<ReturnType<typeof StrategyFormatsService.deleteStrategyFormats>>;
export type UrlsServiceDeleteUrlsMutationResult = Awaited<ReturnType<typeof UrlsService.deleteUrls>>;
export type UsersServiceDeleteUsersMutationResult = Awaited<ReturnType<typeof UsersService.deleteUsers>>;
