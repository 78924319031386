import { checkUnauthorized } from "./checkUnauthorized";
import { saveJwtToken } from "./saveJwT";

export const apiResponseHandler = <T>(promise: Promise<T>): Promise<T> => {
  return new Promise((resolve, reject) => {
    promise
      .then((response) => {
        saveJwtToken(response);
        resolve(response);
      })
      .catch((error) => {
        if (!checkUnauthorized(error)) {
          saveJwtToken(error.response);
        }
        reject(error);
      });
  });
};
