import { apiVersion } from "~/config/api";
import { i18next } from "~/config/i18n";
import type {
  ActionKeyState,
  ActionMap,
  ActionPostFetch,
  ItemPostFetch,
} from "~/types";
import { toast } from "react-toastify";
import { request as __request } from "~/requests/core/request";
import { OpenAPI } from "~/requests/core/OpenAPI";
import secureLocalStorage from "react-secure-storage";
import { generateId } from "~/utils/generateId";
import type { Notification } from "~/requests";
import type { QueryClient } from "@tanstack/react-query";
import { invalidateQuery } from "./invalidate";
import { UseNotificationsServiceGetNotificationsKeyFn } from "~/queries";

type AddNotificationPropsWithDetails = {
  messageType: "success" | "error" | "warning";
  action: ActionPostFetch;
  item: ItemPostFetch;
  context: "female" | "male";
};

type AddNotificationPropsWithSchedule = {
  messageType: "reschedule";
  item: string;
};

type AddNotificationPropsType = (
  | AddNotificationPropsWithDetails
  | AddNotificationPropsWithSchedule
) & {
  queryClient: QueryClient;
};

export const addNotification = (props: AddNotificationPropsType) => {
  const user = JSON.parse(secureLocalStorage.getItem("user") as string);
  if (props.messageType !== "reschedule") {
    const { action, item, context, messageType } = props;

    const count = messageType === "success" ? 1 : 5;
    const actionMap: ActionMap = {
      add: i18next.t("common:addedAction", { count }),
      edit: i18next.t("common:editedAction", { count }),
      delete: i18next.t("common:deletedAction", { count }),
      activate: i18next.t("common:activatedAction", { count }),
      inactivate: i18next.t("common:inactivatedAction", { count }),
      mark: i18next.t("common:markAction", { count }),
      notMark: i18next.t("common:notMarkAction", { count }),
      assign: i18next.t("common:assignAction", { count }),
      finalize: i18next.t("common:finalizeAction", { count }),
      save: i18next.t("common:saveAction", { count }),
      download: i18next.t("common:downloadAction", { count }),
      call: i18next.t("common:callAction", { count }),
      pause: i18next.t("common:pauseAction", { count }),
      restart: i18next.t("common:restartAction", { count }),
      consult: i18next.t("common:consultAction", { count }),
      transfer: i18next.t("common:transferAction", { count }),
      conference: i18next.t("common:conferenceAction", { count }),
      onWait: i18next.t("common:onWaitAction", { count }),
      resuming: i18next.t("common:resumingAction", { count }),
      hangup: i18next.t("common:hangupAction", { count }),
      return: i18next.t("common:returnAction", { count }),
      change: i18next.t("common:changeAction", { count }),
      select: i18next.t("common:selectAction", { count }),
      upload: i18next.t("common:uploadAction", { count }),
      finish: i18next.t("common:finishAction", { count }),
      send: i18next.t("common:sendAction", { count }),
    };

    const tKey =
      `${messageType}ActionMessage` satisfies `${ActionKeyState}ActionMessage`;
    const message = i18next.t(`${messageType}ActionMessage`, {
      context,
      how: actionMap[action],
      what: i18next.t(`${item}Item`),
    });
    toast(message, {
      type: messageType,
      data: {
        tKey,
        context,
        how: action,
        what: item,
      },
    });
    __request(OpenAPI, {
      method: "POST",
      url: `/api/v${apiVersion}/Notifications`,
      headers: {
        "X-DB-Catalog": user.dbCatalog,
        "X-OPERATION-ID": generateId(),
      },
      body: {
        messageType: props.messageType,
        tkey: tKey,
        action: props.action,
        item: props.item,
        context: props.context,
        user: user.userName,
        isRead: false,
      } satisfies Notification,
      mediaType: "application/json",
      errors: {
        400: "No results found.",
        404: "An error occurred while executing PostNotification",
      },
    })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        invalidateQuery(
          props.queryClient,
          UseNotificationsServiceGetNotificationsKeyFn({
            user: user.userName,
            xDbCatalog: user.dbCatalog,
          }) as string[],
        );
      });
  } else {
    const { item, messageType } = props;
    const tKey =
      `${messageType}ActionMessage` satisfies `${ActionKeyState}ActionMessage`;
    const message = i18next.t(`${messageType}ActionMessage`, {
      what: item,
    });
    toast(message, {
      type: "info",
      data: {
        tKey,
        what: item,
      },
    });
    __request(OpenAPI, {
      method: "POST",
      url: `/api/v${apiVersion}/Notifications`,
      headers: {
        "X-DB-Catalog": user.dbCatalog,
        "X-OPERATION-ID": generateId(),
      },
      body: {
        messageType: props.messageType,
        tkey: tKey,
        action: null,
        item: props.item,
        context: null,
        user: user.userName,
        isRead: false,
      } satisfies Notification,
      mediaType: "application/json",
      errors: {
        400: "No results found.",
        404: "An error occurred while executing PostNotification",
      },
    })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        invalidateQuery(
          props.queryClient,
          UseNotificationsServiceGetNotificationsKeyFn({
            user: user.userName,
            xDbCatalog: user.dbCatalog,
          }) as string[],
        );
      });
  }
};
