import { FallbackComponent } from "~/components/FallbackComponent";
import { Unauthorized } from "~/components/Unauthorized";
import { useClientManagement } from "~/hooks/usePages";
import { prefetchUseAppVariablesServiceGetAppVariablesByName } from "~/queries/prefetch";
import { userState } from "~/app/state/login";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Skeleton from "@mui/material/Skeleton";
import { ErrorBoundary } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import { Suspense, StrictMode, useEffect } from "react";
import { useRecoilValue } from "recoil";
import type { AssignExecutiveB, AssignExecutiveR } from "~/types";
import { useSearchParams } from "react-router-dom";
import { assignExecutiveUrl } from "~/utils/endpoints";
import secureLocalStorage from "react-secure-storage";
import { generateId } from "~/utils/generateId";
import { post } from "~/utils/fetchApi";

const PersonalInformation = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/client-management/_components/PersonalInformation"
      )
    ).PersonalInformation,
  };
});

const ContractData = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/client-management/_components/ContractData"
      )
    ).ContractData,
  };
});

const AdvancedContractDetails = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/client-management/_components/AdvancedContractDetails"
      )
    ).AdvancedContractDetails,
  };
});

const ContractDetails = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/client-management/_components/ContractDetails"
      )
    ).ContractDetails,
  };
});

const WaChatBot = reactLazyWithRetry(async () => {
  return {
    default: (
      await import("~/app/(dashboard)/client-management/_components/WaChatBot")
    ).WaChatBot,
  };
});

const PersonalInformationOptions = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/client-management/_components/PersonalInformationOptions"
      )
    ).PersonalInformationOptions,
  };
});

const PersonalInformationViews = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/client-management/_components/PersonalInformationViews"
      )
    ).PersonalInformationViews,
  };
});

const Payments = reactLazyWithRetry(async () => {
  return {
    default: (
      await import("~/app/(dashboard)/client-management/_components/Payments")
    ).Payments,
  };
});

const DebtDistribution = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/client-management/_components/DebtDistribution"
      )
    ).DebtDistribution,
  };
});

const OrderItems = reactLazyWithRetry(async () => {
  return {
    default: (
      await import("~/app/(dashboard)/client-management/_components/OrderItems")
    ).OrderItems,
  };
});

const AccountStatement = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/client-management/_components/AccountStatement"
      )
    ).AccountStatement,
  };
});

const Articles = reactLazyWithRetry(async () => {
  return {
    default: (
      await import("~/app/(dashboard)/client-management/_components/Articles")
    ).Articles,
  };
});

const MonthlyPayments = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/client-management/_components/MonthlyPayments"
      )
    ).MonthlyPayments,
  };
});

const Explanations = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/client-management/_components/Explanations"
      )
    ).Explanations,
  };
});

const Addendum = reactLazyWithRetry(async () => {
  return {
    default: (
      await import("~/app/(dashboard)/client-management/_components/Addendum")
    ).Addendum,
  };
});

const Guarantees = reactLazyWithRetry(async () => {
  return {
    default: (
      await import("~/app/(dashboard)/client-management/_components/Guarantees")
    ).Guarantees,
  };
});

const Quotes = reactLazyWithRetry(async () => {
  return {
    default: (
      await import("~/app/(dashboard)/client-management/_components/Quotes")
    ).Quotes,
  };
});

const ContractOptions = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/client-management/_components/ContractOptions"
      )
    ).ContractOptions,
  };
});

const ContractOptionsViews = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/client-management/_components/ContractOptionViews"
      )
    ).ContractOptionsViews,
  };
});

const QuanticButtons = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/client-management/_components/QuanticButtons"
      )
    ).QuanticButtons,
  };
});

const SoftPhoneContainer = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/client-management/_components/SoftPhoneContainer"
      )
    ).SoftPhoneContainer,
  };
});

// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: Fix later
export function Component() {
  const {
    selectedContract,
    showWaChatBot,
    handleShowWaChatBot,
    showGuaranteesDataGrid,
    showQuotesDataGrid,
    showPaymentsDataGrid,
    showDebtDistributionDataGrid,
    showOrderItemsDataGrid,
    showCardAccountStatement,
    showArticlesCard,
    showMonthlyPaymentsCard,
    showExplanationsCard,
    showAddendumCard,
    matches,
    hasViewPermission,
    hasViewPermissionContractDetails,
    hasViewPermissionContractData,
    hadQuanticButtonsViewPermission,
    hasViewAdvancedContractDetailsPermission,
  } = useClientManagement();
  const queryClient = useQueryClient();
  const user = useRecoilValue(userState);
  const [searchParams, _setSearchParams] = useSearchParams();

  useEffect(() => {
    if (
      searchParams.get("location") === "quanticvision" ||
      searchParams.get("location") === "ucontact"
    ) {
      const jwtToken = JSON.parse(
        secureLocalStorage.getItem("jwtToken") as string,
      );
      const user = JSON.parse(secureLocalStorage.getItem("user") as string);
      const client = searchParams.get("clientId");
      post<AssignExecutiveR[], AssignExecutiveB>({
        url: assignExecutiveUrl(),
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
          "X-DB-Catalog": user.dbCatalog,
          "X-OPERATION-ID": generateId(),
        }),
        body: {
          contratoSeleccionado: "",
          username: user.userName,
          numeroSeleccionado: String(client),
        },
      });
    }
  }, [searchParams]);

  prefetchUseAppVariablesServiceGetAppVariablesByName(queryClient, {
    name: "g_maps_api_key",
    xDbCatalog: user.dbCatalog,
  });

  if (!hasViewPermission) {
    return <Unauthorized />;
  }

  return (
    <>
      <StrictMode>
        {import.meta.env.DEV && (
          <ErrorBoundary
            beforeCapture={(scope) => {
              scope.setTag("component", "softphoneContainer");
            }}
            fallback={FallbackComponent}
          >
            <Suspense
              fallback={
                <Skeleton
                  height={32}
                  width={32}
                  sx={{
                    position: "fixed",
                    right: 27,
                    bottom: 27,
                    p: 0.5,
                  }}
                />
              }
            >
              <SoftPhoneContainer />
            </Suspense>
          </ErrorBoundary>
        )}
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            position: { xs: "relative", md: "sticky" },
            top: { xs: 0, md: 20 },
            zIndex: 100,
            backgroundColor: "background.default",
            py: { xs: 0, md: 0 },
          }}
        >
          <ErrorBoundary
            beforeCapture={(scope) => {
              scope.setTag("component", "personalinformation");
            }}
            fallback={FallbackComponent}
          >
            <Suspense fallback={<Skeleton width="100%" height={147} />}>
              <PersonalInformation />
            </Suspense>
          </ErrorBoundary>
          {hadQuanticButtonsViewPermission && (
            <ErrorBoundary
              beforeCapture={(scope) => {
                scope.setTag("view", "quanticButtons");
              }}
              fallback={FallbackComponent}
            >
              <Suspense fallback={<Skeleton height={25} />}>
                <QuanticButtons />
              </Suspense>
            </ErrorBoundary>
          )}
        </Box>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "panel");
          }}
          fallback={FallbackComponent}
        >
          <Box
            sx={{
              height: "calc(100vh - 258.75px)",
            }}
          >
            <Allotment snap={true} onVisibleChange={handleShowWaChatBot}>
              <Allotment.Pane className="allotmentPane1">
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    overflow: "auto",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    <ErrorBoundary
                      beforeCapture={(scope) => {
                        scope.setTag("component", "personalinformationoptions");
                      }}
                      fallback={FallbackComponent}
                    >
                      <Suspense
                        fallback={<Skeleton width="100%" height={100} />}
                      >
                        <PersonalInformationOptions />
                      </Suspense>
                    </ErrorBoundary>
                    <ErrorBoundary
                      beforeCapture={(scope) => {
                        scope.setTag("component", "personalinformationviews");
                      }}
                      fallback={FallbackComponent}
                    >
                      <Suspense
                        fallback={<Skeleton width="100%" height={369} />}
                      >
                        <PersonalInformationViews />
                      </Suspense>
                    </ErrorBoundary>
                    {hasViewPermissionContractDetails &&
                      !hasViewAdvancedContractDetailsPermission && (
                        <ErrorBoundary
                          beforeCapture={(scope) => {
                            scope.setTag("component", "contractdetails");
                          }}
                          fallback={FallbackComponent}
                        >
                          <Suspense
                            fallback={<Skeleton width="100%" height={341} />}
                          >
                            <ContractDetails />
                          </Suspense>
                        </ErrorBoundary>
                      )}
                    {hasViewPermissionContractDetails &&
                      hasViewAdvancedContractDetailsPermission && (
                        <ErrorBoundary
                          beforeCapture={(scope) => {
                            scope.setTag(
                              "component",
                              "advancedcontractdetails",
                            );
                          }}
                          fallback={FallbackComponent}
                        >
                          <Suspense
                            fallback={<Skeleton width="100%" height={341} />}
                          >
                            <AdvancedContractDetails />
                          </Suspense>
                        </ErrorBoundary>
                      )}
                    {hasViewPermissionContractData && (
                      <ErrorBoundary
                        beforeCapture={(scope) => {
                          scope.setTag("component", "contractdata");
                        }}
                        fallback={FallbackComponent}
                      >
                        <Suspense
                          fallback={<Skeleton width="100%" height={120} />}
                        >
                          {selectedContract.contrato !== "" && <ContractData />}
                        </Suspense>
                      </ErrorBoundary>
                    )}
                    <ErrorBoundary
                      beforeCapture={(scope) => {
                        scope.setTag("component", "contractoptions");
                      }}
                      fallback={FallbackComponent}
                    >
                      <Suspense
                        fallback={<Skeleton width="100%" height={100} />}
                      >
                        {selectedContract.contrato !== "" && (
                          <ContractOptions />
                        )}
                      </Suspense>
                    </ErrorBoundary>
                    <ErrorBoundary
                      beforeCapture={(scope) => {
                        scope.setTag("component", "contractoptionsviews");
                      }}
                      fallback={FallbackComponent}
                    >
                      <Suspense
                        fallback={<Skeleton width="100%" height={769} />}
                      >
                        {selectedContract.contrato !== "" && (
                          <ContractOptionsViews />
                        )}
                      </Suspense>
                    </ErrorBoundary>
                  </Box>
                </Box>
              </Allotment.Pane>
              <Allotment.Pane
                visible={showWaChatBot}
                className="allotmentPane2"
              >
                <ErrorBoundary
                  beforeCapture={(scope) => {
                    scope.setTag("component", "waChatBot");
                  }}
                  fallback={FallbackComponent}
                >
                  <Suspense fallback={<Skeleton sx={{ height: "100%" }} />}>
                    {showWaChatBot && matches && <WaChatBot />}
                  </Suspense>
                </ErrorBoundary>
              </Allotment.Pane>
            </Allotment>
          </Box>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "payments");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={
              <Modal open={true} sx={{ mx: 2 }}>
                <Skeleton
                  height={341}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: {
                      xs: "calc(100% - 32px)",
                      md: "100%",
                    },
                    maxWidth: 672,
                  }}
                />
              </Modal>
            }
          >
            {showPaymentsDataGrid && <Payments />}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "guarantees");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={
              <Modal open={true} sx={{ mx: 2 }}>
                <Skeleton
                  height={191}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: {
                      xs: "calc(100% - 32px)",
                      md: "100%",
                    },
                    maxWidth: 1052,
                  }}
                />
              </Modal>
            }
          >
            {showGuaranteesDataGrid && <Guarantees />}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "quotes");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={
              <Modal open={true} sx={{ mx: 2 }}>
                <Skeleton
                  height={500}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: {
                      xs: "calc(100% - 32px)",
                      md: "100%",
                    },
                    maxWidth: 1052,
                  }}
                />
              </Modal>
            }
          >
            {showQuotesDataGrid && <Quotes />}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "debtDistribution");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={
              <Modal open={true} sx={{ mx: 2 }}>
                <Skeleton
                  height={500}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: {
                      xs: "calc(100% - 32px)",
                      md: "100%",
                    },
                    maxWidth: 1020,
                  }}
                />
              </Modal>
            }
          >
            {showDebtDistributionDataGrid && <DebtDistribution />}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "orderItems");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={
              <Modal open={true} sx={{ mx: 2 }}>
                <Skeleton
                  height={500}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: {
                      xs: "calc(100% - 32px)",
                      md: "100%",
                    },
                    maxWidth: 870,
                  }}
                />
              </Modal>
            }
          >
            {showOrderItemsDataGrid && <OrderItems />}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "articles");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={
              <Modal open={true} sx={{ mx: 2 }}>
                <Skeleton
                  height={500}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: {
                      xs: "calc(100% - 32px)",
                      md: "100%",
                    },
                    maxWidth: 830,
                  }}
                />
              </Modal>
            }
          >
            {showArticlesCard && <Articles />}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "monthlyPayments");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={
              <Modal open={true} sx={{ mx: 2 }}>
                <Skeleton
                  height={500}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: {
                      xs: "calc(100% - 32px)",
                      md: "100%",
                    },
                    maxWidth: 830,
                  }}
                />
              </Modal>
            }
          >
            {showMonthlyPaymentsCard && <MonthlyPayments />}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "explanations");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={
              <Modal open={true} sx={{ mx: 2 }}>
                <Skeleton
                  height={500}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: {
                      xs: "calc(100% - 32px)",
                      md: "100%",
                    },
                    maxWidth: 830,
                  }}
                />
              </Modal>
            }
          >
            {showExplanationsCard && <Explanations />}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "addendum");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={
              <Modal open={true} sx={{ mx: 2 }}>
                <Skeleton
                  height={500}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: {
                      xs: "calc(100% - 32px)",
                      md: "100%",
                    },
                    maxWidth: 830,
                  }}
                />
              </Modal>
            }
          >
            {showAddendumCard && <Addendum />}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "accountStatement");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={
              <Modal open={true} sx={{ mx: 2 }}>
                <Skeleton
                  height={500}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: {
                      xs: "calc(100% - 32px)",
                      md: "100%",
                    },
                    maxWidth: 830,
                  }}
                />
              </Modal>
            }
          >
            {showCardAccountStatement && <AccountStatement />}
          </Suspense>
        </ErrorBoundary>
      </StrictMode>
    </>
  );
}

Component.displayName = "ClientManagement";
