import { useFields } from "~/hooks/useFields";
import { useTranslation } from "react-i18next";

/**
 * Custom hook that returns the translated field name based on the sequential number and field type.
 * @param sequential The sequential number of the field.
 * @param fieldType The type of the field.
 * @param count The count used for pluralization (optional).
 * @returns The translated field name or null if not found.
 */
export const useFieldName = (
  sequential: number,
  fieldType:
    | "contract"
    | "client"
    | "name"
    | "identification"
    | "workplace"
    | "position"
    | "profession"
    | "daysInArrears"
    | "country"
    | "area"
    | "originalBalance",
  count?: number,
): string | null => {
  const { fields } = useFields();
  const { t: tFields } = useTranslation("fields");

  if (fields !== undefined) {
    // If fields is defined, find the field with the matching sequential value.
    const headerKey = fields.find(
      (field) => field.sequential === sequential,
    )?.value;

    if (headerKey) {
      // If a field with the matching sequential value is found and its value is truthy,
      // use it as a key to get a translated string from the tFields function.
      // The fieldType is prepended to the key to form a fully qualified key for the translation function.
      if (count) {
        // If count is defined and truthy, pass it as a parameter to the translation function.
        // This is useful for pluralization in translations.
        return tFields(`${fieldType}.${headerKey}` as never, { count });
      }
      // If count is not defined or falsy, do not pass it to the translation function.
      return tFields(`${fieldType}.${headerKey}` as never);
    }
  }
  // If fields is not defined or no field with the matching sequential value is found,
  // or the found field's value is falsy, return null.
  return null;
};
