import { useUserPass } from "~/app/useLogin";
import LoginIcon from "@mui/icons-material/Login";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { nanoid } from "nanoid";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";

function Component() {
  const {
    user,
    showOtp,
    loadingResponse,
    handleTextFieldChange,
    handleSelectChange,
    handleChangeCheckbox,
    checked,
    handleForgotPassword,
    showResetPasswordPrompt,
    showTypeResetPasswordCodePrompt,
    handleLoginSubmit,
  } = useUserPass();
  const { t } = useTranslation("login");
  const companies = useLoaderData() as {
    company: string;
    catalog: string;
  }[];

  return (
    <>
      {!(
        showOtp ||
        showResetPasswordPrompt ||
        showTypeResetPasswordCodePrompt
      ) && (
        <form onSubmit={handleLoginSubmit}>
          <FormControl
            fullWidth={true}
            required={true}
            disabled={loadingResponse}
          >
            <InputLabel htmlFor="company">{t("company")}</InputLabel>
            <Select
              notched={true}
              id="company"
              name="company"
              label={t("company")}
              value={user.company}
              onChange={handleSelectChange("company")}
              fullWidth={true}
            >
              {companies?.map((values) => (
                <MenuItem value={values.company} key={nanoid()}>
                  {values.company}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            disabled={loadingResponse}
            margin="normal"
            required={true}
            fullWidth={true}
            id="username"
            label={t("username")}
            type="text"
            name="username"
            autoComplete="email"
            value={user.userName}
            onChange={handleTextFieldChange("userName")}
          />
          <TextField
            disabled={loadingResponse}
            margin="normal"
            required={true}
            fullWidth={true}
            name="password"
            label={t("password")}
            type="password"
            id="password"
            autoComplete="current-password"
            value={user.password}
            onChange={handleTextFieldChange("password")}
          />
          <Box
            sx={{
              display: "flex",
              gap: 1,
              flexDirection: { xs: "column", sm: "column", md: "row" },
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              disabled={loadingResponse}
              control={<Checkbox value="remember" color="primary" />}
              checked={checked}
              onChange={handleChangeCheckbox}
              label={t("rememberPassword")}
            />
            <Button type="button" variant="text" onClick={handleForgotPassword}>
              {t("forgotPassword")}
            </Button>
          </Box>
          <Button
            disabled={loadingResponse}
            type="submit"
            fullWidth={true}
            variant="contained"
            startIcon={<LoginIcon />}
            sx={{ mt: 3, mb: 2 }}
          >
            {t("button")}
          </Button>
        </form>
      )}
    </>
  );
}

Component.displayName = "UserPass";
export const UserPass = Component;
