import type { Catalogs, Holidays } from "~/requests";
import type Resources from "~/types/resources";
import type { GridColDef, GridValidRowModel } from "@mui/x-data-grid/models";
import type { FormEvent, MouseEvent, ReactElement, ReactNode } from "react";
import type { FieldsNotchedSelectProps } from "./props";
import type { QueryClient } from "@tanstack/react-query";

type HeadersInit = Headers | Record<string, string>;

export type ClientPortfolioR = {
  orden: number;
  rangoMora?: string;
  saldoTotal?: number;
  cantidad?: number;
  gestionesTotales?: number;
};

export type ClientFinderSearchR = {
  contrato: string;
  cliente: string;
  nombreCliente: string;
  identificacion: string;
  saldoActual: number;
  saldoMoroso: number;
  diasAtraso: string;
  producto: string;
  estado1: string;
  estado2: string;
  telefono: string;
  tipoCliente: string;
  activa: boolean;
};

export type ClientFinderSearchB = {
  contractFilter?: string | null;
  clientFilter?: string | null;
  nameFilter?: string | null;
  lastNameFilter?: string | null;
  currentBalanceFilter?: string | null;
  overdueBalanceFilter?: string | null;
  daysOverdueFilter?: string | null;
  productFilter?: string | null;
  clientTypeFilter?: string | null;
  identificationFilter?: string | null;
  status1Filter?: string | null;
  status2Filter?: string | null;
  contactInfoFilter?: string | null;
  workplaceFilter?: string | null;
  promiseFilter?: string | null;
};

export type SearchResultsB = {
  filter: string;
  texto: string;
};

export type PersonalInformationR = {
  cliente: string;
  nombreCliente: string;
  identificacion: string;
  sexo: string;
  fechaNacimiento: string;
  segmento: string;
  tipo: string;
  profesion: string;
  cargo: string;
  lugarTrabajo: string;
  fechaActualizado: string;
  edad: string;
  pais: string;
  area: string;
  cancelationAmount: string;
};

export type PersonalInformationB = {
  cliente: number;
};

export type ContractDataB = Pick<ClientFinderSearchR, "contrato" | "cliente">;

export type ContractDataR = {
  contrato: string;
  cliente: number;
  fechaLiquida: string;
  fechaVencimiento: string;
  fechaVencimientoAuxiliar: string;
  fechaUltimoPago: string;
  fechaCorte: string;
  diaCorte: number;
  saldoOriginal: number;
  saldoActual: number;
  tasaInteres: number;
  letraMensual: number;
  diaPago: number;
  diasMorosidad: number;
  saldoMorosidad: number;
  tipoProducto: string;
  tipoSubproducto: string;
  oficialCred: string;
  formaPago: string;
  estado1: string;
  estado2: string;
  capMorosidad: number;
  intMorosidad: number;
  imoMorosidad: number;
  feciMorosidad: number;
  segMorosidad: number;
  comMorosidad: number;
  otrosMorosidad: number;
  codigoGarantia1: string;
  codigoGarantia2: string;
  fecha: string;
};

export type InfoCard = {
  name: string;
  data?: string | number | null;
  icon?: ReactElement;
  hidden?: boolean;
};

export type PersonalReferencesR = {
  sequential: number;
  client: number;
  type: string;
  name: string;
  phone1: string;
  source: string;
  status: string;
  user: string;
  date: Date;
  phone2: string;
  phone3: string;
};

export type AddPersonalReferencesB = {
  client: number;
  type: string;
  name: string;
  phone1: string;
  source: string;
  status: string;
  user: string;
  date: Date;
  phone2: string;
  phone3: string;
};

export type EditPersonalReferencesB = {
  client: number;
  type: string;
  name: string;
  phone1: string;
  source: string;
  status: string;
  user: string;
  date: Date;
  phone2: string;
  phone3: string;
};

export type AddDemographicData = {
  client: number;
  country: number;
  province: string;
  district: string;
  town: string;
  neighborhood: string;
  description: string;
  type: string;
  source: string;
  status: string;
  user: string;
  date: Date;
  segment: string;
  houseApt: string;
  latitude: number | null;
  longitude: number | null;
  address: string | null;
};

export type EditDemographicData = {
  client: number;
  country: number;
  province: string;
  district: string;
  town: string;
  neighborhood: string;
  description: string;
  type: string;
  source: string;
  status: string;
  user: string;
  date: Date;
  segment: string;
  houseApt: string;
  latitude: number | null;
  longitude: number | null;
  address: string | null;
};

export type AddEmploymentDataB = {
  client: number;
  country: number;
  province: string;
  district: string;
  town: string;
  neighborhood: string;
  description: string;
  employerName: string;
  position: string;
  salary: number;
  source: string;
  status: string;
  user: string;
  date: Date;
};

export type EditEmploymentDataB = {
  client: number;
  country: number;
  province: string;
  district: string;
  town: string;
  neighborhood: string;
  description: string;
  employerName: string;
  position: string;
  salary: number;
  source: string;
  status: string;
  user: string;
  date: Date;
};

export type AddContactDetailsB = {
  client: number;
  type: string;
  data: string;
  status: string;
  source: string;
  user: string;
  date: Date;
  good: string;
  segment: string;
};

export type EditContactDetailsB = {
  client: number;
  type: string;
  data: string;
  status: string;
  source: string;
  user: string;
  date: Date;
  good: string;
  segment: string;
};

export type AddManagementB = {
  contract: string;
  client: number;
  date: Date;
  executive: string;
  strategyCode: string;
  infoText: string;
  balance: number;
  deliquencyBalance: number;
  daysOverdue: number;
  state1: string;
  state2: string;
  monthlyLetter: number;
  managementCode: string;
  contactCode: string;
  overdueReason: string;
  strategyDate: Date;
};

export type PaymentAgreementsR = {
  strategyCode: string;
  contract: string;
  client: number;
  startDate: Date;
  paymentDay: number;
  paymentNumber: number;
  amount: number;
  paymentMethod: string;
  executive: string;
  createDate: Date;
  type: string;
  nextPaymentDate: Date;
  sequential: number;
};

export type AddPaymentAgreementsB = {
  strategyCode: string;
  contract: string;
  client: number;
  startDate: Date;
  paymentDay: number;
  paymentNumber: number;
  amount: number;
  paymentMethod: string;
  executive: string;
  createDate: Date;
  type: string;
  nextPaymentDate: Date;
};

export type EditPaymentAgreementsB = {
  strategyCode: string;
  contract: string;
  client: number;
  startDate: Date;
  paymentDay: number;
  paymentNumber: number;
  amount: number;
  paymentMethod: string;
  executive: string;
  createDate: Date;
  type: string;
  nextPaymentDate: Date;
};

export type Promises = {
  strategyCode: string;
  contract: string;
  client: number;
  date: Date;
  amount: number;
  paymentMethod: string;
  executive: string;
  createDate: Date;
  strategyDate: Date;
  state: string;
  sequential: number;
};

export type AddPromisesB = {
  strategyCode: string;
  contract: string;
  client: number;
  date: Date;
  amount: number;
  paymentMethod: string;
  executive: string;
  createDate: Date;
  strategyDate: Date;
  state: string;
};

export type EditPromisesB = {
  strategyCode: string;
  contract: string;
  client: number;
  date: Date;
  amount: number;
  paymentMethod: string;
  executive: string;
  createDate: Date;
  strategyDate: Date;
  state: string;
};

export type ContractDetails = {
  producto: string;
  subproducto: string;
  contrato: string;
  saldoActual: string;
  diasMorosidad: string;
  estado: string;
  formaPago: string;
  saldoMorosidad: string;
  existeGarantia: boolean;
  cliente: number;
};

export type ContractDetailsB = {
  username: string;
};

export type Action =
  | "edit"
  | "delete"
  | "inactivate"
  | "activate"
  | "add"
  | "payments"
  | "guarantees"
  | "cuotas"
  | "mark"
  | "notMark"
  | "";

export type GridActionClick<T> = (params: {
  e: MouseEvent;
  row: T & { estado?: string };
  action: Action;
}) => void;

export type GridActionClickWithoutIdAndAction<T> = (
  params: Pick<Parameters<GridActionClick<T>>[0], "e" | "row">,
) => void;

export type ColumnFieldName<T extends GridValidRowModel> = {
  field: keyof T | "actionsButtons";
} & GridColDef<T>;

export type HttpMethod = "GET" | "POST" | "PUT" | "DELETE";

export type AssignExecutiveR = {
  column1: string;
};

export type AssignExecutiveB = {
  contratoSeleccionado: string;
  username: string;
  numeroSeleccionado: string;
};

export type GenerateOtpB = {
  username: string;
};

export type ValidateOtpB = {
  otp: string;
  username: string;
};

export type GetParams = {
  url: string;
  headers?: HeadersInit;
};

export type PostParams<Tbody> = {
  url: string;
  headers?: HeadersInit;
  body?: Tbody;
  isJson?: boolean;
};

export type ActionPostFetch =
  | "edit"
  | "delete"
  | "inactivate"
  | "activate"
  | "add"
  | "mark"
  | "assign"
  | "save"
  | "finalize"
  | "download"
  | "notMark"
  | "call"
  | "pause"
  | "restart"
  | "consult"
  | "transfer"
  | "conference"
  | "onWait"
  | "resuming"
  | "hangup"
  | "return"
  | "change"
  | "select"
  | "upload"
  | "finish"
  | "send";

export type ItemPostFetch =
  | "strategyMaintenance"
  | "catalogsMaintenance"
  | "formatsMaintenance"
  | "contactDetails"
  | "demographicData"
  | "employmentData"
  | "formatMaintenance"
  | "executiveMaintenance"
  | "groupMaintenance"
  | "holidayMaintenance"
  | "management"
  | "operatingFlows"
  | "pauseMaintenance"
  | "paymentAgreements"
  | "personalReferences"
  | "promises"
  | "reschedules"
  | "flow"
  | "stage"
  | "stageData"
  | "document"
  | "attachment"
  | "assignment"
  | "permission"
  | "role"
  | "fieldsMaintenance"
  | "liability"
  | "call"
  | "password"
  | "date"
  | "appVariable"
  | "number"
  | "file"
  | "list"
  | "notification"
  | "client"
  | "appPersonalization"
  | "report";

export type PostAndFetchParams<Tbody> = {
  url: string;
  headers: HeadersInit;
  body: Tbody;
  setLoading?: (value: boolean) => void;
  fetch?: () => void;
  item: ItemPostFetch;
  action: ActionPostFetch;
  context: "female" | "male";
  isJson?: boolean;
  showSucess?: boolean;
  queryClient: QueryClient;
};

export type ActionMap = {
  [_key in ActionPostFetch]: string;
};

export type ActionKeyState = "success" | "error" | "warning" | "reschedule";

export type OperatingFlowR = {
  id: string;
  codigoFlujo: string;
  nombreFlujo: string;
  contrato: string;
  fecha: string;
  usuario: string;
  usuarioAsignado: string;
  estado: string;
};

export type OperatingFlowB = Pick<ClientFinderSearchR, "cliente">;

export type GuaranteesR = {
  guaranteeNumber: string;
  contract: string;
  client: number;
  guaranteeType: string;
  createDate: Date;
  lastValidationDate: Date;
  originalValue: number;
  currentValue: number;
  province: string;
  district: string;
  town: string;
  neighborhood: string;
  houseApartment: string;
  detailAddress: string;
  estate: number;
  invoice: number;
  roll: number;
  projectName: string;
  projectYear: number;
  brand: string;
  model: string;
  color: string;
  licensePlate: string;
  chassisNumber: string;
  informationDate: Date;
};

export type GuaranteeOwnersR = {
  sequential: number;
  guaranteeNumber: string;
  identification: string;
  client: number;
  firstName: string;
  lastName: string;
  informationDate: Date;
};

export type PaymentsR = {
  id: string;
  contrato: string;
  fechaPago: string;
  montoPago: string;
  formaPago: string;
};

export type PaymentsB = Pick<ContractDetails, "contrato" | "cliente">;

export type QuotesR = {
  sequential: number;
  contract: string;
  client: number;
  quoteStatus: string;
  startDate: Date;
  endDate: Date;
  quoteTotal: number;
  quotePayment: number;
  principal: number;
  interest: number;
  lateInterest: number;
  feci: number;
  charges: number;
  otherCharges: number;
};

export type SearchStrategiesB = {
  username: string;
};

export type SearchStrategiesR = {
  codigo: string;
  nombre: string;
  grupo: number;
  subgrupo: string;
  fecha: string;
  agrupacion: number;
};

export type AssignStrategiesB = {
  codigoEstrategia: string;
  username: string;
};

export type ManagementExecutiveR = {
  totalGestiones: number;
  directo: number;
  noDirecto: number;
};

export type ManagementExecutiveB = {
  username: string;
  year: number | null;
  month: number | null;
  day: number | null;
};

export type PromisesExecutiveR = {
  totalPromesas: number;
  cumplida: number;
  noCumplida: number;
};

export type PromisesExecutiveB = {
  username: string;
  year: number | null;
  month: number | null;
  day: number | null;
};

export type AmountExecutiveR = {
  monto: number;
};

export type AmountExecutiveB = {
  username: string;
  year: number | null;
  month: number | null;
  day: number | null;
};

export type FilterStrategies = {
  year: number | null;
  month: number | null;
  day: number | null;
};

export type MonthDay = {
  number: number;
};

export type Months = {
  number: number;
  name: string;
};

export type StrategyListR = {
  codigo: string;
  nombre: string;
  grupo: number;
  fecha: string;
  cantidad: number;
  agrupacion: number;
};

export type StrategyListB = {
  username: string;
};

export type StrategyListInformationR = {
  secuencial: number;
  cliente: number;
  nombreCliente: string;
  saldoTotal: number;
  saldoTotalMora: number;
  diasMoraMax: number;
  cantidadContratos: number;
  estadoContrato: string;
  gestiones: number;
  ultimaFechaGestion: string;
  fechaReagenda: string;
  contrato: string;
};

export type StrategyListInformationB = {
  username: string;
  codigoEstrategia: string;
};

export type ClientAction = {
  action: "gestionar" | "no gestionar";
};

export type MarkClient = {
  cliente: number;
  username: string;
};

export type StrategiesR = {
  codeStrategy: string;
  name: string;
  definition: string;
  createDate: Date;
  modifyDate: Date;
  group: string;
  subgroup: string;
  intensity: number;
  detail: string;
  channel: string;
  state: string;
  type: string;
  order: string;
  grouping: number;
  sequential: number;
};

export type EditStrategyB = {
  codeStrategy: string;
  name: string;
  definition: string;
  createDate: Date;
  modifyDate: Date;
  group: string;
  subgroup: string;
  intensity: number;
  detail: string;
  channel: string;
  state: string;
  type: string;
  order: string;
  grouping: number;
};

export type AddStrategyB = {
  codeStrategy: string;
  name: string;
  definition: string;
  createDate: Date;
  modifyDate: Date;
  group: string;
  subgroup: string;
  intensity: number;
  detail: string;
  channel: string;
  state: string;
  type: string;
  order: string;
  grouping: number;
};

export type ReschedulesR = {
  client: number;
  visitDate: Date;
  visitTime: Date;
  visitType: string;
  executive: string;
  date: Date;
  segment: string;
  sequential: number;
};

export type AddReschedulesB = {
  client: number;
  visitDate: Date;
  visitTime: Date;
  visitType: string;
  executive: string;
  date: Date;
  segment: string;
};

export type DebtDistributionR = {
  diasMora: number;
  saldoActual: number;
};

export type DebtDistributionB = {
  cliente: number;
};

export type DataChartValues = {
  diasMora: string;
  saldoActual: number;
  count: number;
};

export type DynamicDataFieldsB = {
  campoDinamico: string;
};

export type DynamicDataFieldsR = {
  info: string;
  id: string;
};

export type FormatsR = {
  name: string;
  channel: string;
  quantity: number;
  text1: string;
  text2: string;
  text3: string;
  text4: string;
  text5: string;
  date: Date;
  subject: string;
  products: number;
  message: string;
  sequential: number;
};

export type MenuItem = {
  icon?: ReactNode;
  iconOutlined?: ReactNode;
  title: string;
  to: string;
  items: MenuItem[];
  hidden?: boolean;
};

export type CatalogsR = {
  sequential: number;
  type: string;
  relation: number;
  description: string;
  status: string;
};

export type UsersR = {
  userCode: string;
  userName: string;
  userGroup: string;
  subGroup: string;
  userStatus: string;
  userEmail: string;
  userDate: Date;
  password: string;
  company: string;
  sequential: number;
  userRole: string;
};

export type AddUsersB = {
  userCode: string;
  userName: string;
  userGroup: string;
  subGroup: string;
  userStatus: string;
  userEmail: string;
  userDate: Date;
  password: string;
  company: string;
  userRole: string;
};

export type EditUsersB = {
  userCode: string;
  userName: string;
  userGroup: string;
  subGroup: string;
  userStatus: string;
  userEmail: string;
  userDate: Date;
  password: string;
  company: string;
  userRole: string;
};

export type AddGroupsB = {
  groupCode: string;
  groupName: string;
  subgroupCode: string;
  subgroupName: string;
  supervisor: string;
  groupDate: Date;
};

export type EditGroupsB = {
  groupCode: string;
  groupName: string;
  subgroupCode: string;
  subgroupName: string;
  supervisor: string;
  groupDate: Date;
};

export type SelectsMetricsReportR = {
  secuencial: number;
  descripcion: string;
  diario: boolean;
  mensual: boolean;
  anual: boolean;
};

export type MetricsReportB = {
  opcion: number;
  nombre: string;
  param2: string | null;
  param3: string | null;
};

export type MetricsReportR = { [keyValue: string]: number | string };

export type MetricsDashboardGraphsB = {
  opcion: string | null;
  fecha: Date;
};

export type MetricsDashboardCardsR = {
  saldoVencer: number;
  saldoVencido: number;
  promesaCumplida: number;
  promesaTotal: number;
  pagoTotalMes: number;
  saldoTotalNAB: number;
  saldoTotal: number;
};

export type MetricsDashboardCardsB = {
  opcion: string | null;
};

export type MetricsDashboardDataSourceKey = keyof MetricsDashboardCardsR;

export type MetricsDashBoardCardsDataSource = {
  key: MetricsDashboardDataSourceKey;
  value: number;
};

export type CarteraTotalItem = {
  orden: number;
  rangoMora: string;
  saldoTotal: number;
  cantidad: number;
  gestionesTotales: number;
};

export type CarteraTotalVencimientoItem = {
  orden: number;
  rangoVencimiento: string;
  saldoTotal: number;
  cantidad: number;
};

type DiaPagoTotalItem = {
  dia: string;
  cantidad: number;
  saldoTotal: number;
};

type PromesasTotalItem = {
  orden: number;
  saldoTotal: number;
  saldoVencimiento: number;
  cantidad: number;
};

type DesembolsoTotalItem = {
  anio: string;
  cantidad: number;
  saldoTotal: number;
  saldoTotalNAB: number;
};

type ComparativoMismoDiaMesItem = {
  mes: number;
  _2015: number;
  _2016: number;
  _2017: number;
  _2018: number;
  _2019: number;
  _2020: number;
  _2021: number;
  _2022: number;
};

type ComparativoCierreMesItem = {
  mes: number;
  _2015: number;
  _2016: number;
  _2017: number;
  _2018: number;
  _2019: number;
  _2020: number;
  _2021: number;
  _2022: number;
};

type TotalGestionesEjecutivosItem = {
  ejecutivo: string;
  directo: number;
  noDirecto: number;
  total: number;
  contactabilidad: number;
};

export type MetricsDashboardGraphsR = {
  carteraTotal: CarteraTotalItem[] | null;
  carteraTotalVencimiento: CarteraTotalVencimientoItem[] | null;
  diaPagoTotal: DiaPagoTotalItem[] | null;
  promesasTotal: PromesasTotalItem[] | null;
  desembolsoTotal: DesembolsoTotalItem[] | null;
  comparativoMismoDiaMes: ComparativoMismoDiaMesItem[] | null;
  comparativoCierreMes: ComparativoCierreMesItem[] | null;
  totalGestionesEjecutivos: TotalGestionesEjecutivosItem[] | null;
};

export type MetricsDashboardGraphsDataSourceKey = keyof MetricsDashboardGraphsR;

type MetricsDashboardGraphsValueR = {
  [K in keyof MetricsDashboardGraphsR]: MetricsDashboardGraphsR[K];
}[keyof MetricsDashboardGraphsR];

export type MetricsDashBoardGraphsDataSource = {
  key: MetricsDashboardGraphsDataSourceKey;
  value: MetricsDashboardGraphsValueR;
};

export type MetricsDashBoardGraphsMap = {
  [_K in MetricsDashboardGraphsDataSourceKey]: ReactNode;
};

export type MetricsDashboardActiveStrategiesR = {
  id: string;
  codigoEstrategia: string;
  nombreEstrategia: string;
  cliente: number;
  contrato: string;
  saldo: number;
  saldoMora: number;
  gestionado: number;
  diasMora: number;
  canal: string;
};

export type AddFlowB = {
  contrato: string;
  cliente: number;
  fecha: Date;
  codigoFlujo: string;
  usuario: string;
};

export type CompletedStagesR = {
  secuencial: number;
  nombreEtapa: string;
  usuario: string;
  codigoEtapa: string;
  fechaFinalizacionEtapa: Date;
  fechaInicioEtapa: Date;
};

export type CompletedStagesB = {
  contrato: string;
  fechaFlujo: Date;
  codigoFlujo: string;
};

export type OperatingFlowsR = {
  flowCode: string;
  operationCode: string;
  flowName: string;
  stageNumber: number;
  group: string;
  sequential: number;
};

export type AddOperatingFlowsB = {
  flowCode: string;
  operationCode: string;
  flowName: string;
  stageNumber: number;
  group: string;
};

export type EditOperatingFlowsB = {
  flowCode: string;
  operationCode: string;
  flowName: string;
  stageNumber: number;
  group: string;
};

export type FlowDocumentsR = {
  flow: string;
  fileName: string;
  documentName: string;
  uploadDate: Date;
  user: string;
  file: ArrayBuffer | string;
  sequential: number;
};

export type AddFlowDocumentsB = {
  flow: string;
  fileName: string;
  documentName: string;
  uploadDate: Date;
  user: string;
  file: ArrayBuffer | string;
};

export type FlowContractDetailsR = {
  id: string;
  contrato: string;
  cliente: number;
  fechaFlujo: Date;
  codigoEtapa: string;
  nombreCliente: string;
  numeroTareas: number;
  tiempo: number;
  secuencial: number;
  usuarioAsignado: string;
};

export type FlowContractDetailsB = {
  codigoFlujo: string;
  estado: string;
};

export type FlowsByStateR = {
  id: string;
  codigoFlujo: string;
  nombreFlujo: string;
  estado: string;
  cantidad: number;
};

export type FlowsByStateB = {
  codigoFlujo: string | null;
};

export type FlowListR = {
  nombreFlujo: string;
  codigoFlujo: string;
  grupoFlujo: string;
  cantidad: number;
};

export type TaskNumbers = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export type FlowStagesR = {
  flowCode: string;
  stageCode: string;
  stageName: string;
  numTasks: number;
  time: number;
  dataType1: number;
  taskName1: string;
  dataType2: number;
  taskName2: string;
  dataType3: number;
  taskName3: string;
  dataType4: number;
  taskName4: string;
  dataType5: number;
  taskName5: string;
  dataType6: number;
  taskName6: string;
  dataType7: number;
  taskName7: string;
  dataType8: number;
  taskName8: string;
  dataType9: number;
  taskName9: string;
  dataType10: number;
  taskName10: string;
  stageNumber: number;
  sequential: number;
};

export type AddFlowStagesB = {
  flowCode: string;
  stageCode: string;
  stageName: string;
  numTasks: number;
  time: number;
  dataType1: number;
  taskName1: string;
  dataType2: number;
  taskName2: string;
  dataType3: number;
  taskName3: string;
  dataType4: number;
  taskName4: string;
  dataType5: number;
  taskName5: string;
  dataType6: number;
  taskName6: string;
  dataType7: number;
  taskName7: string;
  dataType8: number;
  taskName8: string;
  dataType9: number;
  taskName9: string;
  dataType10: number;
  taskName10: string;
  stageNumber: number;
};

export type EditFlowStagesB = {
  flowCode: string;
  stageCode: string;
  stageName: string;
  numTasks: number;
  time: number;
  dataType1: number;
  taskName1: string;
  dataType2: number;
  taskName2: string;
  dataType3: number;
  taskName3: string;
  dataType4: number;
  taskName4: string;
  dataType5: number;
  taskName5: string;
  dataType6: number;
  taskName6: string;
  dataType7: number;
  taskName7: string;
  dataType8: number;
  taskName8: string;
  dataType9: number;
  taskName9: string;
  dataType10: number;
  taskName10: string;
  stageNumber: number;
};

export type StageObservationsR = {
  id: string;
  codigoFlujo: string;
  codigoEtapa: string;
  usuario: string;
  fecha: Date;
  detalle: string;
};

export type StageObservationsB = {
  contrato: string;
  cliente: number;
  fechaFlujo: Date;
  codigoFlujo: string;
};

export type StagesOptionsR = {
  codigoEtapa: string;
  nombreEtapa: string;
};

export type StagesOptionsB = {
  contrato: string;
  fechaFlujo: Date;
  codigoFlujo: string;
};

export type FlowDataR = {
  contrato: string;
  cliente: number;
  fechaFlujo: Date;
  codigoFlujo: string;
  codigoEtapa: string;
  usuario: string;
  info1: string;
  info2: string;
  info3: string;
  info4: string;
  info5: string;
  info6: string;
  info7: string;
  info8: string;
  info9: string;
  info10: string;
  comentario: string;
  fecha: Date;
};

export type FlowDataB = {
  contrato: string;
  cliente: number;
  fechaFlujo: Date;
  codigoFlujo: string;
  codigoEtapa: string;
};

export type AddFlowDataB = {
  contrato: string;
  cliente: number;
  fechaFlujo: Date;
  codigoFlujo: string;
  codigoEtapa: string;
  usuario: string;
  info1: string;
  info2: string;
  info3: string;
  info4: string;
  info5: string;
  info6: string;
  info7: string;
  info8: string;
  info9: string;
  info10: string;
  comentario: string;
};

export type ChangeStageB = {
  contrato: string;
  cliente: number;
  fechaFlujo: Date;
  codigoFlujo: string;
  codigoEtapa: string;
  usuario: string;
  info1: string;
  info2: string;
  info3: string;
  info4: string;
  info5: string;
  info6: string;
  info7: string;
  info8: string;
  info9: string;
  info10: string;
  comentario: string;
};

export type EndFlowB = {
  contrato: string;
  cliente: number;
  fechaFlujo: Date;
  codigoFlujo: string;
  usuario: string;
  comentario: string;
};

export type ClientDataB = {
  numeroCliente: number;
};

export type ClientDataR = {
  nombreCliente: string;
  identificacion: string;
};

export type AddAttachedFilesB = {
  client: number;
  process: string;
  stage: string;
  fileName: string;
  uploadDate: Date;
  user: string;
  file: ArrayBuffer | string;
};

export type AttachedFilesR = {
  client: number;
  process: string;
  stage: string;
  fileName: string;
  uploadDate: Date;
  user: string;
  file: ArrayBuffer | string;
  sequential: number;
};

type LanguageObject = {
  nativeName: string;
};

export type LanguagesObject = {
  [key: string]: LanguageObject;
};

export type StrategyFormatsR = {
  codeStrategy: string;
  format: string;
  date: Date;
  sequential: number;
};

export type AddStrategyFormatsB = {
  codeStrategy: string;
  format: string;
  date: Date;
};

export type AssignedClientB = {
  username: string;
};

export type AssignedClientR = {
  cliente: number;
};

export type UploadFileB = {
  file: File;
} & FormData;

export type UploadFileR = {
  fileUrl: string;
};

type StatusColors = "error" | "info" | "warning" | "success";

export type StatusMap = Record<string, StatusColors>;

type MetricsReportsOption = {
  param2: string;
  param3: string | null;
};

export type MetricsReportsOptions = Record<number, MetricsReportsOption>;

export type ContactCode =
  | "Llamada Entrante"
  | "Llamada Saliente"
  | "SMS"
  | "Visita"
  | "Correo Electrónico"
  | "Descuento Directo";

export type PaymentMethod =
  | "ACH"
  | "Sucursal"
  | "Ventanilla"
  | "Cheque"
  | "Banca en Linea"
  | "Tarjeta de credito"
  | "Cheque Listo";

export type AsyncFormEventHandler<T = HTMLFormElement> = (
  event: FormEvent<T>,
) => Promise<void>;

export type ImportB = {
  JSON: string;
  TableName: string;
};

export type RenderTree = {
  id: string;
  name: string;
  children?: readonly RenderTree[];
};

export type ExecutivesDistributionR = {
  codigo: string;
  fecha: Date;
  ejecutivo: string;
  cantidad: number;
};

export type ExecutivesDistributionB = {
  code: string;
};

export type RecurrentListsR = {
  codigo: string;
  tipoAsignacion: string;
  cantidad: number;
  subGrupo: string;
  fecha: Date;
};

export type ActiveListsR = {
  codigo: string;
  ejecutivos: number;
  asignados: number;
  cantidad: number;
};

export type AssignListB = {
  activeListCode: string;
  assignmentType: string;
  subGroupCode: string;
  executive: string;
  recurrent: boolean;
  amount: number;
};

export type DeleteListAssignmentB = {
  code: string;
};

export type DeleteRecurrentListB = {
  code: string;
};

export type RolesR = {
  roleName: string;
  sequential: number;
};

export type AddRoleB = {
  roleName: string;
};

export type EditRoleB = {
  roleName: string;
};

export type AddRolePermissionB = {
  roleId: number;
  permissionId: number;
};

type ParamData = {
  paramID?: number;
  paramName?: string;
  readableName?: string;
  paramDefaultValue?: string;
  paramType?: string;
};

export type UrlsR = {
  urlID?: number;
  url?: string;
  urlType?: string;
  paramData?: ParamData[];
};

export type OrderItemsR = {
  country: string;
  client: number;
  iD1: string;
  iD2: string;
  salesperson: string;
  orderDate: string;
  orderType: string;
  orderStatus: string;
  orderID: number;
  orderSubtotal: number;
};

export type ClientStrategyLetterB = {
  client: number;
};

export type ClientStrategyLetterR = {
  strategyCode: string;
};

export type GenerateLetterB = {
  client: number;
  strategyCode: string;
};

export type GenerateLetterR = {
  html: string;
};

export type CatalogsWithAction = Catalogs & { action?: Action };
export type HolidaysWithAction = Holidays & { action?: Action };
export type PausesWithAction = Catalogs & { action?: Action };

export type ContractKeys = keyof Resources["fields"]["contract"];
export type ClientKeys = keyof Resources["fields"]["client"];
export type NameKeys = keyof Resources["fields"]["name"];
export type IdentificationKeys = keyof Resources["fields"]["identification"];
export type WorkplaceKeys = keyof Resources["fields"]["workplace"];
export type PositionKeys = keyof Resources["fields"]["position"];
export type ProfessionKeys = keyof Resources["fields"]["profession"];
export type DaysInArrearsKeys = keyof Resources["fields"]["daysInArrears"];
export type CountryKeys = keyof Resources["fields"]["country"];
export type AreaKeys = keyof Resources["fields"]["area"];
export type OriginalBalanceKeys = keyof Resources["fields"]["originalBalance"];

export type SelectFieldOptions = {
  [key: string]: FieldsNotchedSelectProps[];
};

export type TreeDataNode = {
  id: string;
  label: string;
  disabled?: boolean;
  children?: TreeDataNode[];
};

export enum PagePaths {
  Home = "/home",
  Clients = "/clients",
  ClientFinder = "/client-finder",
  ClientManagement = "/client-management",
  Strategies = "/strategies",
  AssignedStrategies = "/assigned-strategies",
  MarkClient = "/mark-client",
  AssignLists = "/assign-lists",
  StrategyMaintenance = "/strategy-maintenance",
  FormatMaintenance = "/format-maintenance",
  FormatMaintenanceChannel = "/format-maintenance/:channelId",
  FormatMaintenanceChannelFormat = "/format-maintenance/:channelId/:formatId",
  CatalogsMaintenance = "/catalogs-maintenance",
  CatalogsMaintenanceCatalog = "/catalogs-maintenance/catalog",
  PauseMaintenance = "/pause-maintenance",
  HolidayMaintenance = "/holiday-maintenance",
  HolidayMaintenanceHoliday = "/holiday-maintenance/:id",
  GroupMaintenance = "/group-maintenance",
  Metrics = "/metrics",
  MetricsReports = "/metrics-reports",
  MetricsDashboard = "/metrics-dashboard",
  Configuration = "/configuration",
  ActiveAssignedFlows = "/active-assigned-flows",
  CreateFlow = "/create-flow",
  CreateFlowJuridical = "/create-flow-juridical",
  FlowMaintenance = "/flow-maintenance",
  LoadMaintenance = "/load-maintenance",
  ActiveAssignedFlowsJuridical = "/active-assigned-flows-juridical",
  FieldsMaintenance = "/fields-maintenance",
  ExecutiveMaintenance = "/executive-maintenance",
  Flows = "/flows",
  Juridical = "/juridical",
  IntegrationMaintenance = "/integration-maintenance",
  VitalPbxIntegration = "/integration-maintenance/vital-pbx",
  VicidialIntegration = "/integration-maintenance/vicidial",
  UcontactIntegration = "/integration-maintenance/u-contact",
  DocuwareIntegration = "/integration-maintenance/docuware",
  PersonalizationMaintenance = "/personalization-maintenance",
}

export type CatalogType =
  | "Estado Cliente"
  | "Campos Estrategia"
  | "Tipo Asignacion"
  | "Canal Estrategia"
  | "Gestion"
  | "Agrupacion Estrategia"
  | "Tipo Teléfono"
  | "Pais"
  | "Provincia"
  | "Distrito"
  | "Corregimiento"
  | "Tipo Dirección"
  | "Tipo Dato"
  | "Contacto"
  | "Razon"
  | "Forma Pago"
  | "Tipo Arreglo"
  | "Tipo Cliente"
  | "Tipo Reagenda"
  | "Estado Estrategia"
  | "Tipo Lista"
  | "Campos Estrategia";

export type MonthlyPaymentsR = {
  clientCode: number;
  letter: number;
  currentAmount: number;
  initialLetter: number;
  paidLetter: number;
  purchaseDate: Date;
};

export type EditLiabilitiesB = {
  number: string;
  clientCode: number;
  type: string;
  startDate: Date;
  endDate: Date;
  balance: number;
  date: Date;
};

export type AddLiabilitiesB = {
  number: string;
  clientCode: number;
  type: string;
  startDate: Date;
  endDate: Date;
  balance: number;
  date: Date;
};

export type LiabilitiesR = {
  id: number;
  number: string;
  clientCode: number;
  type: string;
  startDate: Date;
  endDate: Date;
  balance: number;
  date: Date;
};

export type AppVariableType = "vitalPBX" | "quantic" | "vicidial" | "ucontact";

export type PreviewSegmentationtB = {
  columns: string;
  orderBy: string;
  whereCondition: string;
};

export type PreviewSegmentationR = { [keyValue: string]: number | string };
