import { apiVersion } from "~/config/api";
import { UseCheckUserPermissionServiceGetCheckUserPermissionKeyFn } from "~/queries";
import type {
  GetCheckUserPermissionData,
  GetCheckUserPermissionResponse,
} from "~/requests";
import { createQueryFn } from "~/utils/query";

export const getCheckUserPermissionQuery = ({
  xDbCatalog,
  permissionId,
  userName,
}: GetCheckUserPermissionData) => ({
  queryKey: UseCheckUserPermissionServiceGetCheckUserPermissionKeyFn({
    xDbCatalog,
    permissionId,
    userName,
  }),
  queryFn: createQueryFn<GetCheckUserPermissionResponse>({
    method: "GET",
    url: `/api/v${apiVersion}/CheckUserPermission`,
    headers: {
      "X-DB-Catalog": xDbCatalog,
    },
    query: {
      userName,
      permissionId,
    },
    errors: {
      400: "Returns a ResponseBadRequestError object",
      401: "Returns a ResponseUnauthenticatedError object",
      422: "Returns a ResponseUnprocessableEntityError object",
      500: "Returns a ResponseInternalServerError object",
    },
  }),
});
