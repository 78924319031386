import { FallbackComponent } from "~/components/FallbackComponent";
import { Unauthorized } from "~/components/Unauthorized";
import { useFlowMaintenance } from "~/hooks/usePages";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import Skeleton from "@mui/material/Skeleton";
import { ErrorBoundary } from "@sentry/react";
import { Suspense, StrictMode } from "react";

const FlowSelection = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/flow_maintenance/_components/FlowSelection"
      )
    ).FlowSelection,
  };
});

const Flows = reactLazyWithRetry(async () => {
  return {
    default: (
      await import("~/app/(dashboard)/flow_maintenance/_components/Flows")
    ).Flows,
  };
});

const AddDocuments = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/active-assigned-flow/_components/AddDocuments"
      )
    ).AddDocuments,
  };
});

const StageFields = reactLazyWithRetry(async () => {
  return {
    default: (
      await import("~/app/(dashboard)/flow_maintenance/_components/StageFields")
    ).StageFields,
  };
});

const StageActions = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/flow_maintenance/_components/StageActions"
      )
    ).StageActions,
  };
});

const StageDetails = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/flow_maintenance/_components/StageDetails"
      )
    ).StageDetails,
  };
});

export const Component = () => {
  const { selectedValue, tasksList, hasViewPermission } = useFlowMaintenance();

  if (!hasViewPermission) {
    return <Unauthorized />;
  }

  return (
    <>
      <StrictMode>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "flowselection");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={90} />}
          >
            <FlowSelection />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "flows");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={127} />}
          >
            <Flows />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "stagefields");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={90} />}
          >
            {selectedValue.flowCode !== "" && <StageFields />}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "stageactions");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={125} />}
          >
            {selectedValue.flowCode !== "" && tasksList.length > 0 && (
              <StageActions />
            )}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "stagedetails");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={341} />}
          >
            {selectedValue.flowCode !== "" && <StageDetails />}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "adddocuments");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={117} />}
          >
            {selectedValue.flowCode !== "" && <AddDocuments />}
          </Suspense>
        </ErrorBoundary>
      </StrictMode>
    </>
  );
};
