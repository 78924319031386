import { FallbackComponent } from "~/components/FallbackComponent";
import { Unauthorized } from "~/components/Unauthorized";
import { useCreateFlow } from "~/hooks/usePages";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import Skeleton from "@mui/material/Skeleton";
import { ErrorBoundary } from "@sentry/react";
import { Suspense, StrictMode } from "react";

const id = "Juridical";

const Search = reactLazyWithRetry(async () => {
  return {
    default: (await import("~/app/(dashboard)/create-flow/_components/Search"))
      .Search,
  };
});

const SearchResults = reactLazyWithRetry(async () => {
  return {
    default: (
      await import("~/app/(dashboard)/create-flow/_components/SearchResults")
    ).SearchResults,
  };
});

const SelectedFlow = reactLazyWithRetry(async () => {
  return {
    default: (
      await import("~/app/(dashboard)/create-flow/_components/SelectedFlow")
    ).SelectedFlow,
  };
});

const FlowDetails = reactLazyWithRetry(async () => {
  return {
    default: (
      await import("~/app/(dashboard)/create-flow/_components/FlowDetails")
    ).FlowDetails,
  };
});

const CompletedStages = reactLazyWithRetry(async () => {
  return {
    default: (
      await import("~/app/(dashboard)/create-flow/_components/CompletedStages")
    ).CompletedStages,
  };
});
export function Component() {
  const {
    selectedFlowDetailRow,
    selectedContractRow,
    contracts,
    hasViewPermission,
  } = useCreateFlow({
    id,
  });

  if (!hasViewPermission) {
    return <Unauthorized />;
  }
  return (
    <>
      <StrictMode>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "search");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={90} />}>
            <Search />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "searchresults");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={342} />}>
            {contracts.length > 0 && <SearchResults id={id} />}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "selectedflow");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={91} />}>
            {selectedContractRow.cliente !== "" && <SelectedFlow id={id} />}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "flowdetails");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={342} />}>
            {selectedContractRow.cliente !== "" && <FlowDetails id={id} />}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "completedstages");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={342} />}>
            {selectedFlowDetailRow.codigoFlujo !== "" && (
              <CompletedStages id={id} />
            )}
          </Suspense>
        </ErrorBoundary>
      </StrictMode>
    </>
  );
}

Component.displayName = "CreateFlow";
