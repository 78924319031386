import type {
  LoaderFunctionArgs,
} from "react-router-dom";
import type { QueryClient } from "@tanstack/react-query";
import {
  type ExtendedGetCatalogsGroupByColumnData,
  getCatalogsGroupByColumnQuery,
} from "~/loader-queries/catalog";
import secureLocalStorage from "react-secure-storage";
import type { GetCheckUserPermissionData } from "~/requests";
import { getCheckUserPermissionQuery } from "~/loader-queries/check-user-permission";

export const loader =
  (queryClient: QueryClient) =>
  ({ request }: LoaderFunctionArgs) => {
    const user = JSON.parse(secureLocalStorage.getItem("user") as string);
    const url = new URL(request.url);
    const search = url.searchParams.get("search") ?? "";

    let queryParameters1: ExtendedGetCatalogsGroupByColumnData = {
      column: "Type",
      xDbCatalog: user.dbCatalog,
    };

    const queryParameters2: GetCheckUserPermissionData = {
      xDbCatalog: user.dbCatalog,
      permissionId: String(50),
      userName: user.userName,
    };

    if (search && search.length > 0) {
      queryParameters1 = { ...queryParameters1, search };
    }

    
    // biome-ignore lint/complexity/noVoid: <explanation>
        void Promise.all([
        queryClient.ensureQueryData(getCatalogsGroupByColumnQuery(queryParameters1)),
        queryClient.ensureQueryData(getCheckUserPermissionQuery(queryParameters2)),
      ])
    
    return null;
  };