import { checkUnauthorized } from "~/utils/checkUnauthorized";
import { addNotification } from "~/utils/notify";
import { saveJwtToken } from "~/utils/saveJwT";
import type { ActionPostFetch, ItemPostFetch } from "~/types";
import type { QueryClient } from "@tanstack/react-query";
import { request as __request } from "~/requests/core/request";
import { OpenAPI } from "~/requests";
import type { ApiRequestOptions } from "~/requests/core/ApiRequestOptions";
import toast from "react-hot-toast";
import { sentenceCase } from "change-case";
import { i18next } from "~/config/i18n";

export function createOnSettled(queryClient: QueryClient, queryKey: unknown[]) {
  return function onSettled(response: unknown) {
    saveJwtToken(response);
    queryClient.invalidateQueries({
      queryKey,
    });
  };
}

export function createCancelQueries(
  queryClient: QueryClient,
  queryKey: unknown[],
) {
  return async function cancelQueries() {
    await queryClient.cancelQueries({ queryKey });
  };
}

export const createOnError = (
  queryClient: QueryClient,
  queryKey: unknown[],
  item: ItemPostFetch,
  context: "male" | "female",
) => {
  const handleError = (actionType: ActionPostFetch) => (error: Error) => {
    queryClient.invalidateQueries({ queryKey });
    if (!checkUnauthorized(error)) {
      addNotification({
        queryClient,
        messageType: "error",
        action: actionType,
        item,
        context,
      });
    }
  };

  return {
    onErrorDelete: handleError("delete"),
    onErrorCreate: handleError("add"),
    onErrorUpdate: handleError("edit"),
  };
};

type RequestParams<T> = {
  method: ApiRequestOptions<T>[`method`];
  url: string;
  headers?: Record<string, string>;
  errors?: Record<number, string>;
  path?: ApiRequestOptions<T>[`path`];
  query?: ApiRequestOptions<T>[`query`];
};

export const createQueryFn = <T,>(params: RequestParams<T>) => {
  return (): Promise<T> => {
    return __request<T>(OpenAPI, params);
  };
};

type CreateMutationHelpersParams = {
  queryClient: QueryClient;
  queryKey: unknown[];
  i18nKey: ItemPostFetch;
  i18nContext: "male" | "female";
};

export const createMutationHelpers = ({
  queryClient,
  queryKey,
  i18nKey,
  i18nContext,
}: CreateMutationHelpersParams) => {
  async function cancelQueries() {
    await queryClient.cancelQueries({ queryKey });
  }
  function onSettled(response: unknown) {
    saveJwtToken(response);
    queryClient.invalidateQueries({
      queryKey,
    });
  }
  const handleError = (actionType: ActionPostFetch) => (error: Error) => {
    queryClient.invalidateQueries({ queryKey });
    if (!checkUnauthorized(error)) {
      addNotification({
        queryClient,
        messageType: "error",
        action: actionType,
        item: i18nKey,
        context: i18nContext,
      });
    }
  };

  function updateQueryData<
    T,
    NT extends
      | {
          requestBody?: {
            [x: string]: unknown;
          };
        }
      | undefined,
  >(oldData: T, newItem: NT) {
    if (!newItem) {
      return oldData;
    }
    if (!newItem.requestBody) {
      throw new Error("newItem must have a requestBody property");
    }
    if (!Array.isArray(oldData)) {
      throw new Error("oldData must be an array");
    }
    const newItemWithSequential = {
      ...newItem.requestBody,
      sequential: oldData ? oldData.length + 1 : 1,
    };

    return oldData
      ? [...oldData, newItemWithSequential]
      : [newItemWithSequential];
  }

  async function onMutateCreate<
    T extends {
      requestBody?: {
        [x: string]: unknown;
      };
    },
  >(newItem: T) {
    await cancelQueries();
    const previousValue = queryClient.getQueryData<T[]>(queryKey);
    queryClient.setQueryData<T[]>(queryKey, (old) => {
      return updateQueryData(old, newItem);
    });

    return { previousValue };
  }

  async function onMutateUpdate<
    T extends {
      sequential?: number | null | undefined;
      requestBody?: {
        [x: string]: unknown;
      };
    },
  >(newItem: T) {
    await cancelQueries();
    const previousValue = queryClient.getQueryData<T[]>(queryKey);
    queryClient.setQueryData<T[]>(queryKey, (old) => {
      if (!newItem) {
        return old;
      }
      if (!newItem.requestBody) {
        throw new Error("newItem must have a requestBody property");
      }
      if (!Array.isArray(old)) {
        throw new Error("oldData must be an array");
      }

      return old?.map((item) =>
        Number(item.sequential) === Number(newItem.sequential)
          ? {
              ...item,
              ...newItem.requestBody,
            }
          : item,
      );
    });
    return { previousValue };
  }

  async function onMutateDelete<
    T extends {
      sequential?: number | null | undefined;
    },
  >(newItem: T) {
    await cancelQueries();
    const previousValue = queryClient.getQueryData<T[]>(queryKey);
    queryClient.setQueryData<T[]>(queryKey, (old) => {
      return old?.filter(
        (item) => Number(item.sequential) !== Number(newItem.sequential),
      );
    });
    return { previousValue };
  }

  return {
    cancelQueries,
    onSettled,
    onErrorDelete: handleError("delete"),
    onErrorCreate: handleError("add"),
    onErrorUpdate: handleError("edit"),
    updateQueryData,
    onMutateCreate,
    onMutateUpdate,
    onMutateDelete,
  };
};

export const createActionHelpers = ({
  queryClient,
  queryKey,
  i18nKey,
  i18nContext,
}: CreateMutationHelpersParams) => {
  async function cancelQueries() {
    await queryClient.cancelQueries({ queryKey });
  }

  const notify = async ({
    action,
    promise,
    previousValue,
  }: {
    action: ActionPostFetch;
    promise: Promise<unknown>;
    previousValue: unknown;
  }) => {
    const actionMap = {
      add: "addedAction",
      edit: "editedAction",
      delete: "deletedAction",
      activate: "activatedAction",
      inactivate: "inactivatedAction",
      mark: "markAction",
      notMark: "notMarkAction",
      assign: "assignAction",
      finalize: "finalizeAction",
      save: "saveAction",
      download: "downloadAction",
      call: "callAction",
      pause: "pauseAction",
      restart: "restartAction",
      consult: "consultAction",
      transfer: "transferAction",
      conference: "conferenceAction",
      onWait: "onWaitAction",
      resuming: "resumingAction",
      hangup: "hangupAction",
      return: "returnAction",
      change: "changeAction",
      select: "selectAction",
      upload: "uploadAction",
      finish: "finishAction",
      send: "sendAction",
    } as const;

    await toast.promise(promise, {
      loading: sentenceCase(
        i18next.t("loadingActionMessage", {
          context: i18nContext,
          how: i18next.t(actionMap[action], { count: 5 }),
          what: i18next.t(`${i18nKey}Item`),
        }),
      ),
      success: i18next.t("successActionMessage", {
        context: i18nContext,
        how: i18next.t(actionMap[action], { count: 1 }),
        what: i18next.t(`${i18nKey}Item`),
      }),
      error: () => {
        queryClient.setQueryData(queryKey, previousValue);
        return i18next.t("errorActionMessage", {
          context: i18nContext,
          how: i18next.t(actionMap[action], { count: 1 }),
          what: i18next.t(`${i18nKey}Item`),
        });
      },
    });
    await queryClient.invalidateQueries({ queryKey });
  };

  function updateQueryData<
    T,
    NT extends
      | {
          requestBody?: {
            [x: string]: unknown;
          };
        }
      | undefined,
  >(oldData: T, newItem: NT) {
    if (!newItem) {
      return oldData;
    }
    if (!newItem.requestBody) {
      throw new Error("newItem must have a requestBody property");
    }
    if (!Array.isArray(oldData)) {
      throw new Error("oldData must be an array");
    }
    const newItemWithSequential = {
      ...newItem.requestBody,
      sequential: oldData ? oldData.length + 1 : 1,
    };

    return oldData
      ? [...oldData, newItemWithSequential]
      : [newItemWithSequential];
  }

  async function onPost<
    T extends {
      requestBody: {
        [x: string]: unknown;
      };
    },
  >(newItem: T) {
    await cancelQueries();
    const previousValue = queryClient.getQueryData<T[]>(queryKey);
    queryClient.setQueryData<T[]>(queryKey, (old) => {
      return updateQueryData(old, newItem);
    });

    return { previousValue };
  }

  async function onPut<
    T extends {
      sequential: number | null | undefined;
      requestBody: {
        [x: string]: unknown;
      };
    },
  >(newItem: T) {
    await cancelQueries();
    const previousValue = queryClient.getQueryData<T[]>(queryKey);
    queryClient.setQueryData<T[]>(queryKey, (old) => {
      if (!newItem) {
        return old;
      }
      if (!newItem.requestBody) {
        throw new Error("newItem must have a requestBody property");
      }
      if (!Array.isArray(old)) {
        throw new Error("oldData must be an array");
      }

      return old?.map((item) =>
        Number(item.sequential) === Number(newItem.sequential)
          ? {
              ...item,
              ...newItem.requestBody,
            }
          : item,
      );
    });
    return { previousValue };
  }

  async function onDelete<
    T extends {
      sequential: number | null | undefined;
    },
  >(newItem: T) {
    await cancelQueries();
    const previousValue = queryClient.getQueryData<T[]>(queryKey);
    queryClient.setQueryData<T[]>(queryKey, (old) => {
      return old?.filter(
        (item) => Number(item.sequential) !== Number(newItem.sequential),
      );
    });
    return { previousValue };
  }

  return {
    onPost,
    onPut,
    onDelete,
    notify,
  };
};
