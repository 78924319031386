import secureLocalStorage from "react-secure-storage";
import type { QueryClient } from "@tanstack/react-query";
import {
  clientSearchQuery,
  type ClientSearchQueryParams,
} from "~/app/(dashboard)/_hooks/clientSearchQuery";
import { keys as rKeys } from "remeda";

export const clientSearchLoader =
  (queryClient: QueryClient) =>
  // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: Fix later
  async ({ request }: { request: Request }) => {
    const user = JSON.parse(secureLocalStorage.getItem("user") as string);

    const url = new URL(request.url);
    const filter = url.searchParams.get("filter") as
      | null
      | keyof ClientSearchQueryParams
      | "none";
    const parsedSearch = url.searchParams.get("search");
    const pageNumber = Number(url.searchParams.get("pageNumber") ?? 1);
    const rowsPerPage = Number(url.searchParams.get("rowsPerPage") ?? 10);
    const search =
      parsedSearch && parsedSearch.length > 0 ? parsedSearch : "null";

    const queryParameters = {
      contract: "null",
      client: "null",
      name: "null",
      currentBalance: "null",
      delinquentBalance: "null",
      daysLate: "null",
      productType: "null",
      clientType: "null",
      identification: "null",
      status1: "null",
      status2: "null",
      contactInfo: "null",
      workplace: "null",
      promise: "null",
      pageNumber,
      rowsPerPage,
      xDbCatalog: user.dbCatalog,
    } as ClientSearchQueryParams;

    const keys = rKeys(queryParameters);

    if (filter) {
      if (filter === "none") {
        for (const key of keys) {
          if (
            key !== "rowsPerPage" &&
            key !== "pageNumber" &&
            key !== "xDbCatalog"
          ) {
            queryParameters[key] = search;
          }
        }
      } else if (keys.includes(filter)) {
        if (
          filter !== "rowsPerPage" &&
          filter !== "pageNumber" &&
          filter !== "xDbCatalog"
        ) {
          queryParameters[filter] = search;
        }
      }
    }

    const query = clientSearchQuery(queryParameters);
    return (
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))
    );
  };
