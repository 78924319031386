import { addNotification } from "~/utils/notify";
import {
  isDarkModeState,
  modeState,
  resetPasswordLoadingResponseState,
  resetPasswordState,
} from "~/app/(dashboard)/configuration/_state/configuration";
import { enableSegmentationV2State } from "~/states/pages";
import { headersJsonState, userState } from "~/app/state/login";
import type { PasswordChangeRequest } from "~/types/access";
import { accessUrl } from "~/utils/endpoints";
import { postWithoutJsonResponse } from "~/utils/fetchApi";
import type { MouseEventHandler } from "react";
import type { ChangeEvent } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useRecoilCallback } from "recoil";
import { useQueryClient } from "@tanstack/react-query";
import { generateId } from "~/utils/generateId";

export const useConfiguration = () => {
  const [mode, setMode] = useRecoilState(modeState);
  const isDarkMode = useRecoilValue(isDarkModeState);
  const [resetPassword, setResetPassword] = useRecoilState(resetPasswordState);
  const headersJson = useRecoilValue(headersJsonState);
  const [loadingResponse, setLoadingResponse] = useRecoilState(
    resetPasswordLoadingResponseState,
  );
  const [enableSegmentationV2, setEnableSegmentatonV2] = useRecoilState(
    enableSegmentationV2State,
  );
  const queryClient = useQueryClient();

  const handleModeChange: MouseEventHandler<HTMLButtonElement> = (event) => {
    setMode(event.currentTarget.value as typeof mode);
  };

  const handleSegmentationV2Change = (event: ChangeEvent<HTMLInputElement>) => {
    setEnableSegmentatonV2(event.target.checked);
  };

  const handleOldPasswordTextField = (event: ChangeEvent<HTMLInputElement>) => {
    setResetPassword((prev) => ({
      ...prev,
      oldPassword: event.target.value,
    }));
  };

  const handleNewPasswordTextField = (event: ChangeEvent<HTMLInputElement>) => {
    setResetPassword((prev) => ({
      ...prev,
      newPassword: event.target.value,
    }));
  };

  const handleResetPasswordButton: MouseEventHandler<HTMLButtonElement> =
    useRecoilCallback(
      ({ snapshot, reset }) =>
        async (e) => {
          e.preventDefault();
          setLoadingResponse(true);
          const user = await snapshot.getPromise(userState);
          const resetPassword = await snapshot.getPromise(resetPasswordState);
          const headersObj = Object.fromEntries(headersJson.entries());
          postWithoutJsonResponse<PasswordChangeRequest>({
            url: `${accessUrl()}/change-password`,
            headers: new Headers({
              ...headersObj,
              "X-OPERATION-ID": generateId(),
            }),
            body: {
              username: user.userName,
              oldPassword: resetPassword.oldPassword,
              newPassword: resetPassword.newPassword,
            },
          }).then((response) => {
            if (response.ok) {
              setLoadingResponse(false);
              reset(resetPasswordState);
              addNotification({
                messageType: "success",
                action: "change",
                item: "password",
                context: "female",
                queryClient,
              });
            } else {
              setLoadingResponse(false);
              reset(resetPasswordState);
              addNotification({
                messageType: "error",
                action: "change",
                item: "password",
                context: "female",
                queryClient,
              });
            }
          });
        },
      [],
    );

  return {
    isDarkMode,
    mode,
    handleModeChange,
    resetPassword,
    handleOldPasswordTextField,
    handleNewPasswordTextField,
    handleResetPasswordButton,
    loadingResponse,
    enableSegmentationV2,
    handleSegmentationV2Change,
  };
};
