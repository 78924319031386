import { checkUnauthorized } from "~/utils/checkUnauthorized";
import { invalidateQuery } from "~/utils/invalidate";
import { addNotification } from "~/utils/notify";
import { saveJwtToken } from "~/utils/saveJwT";
import {
  useFieldsServiceGetFields,
  useFieldsServiceGetFieldsKey,
  useFieldsServicePutFields,
} from "~/queries";
import { userAtomState as userState } from "~/app/state/login";
import type { SelectChangeEvent } from "@mui/material/Select";
import { useQueryClient } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { useCallback } from "react";

export const useFields = () => {
  const user = useAtomValue(userState);
  const { data: fields } = useFieldsServiceGetFields({
    xDbCatalog: user.dbCatalog,
  });
  return { fields };
};

export const useFieldsUpdateService = () => {
  const queryClient = useQueryClient();
  return useFieldsServicePutFields({
    onSuccess: (response) => {
      saveJwtToken(response);
      invalidateQuery(queryClient, [useFieldsServiceGetFieldsKey]);
    },
    onError: (error) => {
      if (!checkUnauthorized(error)) {
        addNotification({
          queryClient,
          messageType: "error",
          action: "edit",
          item: "fieldsMaintenance",
          context: "male",
        });
      }
    },
  });
};

export const useEditField = () => {
  const { mutate, isPending } = useFieldsUpdateService();
  const user = useAtomValue(userState);
  const { fields } = useFields();

  const mutateEdit = useCallback(
    (e: SelectChangeEvent<string>) => {
      const fieldId = fields?.find(
        (field) => field.name === e.target.name,
      )?.sequential;
      if (fieldId === null || fieldId === undefined) {
        return;
      }

      mutate({
        sequential: fieldId,
        xDbCatalog: user.dbCatalog,
        requestBody: {
          name: e.target.name,
          value: e.target.value,
        },
      });
    },
    [mutate, user.dbCatalog, fields],
  );

  return { mutateEdit, isPending };
};
