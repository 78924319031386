import Box from "@mui/material/Box";
import { withProfiler } from "@sentry/react";
import { memo } from "react";

interface FlagIconProps {
  countryCode: string;
  size?: string;
}

const Component = memo(({ countryCode, size = "15px" }: FlagIconProps) => (
  <Box display="flex" alignItems="center" gap={0.5}>
    <Box
      component="span"
      className={`fi fi-${countryCode} fis`}
      sx={{
        "&.fis.fis": {
          borderRadius: "calc(4px - 0.125rem)",
          objectFit: "cover",
          height: size,
          width: size,
        },
      }}
    />
  </Box>
));

Component.displayName = "FlagIcon";
export const FlagIcon = withProfiler(Component);
