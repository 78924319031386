import { useFieldName } from "~/hooks/useFieldName";
import { showSidebarState } from "~/app/(dashboard)/_state/layout";
import {
  alertState,
  loadingResponseState,
  loggedInState,
  sessionState,
  showOtpState,
  showResetPasswordPromptState,
  showTypeResetPasswordCodePromptState,
  userState,
} from "~/app/state/login";
import {
  anchorLanguageMenuState,
  anchorNotificationCenterState,
  anchorUserMenuState,
  openFiltersMenuState,
  openLanguageMenuState,
  openNotificationCenterState,
  openUserMenuState,
  showUnreadOnlyState,
} from "~/app/(dashboard)/_state/topBar";
import type { SearchResultsB } from "~/types";
import type { LocationProps } from "~/types/props";
import type { SelectProps } from "@mui/material/Select";
import {
  type FormEventHandler,
  type MouseEvent,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { useBreakpoints } from "../../../hooks/useBreakpoints";
import { sentenceCase } from "change-case";
import {
  useNotificationsServiceDeleteNotification,
  useNotificationsServiceGetNotifications,
  UseNotificationsServiceGetNotificationsKeyFn,
  useNotificationsServicePutNotification,
} from "~/queries";
import { useQueryClient } from "@tanstack/react-query";
import { invalidateQuery } from "~/utils/invalidate";
import { saveJwtToken } from "~/utils/saveJwT";
import { checkUnauthorized } from "~/utils/checkUnauthorized";
import { captureException } from "@sentry/react";
import { addNotification } from "~/utils/notify";
import { z } from "zod";
import { isLogoutState } from "~/states/pages";

const SearchSchema = z.object({
  filter: z.string().default("none"),
  text: z.string().default(""),
});

export const useTopBar = () => {
  const showSidebar = useRecoilValue(showSidebarState);

  return { showSidebar };
};

export const useBreadcumbs = () => {
  const location = useLocation() satisfies LocationProps;
  const pathnames = location.pathname.split("/").filter((x) => x);
  const { matches } = useBreakpoints({
    breakpoint: "md",
  });

  return {
    pathnames,
    matches,
  };
};

export const useForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedFilter, setSelectedFilter] = useState("none");
  const searchFormRef = useRef<HTMLFormElement>(null);
  const { t } = useTranslation();
  const contractHeaderName =
    useFieldName(1, "contract") ?? t("clientFinder.filter2");
  const clientHeaderName =
    useFieldName(2, "client") ?? t("clientFinder.filter1");
  const navigate = useNavigate();

  const handleSelectChange =
    <T extends keyof SearchResultsB>(key: T): SelectProps<string>["onChange"] =>
    (e) => {
      searchParams.set(key, e.target.value);
      setSearchParams(searchParams);
    };

  const handleSearchClick: FormEventHandler = (e) => {
    e.preventDefault();
    if (searchFormRef.current) {
      const formData = Object.fromEntries(new FormData(searchFormRef.current));
      try {
        const searchResults = SearchSchema.parse(formData);
        navigate({
          pathname: "/client-finder",
          search: createSearchParams({
            filter: searchResults.filter,
            search: searchResults.text,
          }).toString(),
        });
      } catch (e) {
        captureException(e);
      }
    }
  };

  const [openFiltersMenu, setOpenFiltersMenu] =
    useRecoilState(openFiltersMenuState);
  const [anchorFiltersMenu, setAnchorFiltersMenu] =
    useState<null | HTMLElement>(null);

  const handleOpenFiltersMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorFiltersMenu(event.currentTarget);
    setOpenFiltersMenu((prev) => !prev);
  };

  const handleFiltersChange = (filter: string) => {
    setSelectedFilter(filter);
    setOpenFiltersMenu(false);
  };

  const filters = [
    { description: "client", label: sentenceCase(clientHeaderName) },
    { description: "contract", label: sentenceCase(contractHeaderName) },
    {
      description: "identification",
      label: t("clientFinder.filter3"),
    },
    { description: "name", label: t("clientFinder.filter4") },
    { description: "status1", label: `${t("clientFinder.filter6")} 1` },
    { description: "status2", label: `${t("clientFinder.filter6")} 2` },
    { description: "contactInfo", label: t("clientFinder.filter7") },
    { description: "workplace", label: t("clientFinder.filter8") },
    { description: "promise", label: t("clientFinder.filter9") },
    { description: "none", label: t("clientFinder.filter5") },
  ];

  return {
    searchFormRef,
    selectedFilter,
    handleSearchClick,
    filters,
    handleSelectChange,
    openFiltersMenu,
    anchorFiltersMenu,
    handleOpenFiltersMenu,
    handleFiltersChange,
  };
};

export const useLanguage = () => {
  const { i18n } = useTranslation();
  const [openLanguageMenu, setOpenLanguageMenu] = useRecoilState(
    openLanguageMenuState,
  );
  const [anchorLanguageMenu, setAnchorLanguageMenu] = useRecoilState(
    anchorLanguageMenuState,
  );

  const handleOpenLanguageMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorLanguageMenu(event.currentTarget);
    setOpenLanguageMenu((prev) => !prev);
  };

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language);
    setOpenLanguageMenu(false);
  };

  return {
    handleOpenLanguageMenu,
    openLanguageMenu,
    anchorLanguageMenu,
    handleLanguageChange,
  };
};

export const useMenu = () => {
  const [showSidebar, setShowSidebar] = useRecoilState(showSidebarState);

  const handleDrawerToggle = () => {
    setShowSidebar((prev) => !prev);
  };

  return {
    handleDrawerToggle,
    showSidebar,
  };
};

const useNotificationsUpdateService = () => {
  const queryClient = useQueryClient();
  const user = useRecoilValue(userState);
  return useNotificationsServicePutNotification({
    onSuccess: (response) => {
      saveJwtToken(response);
      invalidateQuery(
        queryClient,
        UseNotificationsServiceGetNotificationsKeyFn({
          user: user.userName,
          xDbCatalog: user.dbCatalog,
        }) as string[],
      );
    },
    onError: (error) => {
      if (!checkUnauthorized(error)) {
        addNotification({
          queryClient,
          messageType: "error",
          action: "edit",
          item: "notification",
          context: "female",
        });
      }
    },
  });
};

const useNotificationsDeleteService = () => {
  const queryClient = useQueryClient();
  const user = useRecoilValue(userState);
  return useNotificationsServiceDeleteNotification({
    onSuccess: (response) => {
      saveJwtToken(response);
      invalidateQuery(
        queryClient,
        UseNotificationsServiceGetNotificationsKeyFn({
          user: user.userName,
          xDbCatalog: user.dbCatalog,
        }) as string[],
      );
    },
    onError: (error) => {
      if (!checkUnauthorized(error)) {
        addNotification({
          queryClient,
          messageType: "error",
          action: "delete",
          item: "notification",
          context: "female",
        });
      }
    },
  });
};

export const useNotifications = () => {
  const user = useRecoilValue(userState);
  const { data } = useNotificationsServiceGetNotifications({
    user: user.userName,
    xDbCatalog: user.dbCatalog,
  });
  const [anchorNotificationCenter, setAnchorNotificationCenter] =
    useRecoilState(anchorNotificationCenterState);

  const [showUnreadOnly, setShowUnreadOnly] =
    useRecoilState(showUnreadOnlyState);

  const [openNotificationCenter, setOpenNotificationCenter] = useRecoilState(
    openNotificationCenterState,
  );
  const { mutate: mutateEdit } = useNotificationsUpdateService();
  const { mutate: mutateDelete } = useNotificationsDeleteService();

  const handleOpenNotificationCenter = (
    event: MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorNotificationCenter(event.currentTarget);
    setOpenNotificationCenter((prev) => !prev);
  };

  const toggleShowUnreadOnly = () => {
    setShowUnreadOnly((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpenNotificationCenter(false);
  };

  const notifications = showUnreadOnly ? data?.filter((n) => !n.isRead) : data;

  const markAsRead = (sequential: number) => {
    const notification = data?.find((n) => n.sequential === sequential);
    mutateEdit({
      sequential,
      xDbCatalog: user.dbCatalog,
      requestBody: {
        ...notification,
        isRead: true,
      },
    });
  };

  const markAllAsRead = () => {
    if (data) {
      for (const notification of data) {
        mutateEdit({
          // biome-ignore lint/style/noNonNullAssertion: ignore because we are sure that the value is not null
          sequential: notification.sequential!,
          xDbCatalog: user.dbCatalog,
          requestBody: {
            ...notification,
            isRead: true,
          },
        });
      }
    }
  };

  const clear = () => {
    if (data) {
      for (const notification of data) {
        mutateDelete({
          // biome-ignore lint/style/noNonNullAssertion: ignore because we are sure that the value is not null
          sequential: notification.sequential!,
          xDbCatalog: user.dbCatalog,
        });
      }
    }
  };

  const unreadCount = data?.filter((n) => !n.isRead).length ?? 0;

  const disabledMarkAllAsRead = unreadCount === 0;

  const disabledClear = data?.length === 0;

  return {
    handleOpenNotificationCenter,
    anchorNotificationCenter,
    openNotificationCenter,
    toggleShowUnreadOnly,
    showUnreadOnly,
    handleClickAway,
    notifications,
    markAsRead,
    markAllAsRead,
    clear,
    unreadCount,
    disabledMarkAllAsRead,
    disabledClear,
  };
};

export const useUser = () => {
  const navigate = useNavigate();
  const user = useRecoilValue(userState);
  const [openUserMenu, setOpenUserMenu] = useRecoilState(openUserMenuState);
  const [anchorUserMenu, setAnchorUserMenu] =
    useRecoilState(anchorUserMenuState);
  const resetSesssion = useResetRecoilState(sessionState);
  const resetShowAlert = useResetRecoilState(alertState);
  const setIsLogout = useSetRecoilState(isLogoutState);
  const setLoggedIn = useSetRecoilState(loggedInState);
  const resetShowOtp = useResetRecoilState(showOtpState);
  const resetShowResetPassword = useResetRecoilState(
    showResetPasswordPromptState,
  );
  const resetShowTypeResetPasswordCodePrompt = useResetRecoilState(
    showTypeResetPasswordCodePromptState,
  );
  const resetShowLoadingResponse = useResetRecoilState(loadingResponseState);

  const handleLogout = () => {
    resetSesssion();
    resetShowAlert();
    setOpenUserMenu(false);
    setIsLogout(true);
    resetShowOtp();
    resetShowResetPassword();
    resetShowTypeResetPasswordCodePrompt();
    resetShowLoadingResponse();
    setLoggedIn(false);
    navigate("/", { replace: true });
  };

  const handleOpenUserMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorUserMenu(event.currentTarget);
    setOpenUserMenu((prev) => !prev);
  };

  const handleConfigClick = () => {
    navigate("/configuration", { replace: true });
    setOpenUserMenu((prev) => !prev);
  };

  return {
    user,
    handleLogout,
    openUserMenu,
    handleOpenUserMenu,
    anchorUserMenu,
    handleConfigClick,
  };
};
