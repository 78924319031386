// generated with @7nohe/openapi-react-query-codegen@1.6.1 

import { type QueryClient } from "@tanstack/react-query";
import { AccountStatementService, AppPersonalizationsService, AppVariablesService, ArticlesService, CatalogsService, CheckUserPermissionService, ClientFlowSearchService, ClientListSearchService, ClientSearchService, ContactDetailsService, DemographicDataService, DownloadedDataService, DynamicNamesService, EmploymentDataService, FieldsService, FlowDocumentsService, FlowStageDocumentsService, FlowStagesService, FormatsService, GroupsService, GuaranteeOwnersService, GuaranteesService, HolidaysService, LiabilitiesService, ManagementService, MonthlyPaymentsService, NotificationsService, OperatingFlowsService, OrderItemsService, OtherPersonsService, PaymentAgreementsService, PersonalReferencesService, PersonsAddendumService, PromisesService, QuotesService, ReschedulesService, RolesPermissionsService, RolesService, StrategiesService, StrategyFormatsService, UrlsService, UsersService } from "../requests/services.gen";
import * as Common from "./common";
export const prefetchUseAccountStatementServiceGetAccountStatement = (queryClient: QueryClient, { cia, cliente, dataSource, ffinal, finicio, grupo, outFilename, reportEncoding, reportFormat, reportLocale, reportName, xDbCatalog }: {
  cia: string;
  cliente: string;
  dataSource: string;
  ffinal: string;
  finicio: string;
  grupo: string;
  outFilename?: string;
  reportEncoding: string;
  reportFormat: string;
  reportLocale: string;
  reportName: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseAccountStatementServiceGetAccountStatementKeyFn({ cia, cliente, dataSource, ffinal, finicio, grupo, outFilename, reportEncoding, reportFormat, reportLocale, reportName, xDbCatalog }), queryFn: () => AccountStatementService.getAccountStatement({ cia, cliente, dataSource, ffinal, finicio, grupo, outFilename, reportEncoding, reportFormat, reportLocale, reportName, xDbCatalog }) });
export const prefetchUseAppPersonalizationsServiceGetAppPersonalizations = (queryClient: QueryClient, { sequential, xDbCatalog }: {
  sequential: number;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseAppPersonalizationsServiceGetAppPersonalizationsKeyFn({ sequential, xDbCatalog }), queryFn: () => AppPersonalizationsService.getAppPersonalizations({ sequential, xDbCatalog }) });
export const prefetchUseAppVariablesServiceGetAppVariables = (queryClient: QueryClient, { id, xDbCatalog }: {
  id: number;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseAppVariablesServiceGetAppVariablesKeyFn({ id, xDbCatalog }), queryFn: () => AppVariablesService.getAppVariables({ id, xDbCatalog }) });
export const prefetchUseAppVariablesServiceGetAppVariablesByName = (queryClient: QueryClient, { name, xDbCatalog }: {
  name: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseAppVariablesServiceGetAppVariablesByNameKeyFn({ name, xDbCatalog }), queryFn: () => AppVariablesService.getAppVariablesByName({ name, xDbCatalog }) });
export const prefetchUseAppVariablesServiceGetAppVariablesByType = (queryClient: QueryClient, { type, xDbCatalog }: {
  type: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseAppVariablesServiceGetAppVariablesByTypeKeyFn({ type, xDbCatalog }), queryFn: () => AppVariablesService.getAppVariablesByType({ type, xDbCatalog }) });
export const prefetchUseAppVariablesServiceGetAppVariablesByTypeAndUserId = (queryClient: QueryClient, { type, userid, xDbCatalog }: {
  type: string;
  userid: number;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseAppVariablesServiceGetAppVariablesByTypeAndUserIdKeyFn({ type, userid, xDbCatalog }), queryFn: () => AppVariablesService.getAppVariablesByTypeAndUserId({ type, userid, xDbCatalog }) });
export const prefetchUseArticlesServiceGetArticles = (queryClient: QueryClient, { clientCode, xDbCatalog }: {
  clientCode: number;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseArticlesServiceGetArticlesKeyFn({ clientCode, xDbCatalog }), queryFn: () => ArticlesService.getArticles({ clientCode, xDbCatalog }) });
export const prefetchUseCatalogsServiceGetCatalogsByStatus = (queryClient: QueryClient, { status, xDbCatalog }: {
  status: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseCatalogsServiceGetCatalogsByStatusKeyFn({ status, xDbCatalog }), queryFn: () => CatalogsService.getCatalogsByStatus({ status, xDbCatalog }) });
export const prefetchUseCatalogsServiceGetCatalogsByDescription = (queryClient: QueryClient, { description, xDbCatalog }: {
  description: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseCatalogsServiceGetCatalogsByDescriptionKeyFn({ description, xDbCatalog }), queryFn: () => CatalogsService.getCatalogsByDescription({ description, xDbCatalog }) });
export const prefetchUseCatalogsServiceGetCatalogsGroupByColumn = (queryClient: QueryClient, { column, xDbCatalog }: {
  column: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseCatalogsServiceGetCatalogsGroupByColumnKeyFn({ column, xDbCatalog }), queryFn: () => CatalogsService.getCatalogsGroupByColumn({ column, xDbCatalog }) });
export const prefetchUseCatalogsServiceGetCatalogs = (queryClient: QueryClient, { type, xDbCatalog }: {
  type: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseCatalogsServiceGetCatalogsKeyFn({ type, xDbCatalog }), queryFn: () => CatalogsService.getCatalogs({ type, xDbCatalog }) });
export const prefetchUseCheckUserPermissionServiceGetCheckUserPermission = (queryClient: QueryClient, { permissionId, userName, xDbCatalog }: {
  permissionId?: string;
  userName?: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseCheckUserPermissionServiceGetCheckUserPermissionKeyFn({ permissionId, userName, xDbCatalog }), queryFn: () => CheckUserPermissionService.getCheckUserPermission({ permissionId, userName, xDbCatalog }) });
export const prefetchUseClientFlowSearchServiceGetClientFlowSearch = (queryClient: QueryClient, { client, contract, flowType, identification, name, xDbCatalog }: {
  client?: string;
  contract?: string;
  flowType?: string;
  identification?: string;
  name?: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseClientFlowSearchServiceGetClientFlowSearchKeyFn({ client, contract, flowType, identification, name, xDbCatalog }), queryFn: () => ClientFlowSearchService.getClientFlowSearch({ client, contract, flowType, identification, name, xDbCatalog }) });
export const prefetchUseClientListSearchServiceGetClientListSearch = (queryClient: QueryClient, { strategyCode, xDbCatalog }: {
  strategyCode?: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseClientListSearchServiceGetClientListSearchKeyFn({ strategyCode, xDbCatalog }), queryFn: () => ClientListSearchService.getClientListSearch({ strategyCode, xDbCatalog }) });
export const prefetchUseClientSearchServiceGetClientSearch = (queryClient: QueryClient, { client, clientType, contactInfo, contract, currentBalance, daysLate, delinquentBalance, identification, name, pageNumber, productType, promise, rowsPerPage, status1, status2, workplace, xDbCatalog }: {
  client?: string;
  clientType?: string;
  contactInfo?: string;
  contract?: string;
  currentBalance?: string;
  daysLate?: string;
  delinquentBalance?: string;
  identification?: string;
  name?: string;
  pageNumber?: number;
  productType?: string;
  promise?: string;
  rowsPerPage?: number;
  status1?: string;
  status2?: string;
  workplace?: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseClientSearchServiceGetClientSearchKeyFn({ client, clientType, contactInfo, contract, currentBalance, daysLate, delinquentBalance, identification, name, pageNumber, productType, promise, rowsPerPage, status1, status2, workplace, xDbCatalog }), queryFn: () => ClientSearchService.getClientSearch({ client, clientType, contactInfo, contract, currentBalance, daysLate, delinquentBalance, identification, name, pageNumber, productType, promise, rowsPerPage, status1, status2, workplace, xDbCatalog }) });
export const prefetchUseContactDetailsServiceGetContactDetails = (queryClient: QueryClient, { client, xDbCatalog }: {
  client: number;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseContactDetailsServiceGetContactDetailsKeyFn({ client, xDbCatalog }), queryFn: () => ContactDetailsService.getContactDetails({ client, xDbCatalog }) });
export const prefetchUseDemographicDataServiceGetDemographicData = (queryClient: QueryClient, { client, xDbCatalog }: {
  client: number;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseDemographicDataServiceGetDemographicDataKeyFn({ client, xDbCatalog }), queryFn: () => DemographicDataService.getDemographicData({ client, xDbCatalog }) });
export const prefetchUseDownloadedDataServiceGetDownloadedDataByPageNumber = (queryClient: QueryClient, { pageNumber, resultsPerPage, xDbCatalog }: {
  pageNumber?: number;
  resultsPerPage?: number;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseDownloadedDataServiceGetDownloadedDataByPageNumberKeyFn({ pageNumber, resultsPerPage, xDbCatalog }), queryFn: () => DownloadedDataService.getDownloadedDataByPageNumber({ pageNumber, resultsPerPage, xDbCatalog }) });
export const prefetchUseDownloadedDataServiceGetDownloadedDataByContract = (queryClient: QueryClient, { contract, xDbCatalog }: {
  contract: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseDownloadedDataServiceGetDownloadedDataByContractKeyFn({ contract, xDbCatalog }), queryFn: () => DownloadedDataService.getDownloadedDataByContract({ contract, xDbCatalog }) });
export const prefetchUseDownloadedDataServiceGetDownloadedDataByIdentification = (queryClient: QueryClient, { identification, xDbCatalog }: {
  identification: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseDownloadedDataServiceGetDownloadedDataByIdentificationKeyFn({ identification, xDbCatalog }), queryFn: () => DownloadedDataService.getDownloadedDataByIdentification({ identification, xDbCatalog }) });
export const prefetchUseDownloadedDataServiceGetDownloadedDataByName = (queryClient: QueryClient, { name, xDbCatalog }: {
  name: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseDownloadedDataServiceGetDownloadedDataByNameKeyFn({ name, xDbCatalog }), queryFn: () => DownloadedDataService.getDownloadedDataByName({ name, xDbCatalog }) });
export const prefetchUseDownloadedDataServiceGetDownloadedDataByOriginalBalance = (queryClient: QueryClient, { originalBalance, xDbCatalog }: {
  originalBalance: number;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseDownloadedDataServiceGetDownloadedDataByOriginalBalanceKeyFn({ originalBalance, xDbCatalog }), queryFn: () => DownloadedDataService.getDownloadedDataByOriginalBalance({ originalBalance, xDbCatalog }) });
export const prefetchUseDownloadedDataServiceGetDownloadedDataByCurrentBalance = (queryClient: QueryClient, { currentBalance, xDbCatalog }: {
  currentBalance: number;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseDownloadedDataServiceGetDownloadedDataByCurrentBalanceKeyFn({ currentBalance, xDbCatalog }), queryFn: () => DownloadedDataService.getDownloadedDataByCurrentBalance({ currentBalance, xDbCatalog }) });
export const prefetchUseDownloadedDataServiceGetDownloadedDataByPastDueBalance = (queryClient: QueryClient, { pastDueBalance, xDbCatalog }: {
  pastDueBalance: number;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseDownloadedDataServiceGetDownloadedDataByPastDueBalanceKeyFn({ pastDueBalance, xDbCatalog }), queryFn: () => DownloadedDataService.getDownloadedDataByPastDueBalance({ pastDueBalance, xDbCatalog }) });
export const prefetchUseDownloadedDataServiceGetDownloadedDataByPastDueDays = (queryClient: QueryClient, { pastDueDays, xDbCatalog }: {
  pastDueDays: number;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseDownloadedDataServiceGetDownloadedDataByPastDueDaysKeyFn({ pastDueDays, xDbCatalog }), queryFn: () => DownloadedDataService.getDownloadedDataByPastDueDays({ pastDueDays, xDbCatalog }) });
export const prefetchUseDownloadedDataServiceGetDownloadedDataByPastDueRange = (queryClient: QueryClient, { pastduerange, xDbCatalog }: {
  pastduerange: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseDownloadedDataServiceGetDownloadedDataByPastDueRangeKeyFn({ pastduerange, xDbCatalog }), queryFn: () => DownloadedDataService.getDownloadedDataByPastDueRange({ pastduerange, xDbCatalog }) });
export const prefetchUseDownloadedDataServiceGetDownloadedDataByLastPaymentDate = (queryClient: QueryClient, { lastPaymentDate, xDbCatalog }: {
  lastPaymentDate: Date;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseDownloadedDataServiceGetDownloadedDataByLastPaymentDateKeyFn({ lastPaymentDate, xDbCatalog }), queryFn: () => DownloadedDataService.getDownloadedDataByLastPaymentDate({ lastPaymentDate, xDbCatalog }) });
export const prefetchUseDownloadedDataServiceGetDownloadedDataByWorkplace = (queryClient: QueryClient, { workplace, xDbCatalog }: {
  workplace: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseDownloadedDataServiceGetDownloadedDataByWorkplaceKeyFn({ workplace, xDbCatalog }), queryFn: () => DownloadedDataService.getDownloadedDataByWorkplace({ workplace, xDbCatalog }) });
export const prefetchUseDownloadedDataServiceGetDownloadedDataByPhones = (queryClient: QueryClient, { phones, xDbCatalog }: {
  phones: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseDownloadedDataServiceGetDownloadedDataByPhonesKeyFn({ phones, xDbCatalog }), queryFn: () => DownloadedDataService.getDownloadedDataByPhones({ phones, xDbCatalog }) });
export const prefetchUseDynamicNamesServiceGetDynamicNames = (queryClient: QueryClient, { key, xDbCatalog }: {
  key: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseDynamicNamesServiceGetDynamicNamesKeyFn({ key, xDbCatalog }), queryFn: () => DynamicNamesService.getDynamicNames({ key, xDbCatalog }) });
export const prefetchUseEmploymentDataServiceGetEmploymentData = (queryClient: QueryClient, { client, xDbCatalog }: {
  client: number;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseEmploymentDataServiceGetEmploymentDataKeyFn({ client, xDbCatalog }), queryFn: () => EmploymentDataService.getEmploymentData({ client, xDbCatalog }) });
export const prefetchUseFieldsServiceGetFields = (queryClient: QueryClient, { xDbCatalog }: {
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseFieldsServiceGetFieldsKeyFn({ xDbCatalog }), queryFn: () => FieldsService.getFields({ xDbCatalog }) });
export const prefetchUseFlowDocumentsServiceGetFlowDocuments = (queryClient: QueryClient, { client, contract, flow, flowDate, xDbCatalog }: {
  client?: number;
  contract?: string;
  flow?: string;
  flowDate?: Date;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseFlowDocumentsServiceGetFlowDocumentsKeyFn({ client, contract, flow, flowDate, xDbCatalog }), queryFn: () => FlowDocumentsService.getFlowDocuments({ client, contract, flow, flowDate, xDbCatalog }) });
export const prefetchUseFlowStageDocumentsServiceGetFlowStageDocuments = (queryClient: QueryClient, { client, contract, flow, flowDate, stage, xDbCatalog }: {
  client?: number;
  contract?: string;
  flow?: string;
  flowDate?: Date;
  stage?: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseFlowStageDocumentsServiceGetFlowStageDocumentsKeyFn({ client, contract, flow, flowDate, stage, xDbCatalog }), queryFn: () => FlowStageDocumentsService.getFlowStageDocuments({ client, contract, flow, flowDate, stage, xDbCatalog }) });
export const prefetchUseFlowStageDocumentsServiceGetFlowStageDocumentsByFlow = (queryClient: QueryClient, { client, contract, flow, flowDate, xDbCatalog }: {
  client?: number;
  contract?: string;
  flow?: string;
  flowDate?: Date;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseFlowStageDocumentsServiceGetFlowStageDocumentsByFlowKeyFn({ client, contract, flow, flowDate, xDbCatalog }), queryFn: () => FlowStageDocumentsService.getFlowStageDocumentsByFlow({ client, contract, flow, flowDate, xDbCatalog }) });
export const prefetchUseFlowStagesServiceGetFlowStages = (queryClient: QueryClient, { flowCode, xDbCatalog }: {
  flowCode: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseFlowStagesServiceGetFlowStagesKeyFn({ flowCode, xDbCatalog }), queryFn: () => FlowStagesService.getFlowStages({ flowCode, xDbCatalog }) });
export const prefetchUseFormatsServiceGetFormats = (queryClient: QueryClient, { xDbCatalog }: {
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseFormatsServiceGetFormatsKeyFn({ xDbCatalog }), queryFn: () => FormatsService.getFormats({ xDbCatalog }) });
export const prefetchUseFormatsServiceGetFormatsByChannel = (queryClient: QueryClient, { channel, xDbCatalog }: {
  channel: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseFormatsServiceGetFormatsByChannelKeyFn({ channel, xDbCatalog }), queryFn: () => FormatsService.getFormatsByChannel({ channel, xDbCatalog }) });
export const prefetchUseGroupsServiceGetGroups = (queryClient: QueryClient, { xDbCatalog }: {
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseGroupsServiceGetGroupsKeyFn({ xDbCatalog }), queryFn: () => GroupsService.getGroups({ xDbCatalog }) });
export const prefetchUseGuaranteeOwnersServiceGetGuaranteeOwners = (queryClient: QueryClient, { guaranteeNumber, xDbCatalog }: {
  guaranteeNumber: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseGuaranteeOwnersServiceGetGuaranteeOwnersKeyFn({ guaranteeNumber, xDbCatalog }), queryFn: () => GuaranteeOwnersService.getGuaranteeOwners({ guaranteeNumber, xDbCatalog }) });
export const prefetchUseGuaranteesServiceGetGuarantees = (queryClient: QueryClient, { contract, xDbCatalog }: {
  contract: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseGuaranteesServiceGetGuaranteesKeyFn({ contract, xDbCatalog }), queryFn: () => GuaranteesService.getGuarantees({ contract, xDbCatalog }) });
export const prefetchUseHolidaysServiceGetHolidays = (queryClient: QueryClient, { xDbCatalog }: {
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseHolidaysServiceGetHolidaysKeyFn({ xDbCatalog }), queryFn: () => HolidaysService.getHolidays({ xDbCatalog }) });
export const prefetchUseLiabilitiesServiceGetLiabilities = (queryClient: QueryClient, { clientCode, xDbCatalog }: {
  clientCode: number;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseLiabilitiesServiceGetLiabilitiesKeyFn({ clientCode, xDbCatalog }), queryFn: () => LiabilitiesService.getLiabilities({ clientCode, xDbCatalog }) });
export const prefetchUseManagementServiceGetManagementDetails = (queryClient: QueryClient, { client, xDbCatalog }: {
  client: number;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseManagementServiceGetManagementDetailsKeyFn({ client, xDbCatalog }), queryFn: () => ManagementService.getManagementDetails({ client, xDbCatalog }) });
export const prefetchUseMonthlyPaymentsServiceGetMonthlyPayments = (queryClient: QueryClient, { clientCode, xDbCatalog }: {
  clientCode: number;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseMonthlyPaymentsServiceGetMonthlyPaymentsKeyFn({ clientCode, xDbCatalog }), queryFn: () => MonthlyPaymentsService.getMonthlyPayments({ clientCode, xDbCatalog }) });
export const prefetchUseNotificationsServiceGetNotifications = (queryClient: QueryClient, { user, xDbCatalog }: {
  user: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseNotificationsServiceGetNotificationsKeyFn({ user, xDbCatalog }), queryFn: () => NotificationsService.getNotifications({ user, xDbCatalog }) });
export const prefetchUseOperatingFlowsServiceGetOperatingFlows = (queryClient: QueryClient, { xDbCatalog }: {
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseOperatingFlowsServiceGetOperatingFlowsKeyFn({ xDbCatalog }), queryFn: () => OperatingFlowsService.getOperatingFlows({ xDbCatalog }) });
export const prefetchUseOperatingFlowsServiceGetOperatingFlowsByFlowCode = (queryClient: QueryClient, { flowCode, xDbCatalog }: {
  flowCode: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseOperatingFlowsServiceGetOperatingFlowsByFlowCodeKeyFn({ flowCode, xDbCatalog }), queryFn: () => OperatingFlowsService.getOperatingFlowsByFlowCode({ flowCode, xDbCatalog }) });
export const prefetchUseOrderItemsServiceGetAllOrderItems = (queryClient: QueryClient, { xDbCatalog }: {
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseOrderItemsServiceGetAllOrderItemsKeyFn({ xDbCatalog }), queryFn: () => OrderItemsService.getAllOrderItems({ xDbCatalog }) });
export const prefetchUseOrderItemsServiceGetOrderItemsByClient = (queryClient: QueryClient, { client, xDbCatalog }: {
  client: number;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseOrderItemsServiceGetOrderItemsByClientKeyFn({ client, xDbCatalog }), queryFn: () => OrderItemsService.getOrderItemsByClient({ client, xDbCatalog }) });
export const prefetchUseOtherPersonsServiceGetOtherPersons = (queryClient: QueryClient, { clientCode, xDbCatalog }: {
  clientCode: number;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseOtherPersonsServiceGetOtherPersonsKeyFn({ clientCode, xDbCatalog }), queryFn: () => OtherPersonsService.getOtherPersons({ clientCode, xDbCatalog }) });
export const prefetchUsePaymentAgreementsServiceGetPaymentAgreements = (queryClient: QueryClient, { client, xDbCatalog }: {
  client: number;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UsePaymentAgreementsServiceGetPaymentAgreementsKeyFn({ client, xDbCatalog }), queryFn: () => PaymentAgreementsService.getPaymentAgreements({ client, xDbCatalog }) });
export const prefetchUsePersonalReferencesServiceGetPersonalReferences = (queryClient: QueryClient, { client, xDbCatalog }: {
  client: number;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UsePersonalReferencesServiceGetPersonalReferencesKeyFn({ client, xDbCatalog }), queryFn: () => PersonalReferencesService.getPersonalReferences({ client, xDbCatalog }) });
export const prefetchUsePersonsAddendumServiceGetPersonsAddendum = (queryClient: QueryClient, { clientCode, xDbCatalog }: {
  clientCode: number;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UsePersonsAddendumServiceGetPersonsAddendumKeyFn({ clientCode, xDbCatalog }), queryFn: () => PersonsAddendumService.getPersonsAddendum({ clientCode, xDbCatalog }) });
export const prefetchUsePromisesServiceGetPromises = (queryClient: QueryClient, { client, xDbCatalog }: {
  client: number;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UsePromisesServiceGetPromisesKeyFn({ client, xDbCatalog }), queryFn: () => PromisesService.getPromises({ client, xDbCatalog }) });
export const prefetchUseQuotesServiceGetQuotes = (queryClient: QueryClient, { client, xDbCatalog }: {
  client: number;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseQuotesServiceGetQuotesKeyFn({ client, xDbCatalog }), queryFn: () => QuotesService.getQuotes({ client, xDbCatalog }) });
export const prefetchUseQuotesServiceGetQuotesByClientContract = (queryClient: QueryClient, { client, contract, xDbCatalog }: {
  client: number;
  contract: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseQuotesServiceGetQuotesByClientContractKeyFn({ client, contract, xDbCatalog }), queryFn: () => QuotesService.getQuotesByClientContract({ client, contract, xDbCatalog }) });
export const prefetchUseReschedulesServiceGetReschedules = (queryClient: QueryClient, { client, xDbCatalog }: {
  client: number;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseReschedulesServiceGetReschedulesKeyFn({ client, xDbCatalog }), queryFn: () => ReschedulesService.getReschedules({ client, xDbCatalog }) });
export const prefetchUseReschedulesServiceGetReschedulesByDateAndExecutive = (queryClient: QueryClient, { date, executive, xDbCatalog }: {
  date: string;
  executive: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseReschedulesServiceGetReschedulesByDateAndExecutiveKeyFn({ date, executive, xDbCatalog }), queryFn: () => ReschedulesService.getReschedulesByDateAndExecutive({ date, executive, xDbCatalog }) });
export const prefetchUseRolesServiceGetAllRoles = (queryClient: QueryClient, { xDbCatalog }: {
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseRolesServiceGetAllRolesKeyFn({ xDbCatalog }), queryFn: () => RolesService.getAllRoles({ xDbCatalog }) });
export const prefetchUseRolesPermissionsServiceGetPermissionsByRole = (queryClient: QueryClient, { roleId, xDbCatalog }: {
  roleId: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseRolesPermissionsServiceGetPermissionsByRoleKeyFn({ roleId, xDbCatalog }), queryFn: () => RolesPermissionsService.getPermissionsByRole({ roleId, xDbCatalog }) });
export const prefetchUseStrategiesServiceGetStrategies = (queryClient: QueryClient, { xDbCatalog }: {
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseStrategiesServiceGetStrategiesKeyFn({ xDbCatalog }), queryFn: () => StrategiesService.getStrategies({ xDbCatalog }) });
export const prefetchUseStrategyFormatsServiceGetStrategyFormats = (queryClient: QueryClient, { codeStrategy, xDbCatalog }: {
  codeStrategy: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseStrategyFormatsServiceGetStrategyFormatsKeyFn({ codeStrategy, xDbCatalog }), queryFn: () => StrategyFormatsService.getStrategyFormats({ codeStrategy, xDbCatalog }) });
export const prefetchUseUrlsServiceGetUrls = (queryClient: QueryClient, { xDbCatalog }: {
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseUrlsServiceGetUrlsKeyFn({ xDbCatalog }), queryFn: () => UrlsService.getUrls({ xDbCatalog }) });
export const prefetchUseUsersServiceGetAllUsers = (queryClient: QueryClient, { xDbCatalog }: {
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseUsersServiceGetAllUsersKeyFn({ xDbCatalog }), queryFn: () => UsersService.getAllUsers({ xDbCatalog }) });
export const prefetchUseUsersServiceGetUsersByRole = (queryClient: QueryClient, { userRole, xDbCatalog }: {
  userRole: string;
  xDbCatalog: string;
}) => queryClient.prefetchQuery({ queryKey: Common.UseUsersServiceGetUsersByRoleKeyFn({ userRole, xDbCatalog }), queryFn: () => UsersService.getUsersByRole({ userRole, xDbCatalog }) });
