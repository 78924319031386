import { FallbackComponent } from "~/components/FallbackComponent";
import { Unauthorized } from "~/components/Unauthorized";
import { useActiveAssignFlow, useActiveAssignFlows } from "~/hooks/usePages";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import Skeleton from "@mui/material/Skeleton";
import { ErrorBoundary } from "@sentry/react";
import { Suspense, StrictMode } from "react";

const id = "Regular";

const SearchOptions = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/active-assigned-flow/_components/SearchOptions"
      )
    ).SearchOptions,
  };
});

const SearchViews = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/active-assigned-flow/_components/SearchViews"
      )
    ).SearchViews,
  };
});

const ContractData = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/active-assigned-flow/_components/ContractData"
      )
    ).ContractData,
  };
});

const FlowOptions = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/active-assigned-flow/_components/FlowOptions"
      )
    ).FlowOptions,
  };
});

const FlowOptionsViews = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/active-assigned-flow/_components/FlowOptionsViews"
      )
    ).FlowOptionsViews,
  };
});

export const Component = () => {
  const {
    selectedFlowContractDetailsRow,
  } = useActiveAssignFlows({
    id,
  });
  const { hasViewPermission } = useActiveAssignFlow();

  if (!hasViewPermission) {
    return <Unauthorized />;
  }

  return (
    <>
      <StrictMode>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "searchOptions");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={100} />}>
            <SearchOptions id={id} />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "searchViews");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={100} />}>
            <SearchViews id={id} />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "contractdata");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={81} />}>
            {selectedFlowContractDetailsRow.contrato !== "" && (
              <ContractData id={id} />
            )}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "flowoptions");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={92} />}>
            {selectedFlowContractDetailsRow.contrato !== "" && (
              <FlowOptions id={id} />
            )}
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "swipeableviews");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={92} />}>
            <FlowOptionsViews id={id} />
          </Suspense>
        </ErrorBoundary>
      </StrictMode>
    </>
  );
};
