import secureLocalStorage from "react-secure-storage";

// utils.js
export function saveJwtToken(response: unknown) {
  if (response instanceof Response) {
    const headers = response.headers;
    const contentType = headers.get("Set-Authorization");
    if (contentType !== undefined && contentType !== null) {
      try {
        secureLocalStorage.setItem("jwtToken", JSON.stringify(contentType));
      } catch (_error) {
        secureLocalStorage.removeItem("jwtToken");
      }
    }
  }
}
