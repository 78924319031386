import { FallbackComponent } from "~/components/FallbackComponent";
import { Unauthorized } from "~/components/Unauthorized";
import { useClientFinder, useClientManagement } from "~/hooks/usePages";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import Modal from "@mui/material/Modal";
import Skeleton from "@mui/material/Skeleton";
import { ErrorBoundary } from "@sentry/react";
import { Suspense, StrictMode } from "react";
import { useSearchParams } from "react-router-dom";

const Search = reactLazyWithRetry(async () => {
  return {
    default: (
      await import("~/app/(dashboard)/client-finder/_components/Search")
    ).Search,
  };
});

const SearchResults = reactLazyWithRetry(async () => {
  return {
    default: (
      await import("~/app/(dashboard)/client-finder/_components/SearchResults")
    ).SearchResults,
  };
});

const PersonalInformation = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/client-finder/_components/PersonalInformation"
      )
    ).PersonalInformation,
  };
});

const PersonalInformationOptions = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/client-finder/_components/PersonalInformationOptions"
      )
    ).PersonalInformationOptions,
  };
});

const PersonalInformationViews = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/client-finder/_components/PersonalInformationViews"
      )
    ).PersonalInformationViews,
  };
});

const DebtDistribution = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/client-management/_components/DebtDistribution"
      )
    ).DebtDistribution,
  };
});

const OrderItems = reactLazyWithRetry(async () => {
  return {
    default: (
      await import("~/app/(dashboard)/client-management/_components/OrderItems")
    ).OrderItems,
  };
});

const AccountStatement = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/client-management/_components/AccountStatement"
      )
    ).AccountStatement,
  };
});

const Articles = reactLazyWithRetry(async () => {
  return {
    default: (
      await import("~/app/(dashboard)/client-management/_components/Articles")
    ).Articles,
  };
});

const MonthlyPayments = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/client-management/_components/MonthlyPayments"
      )
    ).MonthlyPayments,
  };
});

const Explanations = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/client-management/_components/Explanations"
      )
    ).Explanations,
  };
});

const Addendum = reactLazyWithRetry(async () => {
  return {
    default: (
      await import("~/app/(dashboard)/client-management/_components/Addendum")
    ).Addendum,
  };
});

const ContractData = reactLazyWithRetry(async () => {
  return {
    default: (
      await import("~/app/(dashboard)/client-finder/_components/ContractData")
    ).ContractData,
  };
});

const ContractOptions = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/client-finder/_components/ContractOptions"
      )
    ).ContractOptions,
  };
});

const ContractOptionsViews = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/client-finder/_components/ContractOptionViews"
      )
    ).ContractOptionsViews,
  };
});

export function Component() {
  const {
    hasViewPermission,
    hasViewPermissionClientPersonalInformation,
    hasViewPermissionClientInformationDetails,
    hasViewPermissionContractData,
    hasViewPermissionContractDetails,
  } = useClientFinder();
  const {
    showDebtDistributionDataGrid,
    showOrderItemsDataGrid,
    showCardAccountStatement,
    showArticlesCard,
    showMonthlyPaymentsCard,
    showExplanationsCard,
    showAddendumCard,
  } = useClientManagement();
  const [searchParams, _] = useSearchParams();

  const isConsult = searchParams.get("consultData") === "true";

  if (!hasViewPermission) {
    return <Unauthorized />;
  }
  return (
    <>
      <StrictMode>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "search");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={90} />}
          >
            <Search />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "searchresults");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={547} />}
          >
            <SearchResults />
          </Suspense>
        </ErrorBoundary>
        {isConsult ? (
          <>
            {hasViewPermissionClientPersonalInformation && (
              <ErrorBoundary
                beforeCapture={(scope) => {
                  scope.setTag("component", "personalinformation");
                }}
                fallback={FallbackComponent}
              >
                <Suspense fallback={<Skeleton width="100%" height={147} />}>
                  <PersonalInformation />
                </Suspense>
              </ErrorBoundary>
            )}
            {hasViewPermissionClientInformationDetails && (
              <>
                <ErrorBoundary
                  beforeCapture={(scope) => {
                    scope.setTag("component", "personalinformationoptions");
                  }}
                  fallback={FallbackComponent}
                >
                  <Suspense fallback={<Skeleton width="100%" height={100} />}>
                    <PersonalInformationOptions />
                  </Suspense>
                </ErrorBoundary>
                <ErrorBoundary
                  beforeCapture={(scope) => {
                    scope.setTag("component", "personalinformationviews");
                  }}
                  fallback={FallbackComponent}
                >
                  <Suspense fallback={<Skeleton width="100%" height={369} />}>
                    <PersonalInformationViews />
                  </Suspense>
                </ErrorBoundary>
              </>
            )}
            <ErrorBoundary
              beforeCapture={(scope) => {
                scope.setTag("component", "debtDistribution");
              }}
              fallback={FallbackComponent}
            >
              <Suspense
                fallback={
                  <Modal open={true} sx={{ mx: 2 }}>
                    <Skeleton
                      height={500}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: {
                          xs: "calc(100% - 32px)",
                          md: "100%",
                        },
                        maxWidth: 1020,
                      }}
                    />
                  </Modal>
                }
              >
                {showDebtDistributionDataGrid && <DebtDistribution />}
              </Suspense>
            </ErrorBoundary>
            <ErrorBoundary
              beforeCapture={(scope) => {
                scope.setTag("component", "orderItems");
              }}
              fallback={FallbackComponent}
            >
              <Suspense
                fallback={
                  <Modal open={true} sx={{ mx: 2 }}>
                    <Skeleton
                      height={500}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: {
                          xs: "calc(100% - 32px)",
                          md: "100%",
                        },
                        maxWidth: 870,
                      }}
                    />
                  </Modal>
                }
              >
                {showOrderItemsDataGrid && <OrderItems />}
              </Suspense>
            </ErrorBoundary>
            <ErrorBoundary
              beforeCapture={(scope) => {
                scope.setTag("component", "articles");
              }}
              fallback={FallbackComponent}
            >
              <Suspense
                fallback={
                  <Modal open={true} sx={{ mx: 2 }}>
                    <Skeleton
                      height={500}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: {
                          xs: "calc(100% - 32px)",
                          md: "100%",
                        },
                        maxWidth: 830,
                      }}
                    />
                  </Modal>
                }
              >
                {showArticlesCard && <Articles />}
              </Suspense>
            </ErrorBoundary>
            <ErrorBoundary
              beforeCapture={(scope) => {
                scope.setTag("component", "monthlyPayments");
              }}
              fallback={FallbackComponent}
            >
              <Suspense
                fallback={
                  <Modal open={true} sx={{ mx: 2 }}>
                    <Skeleton
                      height={500}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: {
                          xs: "calc(100% - 32px)",
                          md: "100%",
                        },
                        maxWidth: 830,
                      }}
                    />
                  </Modal>
                }
              >
                {showMonthlyPaymentsCard && <MonthlyPayments />}
              </Suspense>
            </ErrorBoundary>
            <ErrorBoundary
              beforeCapture={(scope) => {
                scope.setTag("component", "explanations");
              }}
              fallback={FallbackComponent}
            >
              <Suspense
                fallback={
                  <Modal open={true} sx={{ mx: 2 }}>
                    <Skeleton
                      height={500}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: {
                          xs: "calc(100% - 32px)",
                          md: "100%",
                        },
                        maxWidth: 830,
                      }}
                    />
                  </Modal>
                }
              >
                {showExplanationsCard && <Explanations />}
              </Suspense>
            </ErrorBoundary>
            <ErrorBoundary
              beforeCapture={(scope) => {
                scope.setTag("component", "addendum");
              }}
              fallback={FallbackComponent}
            >
              <Suspense
                fallback={
                  <Modal open={true} sx={{ mx: 2 }}>
                    <Skeleton
                      height={500}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: {
                          xs: "calc(100% - 32px)",
                          md: "100%",
                        },
                        maxWidth: 830,
                      }}
                    />
                  </Modal>
                }
              >
                {showAddendumCard && <Addendum />}
              </Suspense>
            </ErrorBoundary>
            <ErrorBoundary
              beforeCapture={(scope) => {
                scope.setTag("component", "accountStatement");
              }}
              fallback={FallbackComponent}
            >
              <Suspense
                fallback={
                  <Modal open={true} sx={{ mx: 2 }}>
                    <Skeleton
                      height={500}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: {
                          xs: "calc(100% - 32px)",
                          md: "100%",
                        },
                        maxWidth: 830,
                      }}
                    />
                  </Modal>
                }
              >
                {showCardAccountStatement && <AccountStatement />}
              </Suspense>
            </ErrorBoundary>
            {hasViewPermissionContractData && (
              <ErrorBoundary
                beforeCapture={(scope) => {
                  scope.setTag("component", "contractdata");
                }}
                fallback={FallbackComponent}
              >
                <Suspense fallback={<Skeleton width="100%" height={120} />}>
                  <ContractData />
                </Suspense>
              </ErrorBoundary>
            )}
            {hasViewPermissionContractDetails && (
              <>
                <ErrorBoundary
                  beforeCapture={(scope) => {
                    scope.setTag("component", "contractoptions");
                  }}
                  fallback={FallbackComponent}
                >
                  <Suspense fallback={<Skeleton width="100%" height={100} />}>
                    <ContractOptions />
                  </Suspense>
                </ErrorBoundary>
                <ErrorBoundary
                  beforeCapture={(scope) => {
                    scope.setTag("component", "contractoptionsviews");
                  }}
                  fallback={FallbackComponent}
                >
                  <Suspense fallback={<Skeleton width="100%" height={769} />}>
                    <ContractOptionsViews />
                  </Suspense>
                </ErrorBoundary>
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </StrictMode>
    </>
  );
}

Component.displayName = "ClientFinder";
