import type { PasswordChangeRequest } from "~/types/access";
import { localStorageEffect } from "~/utils/localStorage";
import { atom, selector } from "recoil";

export const modeState = atom<"light" | "dark" | "system">({
  key: "modeStateConfiguration",
  default: "light",
  effects: [localStorageEffect("modeStateConfiguration")],
});

export const isDarkModeState = selector<boolean>({
  key: "isDarkModeStateConfiguration",
  get: ({ get }) => {
    const mode = get(modeState);
    const prefersDarkMode = window.matchMedia(
      "(prefers-color-scheme: dark)",
    ).matches;
    return mode === "dark" || (mode === "system" && prefersDarkMode);
  },
});

export const resetPasswordState = atom<PasswordChangeRequest>({
  key: "resetPasswordState",
  default: {
    username: "",
    oldPassword: "",
    newPassword: "",
  },
});

export const resetPasswordLoadingResponseState = atom<boolean>({
  key: "resetPasswordLoadingResponseState",
  default: false,
});
