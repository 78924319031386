import type { QueryClient } from "@tanstack/react-query";
import secureLocalStorage from "react-secure-storage";
import type { LoaderFunctionArgs } from "react-router-dom";
import { prefetchAppPersonalization } from "../_hooks/prefetchAppPersonalization";
import { generateId } from "~/utils/generateId";
import { get } from "~/utils/fetchApi";
import type { ClientPortfolioR } from "~/types";
import { getClientPortfolioUrl } from "~/utils/endpoints";

// TODO: Remove safeToken
export const homeloader =
  (queryClient: QueryClient) => async (_: LoaderFunctionArgs) => {
    const parsedJWTToken = JSON.parse(
      secureLocalStorage.getItem("jwtToken") as string,
    );
    const parsedUser = JSON.parse(secureLocalStorage.getItem("user") as string);
    let jwtToken = "";
    let user = {
      userName: "",
      password: "",
      company: "",
      dbCatalog: "",
    };
    if (parsedJWTToken && parsedUser) {
      jwtToken = parsedJWTToken;
      user = parsedUser;
    }

    prefetchAppPersonalization(queryClient, {
      sequential: 1,
      xDbCatalog: user.dbCatalog,
      safeToken: jwtToken,
    });

    prefetchAppPersonalization(queryClient, {
      sequential: 2,
      xDbCatalog: user.dbCatalog,
      safeToken: jwtToken,
    });
    // TODO: FIX content type in api
    // const configuration = new Configuration({
    //   accessToken: safeToken,
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // });
    // const api = new ReportesApi(configuration);
    // const catalog = await api.getClientPortfolio();
    // return catalog || [];
    const headersJson = new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwtToken}`,
      "X-DB-Catalog": user.dbCatalog,
      "X-OPERATION-ID": generateId(),
    });
    const body = await get<ClientPortfolioR[]>({
      url: getClientPortfolioUrl(),
      headers: headersJson,
    });
    return body ?? [];
  };
