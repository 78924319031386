import secureLocalStorage from "react-secure-storage";
import type { AssignExecutiveR, AssignExecutiveB } from "~/types";
import { post } from "~/utils/fetchApi";
import { generateId } from "~/utils/generateId";
import { assignExecutiveUrl } from "~/utils/endpoints";

export const clientManagementLoader =
  () =>
  async ({ request }: { request: Request }) => {
    const user = JSON.parse(secureLocalStorage.getItem("user") as string);

    const url = new URL(request.url);
    const client = url.searchParams.get("clientId");
    const location = url.searchParams.get("location");
    const jwtToken = JSON.parse(
      secureLocalStorage.getItem("jwtToken") as string,
    );

    //TODO: Fix this when opening page
    if (location === "quanticvision" || location === "ucontact") {
      await post<AssignExecutiveR[], AssignExecutiveB>({
        url: assignExecutiveUrl(),
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
          "X-DB-Catalog": user.dbCatalog,
          "X-OPERATION-ID": generateId(),
        }),
        body: {
          contratoSeleccionado: "",
          username: user.userName,
          numeroSeleccionado: String(client),
        },
      });
    }
  };
