import { FallbackComponent } from "~/components/FallbackComponent";
import { Unauthorized } from "~/components/Unauthorized";
import { usePauseMaintenance } from "~/hooks/usePages";
import type { CatalogsWithAction } from "~/types/index";
import { StatusType } from "~/types/requests";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import { zodResolver } from "@hookform/resolvers/zod";
import Skeleton from "@mui/material/Skeleton";
import { ErrorBoundary } from "@sentry/react";
import { Suspense } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

const Pauses = reactLazyWithRetry(async () => {
  return {
    default: (await import("./components/Pauses")).Pauses,
  };
});

const PauseManipulation = reactLazyWithRetry(async () => {
  return {
    default: (await import("./components/PauseManipulation")).PauseManipulation,
  };
});

const schema = z.object({
  sequential: z.number().int().nonnegative(),
  type: z.string().max(50),
  relation: z.number().nonnegative(),
  description: z.string().min(1).max(255),
  status: z.string().min(1).max(50),
  action: z.enum(["add", "edit", "delete"]),
});

export const Component = () => {
  const { hasViewPermission } = usePauseMaintenance();

  const methods = useForm<CatalogsWithAction>({
    defaultValues: {
      sequential: 0,
      type: "",
      relation: 0,
      description: "",
      status: StatusType.INACTIVO,
      action: "edit",
    },
    resolver: zodResolver(schema),
  });

  if (!hasViewPermission) {
    return <Unauthorized />;
  }
  return (
    <FormProvider {...methods}>
      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("component", "pauses");
        }}
        fallback={FallbackComponent}
      >
        <Suspense
          fallback={<Skeleton variant="rounded" width="100%" height={341} />}
        >
          <Pauses />
        </Suspense>
      </ErrorBoundary>
      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("component", "pauseManipulation");
        }}
        fallback={FallbackComponent}
      >
        <Suspense
          fallback={<Skeleton variant="rounded" width="100%" height={125} />}
        >
          <PauseManipulation />
        </Suspense>
      </ErrorBoundary>
    </FormProvider>
  );
};

Component.displayName = "PauseMaintenance";
