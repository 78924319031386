import { useAppPersonalizations } from "~/app/_hooks/useAppPersonalizations";
import { useTranslation } from "react-i18next";

export function useLocaleString() {
  const { data: currencyFormat } = useAppPersonalizations(1);
  const { data: currencyLocale } = useAppPersonalizations(2);
  const { i18n } = useTranslation();

  const formatLocaleString = (num: number | null) => {
    if (num === null) {
      return "";
    }

    return num.toLocaleString(
      `${i18n.language.split("-")[0]}-${currencyLocale?.[0].value ?? "PA"}`,
      {
        style: "currency",
        currency: currencyFormat?.[0].value ?? "PAB",
      },
    );
  };

  return {
    formatLocaleString,
  };
}

export function useLocaleNumberString() {
  const { i18n } = useTranslation();
  const { data: currencyLocale } = useAppPersonalizations(2);

  const formatLocaleNumberString = (num: number | null) => {
    if (num === null) {
      return "";
    }

    return num.toLocaleString(
      `${i18n.language.split("-")[0]}-${
        // biome-ignore lint/style/noNonNullAssertion: We know that currencyLocale is not null
        currencyLocale?.[0].value!
      }`,
    );
  };

  return {
    formatLocaleNumberString,
  };
}
