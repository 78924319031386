import { UseAppPersonalizationsServiceGetAppPersonalizationsKeyFn } from "~/queries";
import type { QueryClient } from "@tanstack/react-query";
import { request as __request } from "~/requests/core/request";
import { apiVersion } from "~/config/api";
import { type OpenAPIConfig, Interceptors } from "~/requests/core/OpenAPI";

export const prefetchAppPersonalization = (
  queryClient: QueryClient,
  {
    sequential,
    xDbCatalog,
    safeToken,
  }: {
    sequential: number;
    xDbCatalog: string;
    safeToken: string;
  },
) => {
  const OpenAPI: OpenAPIConfig = {
    BASE: import.meta.env.API_URL ?? "",
    CREDENTIALS: "include",
    ENCODE_PATH: undefined,
    HEADERS: undefined,
    PASSWORD: undefined,
    TOKEN: safeToken,
    USERNAME: undefined,
    VERSION: "1.0",
    WITH_CREDENTIALS: false,
    interceptors: {
      request: new Interceptors(),
      response: new Interceptors(),
    },
  };
  return queryClient.prefetchQuery({
    queryKey: UseAppPersonalizationsServiceGetAppPersonalizationsKeyFn({
      sequential,
      xDbCatalog,
    }),
    queryFn: () =>
      __request(OpenAPI, {
        method: "GET",
        url: `/api/v${apiVersion}/AppPersonalizations/{sequential}`,
        path: {
          sequential: sequential,
        },
        headers: {
          "X-DB-Catalog": xDbCatalog,
        },
        errors: {
          400: "No results found.",
          404: "An error occurred while executing GetAppPersonalizations",
        },
      }),
  });
};
