import { apiVersion } from "~/config/api";
import { OpenAPI } from "~/requests/core/OpenAPI";
// import { ClientSearchService } from "~/requests/services.gen";
import { request as __request } from "~/requests/core/request";
import { useClientSearchServiceGetClientSearchKey } from "~/queries/common";
export interface ClientSearchQueryParams {
  client?: string;
  clientType?: string;
  contract?: string;
  currentBalance?: string;
  daysLate?: string;
  delinquentBalance?: string;
  identification?: string;
  name?: string;
  pageNumber?: number;
  contactInfo?: string;
  workplace?: string;
  promise?: string;
  productType?: string;
  rowsPerPage?: number;
  status1?: string;
  status2?: string;
  xDbCatalog: string;
}

export const clientSearchQuery = ({
  client,
  clientType,
  contract,
  currentBalance,
  daysLate,
  delinquentBalance,
  identification,
  name,
  pageNumber,
  contactInfo,
  workplace,
  promise,
  productType,
  rowsPerPage,
  status1,
  status2,
  xDbCatalog,
}: ClientSearchQueryParams) => ({
  queryKey: [
    useClientSearchServiceGetClientSearchKey,
    client,
    clientType,
    contract,
    currentBalance,
    daysLate,
    delinquentBalance,
    identification,
    name,
    pageNumber,
    contactInfo,
    workplace,
    promise,
    productType,
    rowsPerPage,
    status1,
    status2,
    xDbCatalog,
  ],
  queryFn: async () =>
    __request(OpenAPI, {
      method: "GET",
      url: `/api/v${apiVersion}/ClientSearch`,
      headers: {
        "X-DB-Catalog": xDbCatalog,
      },
      query: {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        contract: contract,
        client: client,
        name: name,
        currentBalance: currentBalance,
        delinquentBalance: delinquentBalance,
        daysLate: daysLate,
        productType: productType,
        clientType: clientType,
        identification: identification,
        status1: status1,
        status2: status2,
        contactInfo: contactInfo,
        workplace: workplace,
        promise: promise,
      },
      errors: {
        400: "No results found.",
        404: "An error occurred while executing PostClientSearch",
      },
    }),
});
