import type { QueryClient } from "@tanstack/react-query";
import type { LoaderFunctionArgs } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { getCatalogsByTypeQuery } from "~/loader-queries/catalog";
import type { GetCatalogsData } from "~/requests";

export const loader =
  (queryClient: QueryClient) =>
  ({ request }: LoaderFunctionArgs) => {
    const searchParams = new URL(request.url).searchParams;
    const type = searchParams.get("type") ?? "";
    const user = JSON.parse(secureLocalStorage.getItem("user") as string);
    const queryParameters: GetCatalogsData = {
      type,
      xDbCatalog: user.dbCatalog,
    };
    // biome-ignore lint/complexity/noVoid: <explanation>
    void queryClient.ensureQueryData(getCatalogsByTypeQuery(queryParameters));
    return null;
  };
