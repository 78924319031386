import {
  CatalogsService,
  type GetCatalogsResponse,
} from "~/requests";
import type {
  ActionFunctionArgs,
} from "react-router-dom";
import type { QueryClient } from "@tanstack/react-query";
import { createActionHelpers } from "~/utils/query";
import { UseCatalogsServiceGetCatalogsKeyFn } from "~/queries";
import secureLocalStorage from "react-secure-storage";
import { nanoid } from "nanoid";
import { apiResponseHandler } from "~/utils/requestWrapper";

export const action =
  (queryClient: QueryClient) =>
  async ({ request }: ActionFunctionArgs) => {
    const formData = (await request.json()) as GetCatalogsResponse[0];
    const method = request.method;
    const user = JSON.parse(secureLocalStorage.getItem("user") as string);
    const queryKey = UseCatalogsServiceGetCatalogsKeyFn({
      xDbCatalog: user.dbCatalog,
      type: formData.type ?? "",
    });
    const { onPost, onPut, onDelete, notify } = createActionHelpers({
      queryClient,
      queryKey,
      i18nKey: "catalogsMaintenance",
      i18nContext: "male",
    });

    if (method === "POST") {
      const { previousValue } = await onPost({
        requestBody: formData,
        xOperationId: nanoid(),
      });
      const addPromise = apiResponseHandler(
        CatalogsService.postCatalogs({
          requestBody: formData,
          xOperationId: nanoid(),
          xDbCatalog: user.dbCatalog,
        }),
      );
      await notify({
        action: "add",
        promise: addPromise,
        previousValue,
      });
    }

    if (method === "PUT") {
      const { previousValue } = await onPut({
        sequential: formData.sequential ?? 0,
        requestBody: formData,
      });
      const updatePromise = apiResponseHandler(
        CatalogsService.putCatalogs({
          sequential: formData.sequential ?? 0,
          requestBody: formData,
          xDbCatalog: user.dbCatalog,
        }),
      );
      await notify({
        action: "edit",
        promise: updatePromise,
        previousValue,
      });
    }

    if (method === "DELETE") {
      const { previousValue } = await onDelete({
        sequential: formData.sequential ?? 0,
      });
      const deletePromise = apiResponseHandler(
        CatalogsService.deleteCatalogs({
          sequential: formData.sequential ?? 0,
          xDbCatalog: user.dbCatalog,
        }),
      );
      await notify({
        action: "delete",
        promise: deletePromise,
        previousValue,
      });
    }

    return null;
  };