import type { UsersR } from "~/types";
import type { PasswordResetRequestWithToken } from "~/types/access";
import { generateId } from "~/utils/generateId";
import { atomWithLocalStorage } from "~/utils/jotai";
import { localStorageEffect } from "~/utils/localStorage";
import { setUser } from "@sentry/react";
import secureLocalStorage from "react-secure-storage";
import { atom, selector } from "recoil";

const userInputState = atom<Pick<UsersR, "password" | "userName" | "company">>({
  key: "userInput",
  default: {
    userName: "",
    password: "",
    company: "",
  },
});

export const jwtTokenState = atom<string>({
  key: "jwtToken",
  default: "",
  effects: [localStorageEffect("jwtToken")],
});

export const headersJsonState = selector<Headers>({
  key: "headersJson",
  get: ({ get }) => {
    const jwtToken = get(jwtTokenState);
    const user = get(userState);
    return new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwtToken}`,
      "X-DB-Catalog": user.dbCatalog,
      "X-OPERATION-ID": generateId(),
    });
  },
});

export const rememberPasswordState = atom<boolean>({
  key: "rememberPassword",
  default: false,
  effects: [localStorageEffect("rememberPassword")],
});

export const userAtomState = atomWithLocalStorage<
  Pick<UsersR, "password" | "userName" | "company"> & { dbCatalog: string }
>("userAtom", {
  userName: "",
  password: "",
  company: "",
  dbCatalog: "",
});

export const userLoginState = selector<
  Pick<UsersR, "password" | "userName" | "company"> & { dbCatalog: string }
>({
  key: "userLogin",
  get: ({ get }) => {
    const rememberPassword = get(rememberPasswordState);
    const userInput = get(userInputState);
    if (rememberPassword) {
      const savedValue = secureLocalStorage.getItem("userLogin");
      const storedUser = savedValue && JSON.parse(savedValue as string);
      if (storedUser?.company) {
        return storedUser;
      }
    }
    return userInput;
  },
  set: ({ set, get }, newValue) => {
    const rememberPassword = get(rememberPasswordState);
    set(userState, newValue);
    if ("userName" in newValue) {
      setUser({ username: newValue.userName });
    }
    set(userInputState, newValue);
    if (rememberPassword) {
      try {
        secureLocalStorage.setItem("userLogin", JSON.stringify(newValue));
      } catch (_error) {
        secureLocalStorage.removeItem("userLogin");
      }
    }
  },
});

export const userResetState = atom<PasswordResetRequestWithToken>({
  key: "userResetAtom",
  default: {
    username: "",
    newPassword: "",
    token: "",
  },
});

export const userState = atom<
  Pick<UsersR, "password" | "userName" | "company"> & { dbCatalog: string }
>({
  key: "user",
  default: {
    userName: "",
    password: "",
    company: "",
    dbCatalog: "",
  },
  effects: [localStorageEffect("user")],
});

export const sessionState = atom<string[]>({
  key: "session",
  default: [],
  effects: [localStorageEffect("session")],
});

export const loggedInState = atom({
  key: "loggedIn",
  default: false,
  effects: [localStorageEffect("loggedIn")],
});

export const otpState = atom({
  key: "otp",
  default: "",
});

const defaultAlerts = {
  emailAlert: {
    value: false,
    severity: "warning" as const,
    messageKey: "emailAlert" as const,
  },
  emailResetAlert: {
    value: false,
    severity: "success" as const,
    messageKey: "emailResetAlert" as const,
  },
  invalidCodeAlert: {
    value: false,
    severity: "error" as const,
    messageKey: "invalidCodeAlert" as const,
  },
  invalidOrExpiredCodeAlert: {
    value: false,
    severity: "error" as const,
    messageKey: "invalidOrExpiredCodeAlert" as const,
  },
  errorAlert: {
    value: false,
    severity: "error" as const,
    messageKey: "errorAlert" as const,
  },
  invalidUserAlert: {
    value: false,
    severity: "error" as const,
    messageKey: "userExists" as const,
  },
  expiredSessionAlert: {
    value: false,
    severity: "warning" as const,
    messageKey: "expiredSessionAlert" as const,
  },
  invalidUsernameAlert: {
    value: false,
    severity: "error" as const,
    messageKey: "invalidUsernameAlert" as const,
  },
};

export const alertState = atom({
  key: "alertLogin",
  default: {
    emailAlert: {
      value: false,
      severity: "warning" as const,
      messageKey: "emailAlert" as const,
    },
    emailResetAlert: {
      value: false,
      severity: "success" as const,
      messageKey: "emailResetAlert" as const,
    },
    invalidCodeAlert: {
      value: false,
      severity: "error" as const,
      messageKey: "invalidCodeAlert" as const,
    },
    invalidOrExpiredCodeAlert: {
      value: false,
      severity: "error" as const,
      messageKey: "invalidOrExpiredCodeAlert" as const,
    },
    errorAlert: {
      value: false,
      severity: "error" as const,
      messageKey: "errorAlert" as const,
    },
    invalidUserAlert: {
      value: false,
      severity: "error" as const,
      messageKey: "invalidUserAlert" as const,
    },
    expiredSessionAlert: {
      value: false,
      severity: "warning" as const,
      messageKey: "expiredSessionAlert" as const,
    },
    invalidUsernameAlert: {
      value: false,
      severity: "error" as const,
      messageKey: "invalidUsernameAlert" as const,
    },
    currentAlert: null as null | keyof typeof defaultAlerts,
  },
});

export const showOtpState = atom({
  key: "showOtpState",
  default: false,
});

export const showResetPasswordPromptState = atom({
  key: "showResetPasswordPromptState",
  default: false,
});

export const showTypeResetPasswordCodePromptState = atom({
  key: "showTypeResetPasswordCodePromptState",
  default: false,
});

export const loadingResponseState = atom({
  key: "loadingResponseState",
  default: false,
});

export const userDataState = atom<Pick<UsersR, "userName">>({
  key: "userDataState",
  default: {
    userName: "",
  },
});
