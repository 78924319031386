import { FallbackComponent } from "~/components/FallbackComponent";
import { Unauthorized } from "~/components/Unauthorized";
import { useMetricsDashboard } from "~/hooks/usePages";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import Skeleton from "@mui/material/Skeleton";
import { ErrorBoundary } from "@sentry/react";
import { Suspense, StrictMode } from "react";

const ActiveStrategies = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/metrics-dashboard/_components/ActiveStrategies"
      )
    ).ActiveStrategies,
  };
});

const Filter = reactLazyWithRetry(async () => {
  return {
    default: (
      await import("~/app/(dashboard)/metrics-dashboard/_components/Filter")
    ).Filter,
  };
});

const Cards = reactLazyWithRetry(async () => {
  return {
    default: (
      await import("~/app/(dashboard)/metrics-dashboard/_components/Cards")
    ).Cards,
  };
});

const SortGraphs = reactLazyWithRetry(async () => {
  return {
    default: (
      await import("~/app/(dashboard)/metrics-dashboard/_components/SortGraphs")
    ).SortGraphs,
  };
});
export function Component() {
  const { hasViewPermission } = useMetricsDashboard();

  if (!hasViewPermission) {
    return <Unauthorized />;
  }
  return (
    <>
      <StrictMode>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "filter");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={90} />}
          >
            <Filter />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "cards");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={81} />}
          >
            <Cards />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "sortgraphs");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={79} />}
          >
            <SortGraphs />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "activestrategies");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={341} />}
          >
            <ActiveStrategies />
          </Suspense>
        </ErrorBoundary>
      </StrictMode>
    </>
  );
}

Component.displayName = "MetricsDashboard";
