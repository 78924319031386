import {
  alertState,
  jwtTokenState,
  loadingResponseState,
  loggedInState,
  otpState,
  rememberPasswordState,
  sessionState,
  showOtpState,
  showResetPasswordPromptState,
  showTypeResetPasswordCodePromptState,
  userAtomState,
  userDataState,
  userLoginState,
  userResetState,
} from "~/app/state/login";
import { isLogoutState } from "~/states/pages";
import type { GenerateOtpB, ValidateOtpB } from "~/types";
import type {
  PasswordResetRequest,
  PasswordResetRequestWithToken,
  PasswordVerificationRequest,
} from "~/types/access";
import {
  accessUrl,
  generateOtpUrl,
  sessionInfoUrl,
  validateOtpUrl,
} from "~/utils/endpoints";
import { get, postWithoutJsonResponse } from "~/utils/fetchApi";
import { generateId } from "~/utils/generateId";
import type { FormControlLabelProps } from "@mui/material/FormControlLabel";
import type { SelectProps } from "@mui/material/Select";
import type { TextFieldProps } from "@mui/material/TextField";
import { useAtom } from "jotai";
import { type FormEventHandler, useEffect } from "react";
import {
  useLoaderData,
  useNavigate,
} from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { captureException } from "@sentry/react";

export const useHero = () => {
  const [user, _setUser] = useRecoilState(userLoginState);
  return { user };
};

export const useHeader = () => {
  const showOtp = useRecoilValue(showOtpState);
  const showResetPasswordPrompt = useRecoilValue(showResetPasswordPromptState);
  const showTypeResetPasswordCodePrompt = useRecoilValue(
    showTypeResetPasswordCodePromptState,
  );

  return {
    showOtp,
    showResetPasswordPrompt,
    showTypeResetPasswordCodePrompt,
  };
};

export const useLoader = () => {
  const loadingResponse = useRecoilValue(loadingResponseState);

  return {
    loadingResponse,
  };
};

export const useAlert = () => {
  const showAlert = useRecoilValue(alertState);

  return {
    showAlert,
  };
};

export const useUserPass = () => {
  const [user, setUser] = useRecoilState(userLoginState);
  //TODO: Rename to user only
  const [userAtom, setUserAtom] = useAtom(userAtomState);
  const setSession = useSetRecoilState(sessionState);
  const resetSesssion = useResetRecoilState(sessionState);
  const resetOtp = useResetRecoilState(otpState);
  const setOtp = useSetRecoilState(otpState);
  const setShowAlert = useSetRecoilState(alertState);
  const resetShowAlert = useResetRecoilState(alertState);
  const [loadingResponse, setLoadingResponse] =
    useRecoilState(loadingResponseState);
  const [showOtp, setShowOtp] = useRecoilState(showOtpState);
  const [showResetPasswordPrompt, setShowResetPasswordPrompt] = useRecoilState(
    showResetPasswordPromptState,
  );
  const showTypeResetPasswordCodePrompt = useRecoilValue(
    showTypeResetPasswordCodePromptState,
  );
  const setUserData = useSetRecoilState(userDataState);
  const headerJsonFallbackWithCatalog = new Headers({
    "Content-Type": "application/json",
    "X-DB-Catalog": user.dbCatalog,
    "X-OPERATION-ID": generateId(),
  });
  const [checked, setChecked] = useRecoilState(rememberPasswordState);
  const companies = useLoaderData() as {
    company: string;
    catalog: string;
  }[];
  const setIsLogout = useSetRecoilState(isLogoutState);

  useEffect(() => {
    if (checked === false) {
      if (user.company === "") {
        setUser((prevState) => ({
          ...prevState,
          company: companies[0].company,
          dbCatalog: companies[0].catalog,
        }));
        setUserAtom(() => ({
          ...userAtom,
          company: companies[0].company,
          dbCatalog: companies[0].catalog,
        }));
      }
    }
  }, [checked, companies, setUser, setUserAtom, userAtom, user]);

  const handleChangeCheckbox: FormControlLabelProps["onChange"] = () => {
    setChecked((prev) => {
      const newValue = !prev;
      try {
        if (newValue === false) {
          secureLocalStorage.removeItem("userLogin");
        } else {
          secureLocalStorage.setItem("userLogin", JSON.stringify(newValue));
        }
      } catch (_error) {
        secureLocalStorage.removeItem("userLogin");
      }
      return newValue;
    });
  };

  const handleTextFieldChange =
    <T extends keyof typeof user>(key: T): TextFieldProps["onChange"] =>
    (e) => {
      setUser((prevState) => ({
        ...prevState,
        [key]: e.target.value,
      }));
      setUserAtom(() => ({
        ...userAtom,
        [key]: e.target.value,
      }));
    };

  const handleSelectChange =
    <T extends keyof typeof user>(key: T): SelectProps<string>["onChange"] =>
    (e) => {
      if (key === "company") {
        const selectedCompany = e.target.value;
        const selectedCompanyData = companies.find(
          (list) => list.company === selectedCompany,
        );
        const dbCatalog = selectedCompanyData
          ? selectedCompanyData.catalog
          : "";
        setUser((prevState) => ({
          ...prevState,
          company: e.target.value,
          dbCatalog,
        }));
        setUserAtom(() => ({
          ...userAtom,
          company: e.target.value,
          dbCatalog,
        }));
      } else {
        setUser((prevState) => ({
          ...prevState,
          [key]: e.target.value,
        }));
        setUserAtom(() => ({
          ...userAtom,
          [key]: e.target.value,
        }));
      }
    };

  const handleForgotPassword = () => {
    setShowResetPasswordPrompt(true);
  };

  const getSession = (username: string) => {
    get<string[]>({
      url: `${sessionInfoUrl()}?${new URLSearchParams({
        name: username,
      })}`,
    }).then((body) => {
      if (body) {
        setSession(body);
        setOtp("");
      }
    });
  };

  const handleLoginSubmit: FormEventHandler = (event) => {
    setIsLogout(true);
    setLoadingResponse(true);
    resetSesssion();
    resetShowAlert();
    resetOtp();
    event.preventDefault();
    postWithoutJsonResponse<PasswordVerificationRequest>({
      url: `${accessUrl()}/verify-password`,
      headers: headerJsonFallbackWithCatalog,
      body: {
        username: user.userName,
        password: user.password,
      },
    })
      .then((response) => {
        setLoadingResponse(true);
        if (response.ok) {
          setUserData({ userName: user.userName });
          postWithoutJsonResponse<GenerateOtpB>({
            url: generateOtpUrl(),
            headers: headerJsonFallbackWithCatalog,
            body: {
              username: user.userName,
            },
          })
            .then((response) => {
              if (response.ok) {
                setShowAlert((prev) => ({
                  ...prev,
                  emailAlert: {
                    ...prev.emailAlert,
                    value: true,
                  },
                  currentAlert: "emailAlert",
                }));
                setLoadingResponse(false);
                setShowOtp(true);
                getSession(user.userName);
              } else {
                setShowAlert((prev) => ({
                  ...prev,
                  errorAlert: {
                    ...prev.errorAlert,
                    value: true,
                  },
                  currentAlert: "errorAlert",
                }));
                setShowOtp(false);
                setLoadingResponse(false);
              }
            })
            .catch((error) => {
              setShowAlert((prev) => ({
                ...prev,
                errorAlert: {
                  ...prev.errorAlert,
                  value: true,
                },
                currentAlert: "errorAlert",
              }));
              setShowOtp(false);
              setLoadingResponse(false);
              captureException(error);
            });
        } else {
          setShowAlert((prev) => ({
            ...prev,
            invalidUserAlert: {
              ...prev.invalidUserAlert,
              value: true,
            },
            currentAlert: "invalidUserAlert",
          }));
          setLoadingResponse(false);
        }
      })
      .catch((error) => {
        setShowAlert((prev) => ({
          ...prev,
          emailAlert: {
            ...prev.emailAlert,
            value: true,
          },
          currentAlert: "emailAlert",
        }));
        setLoadingResponse(false);
        captureException(error);
      });
  };

  return {
    user,
    showOtp,
    loadingResponse,
    handleTextFieldChange,
    handleSelectChange,
    handleChangeCheckbox,
    checked,
    handleForgotPassword,
    showResetPasswordPrompt,
    showTypeResetPasswordCodePrompt,
    handleLoginSubmit,
  };
};

export const useOtp = () => {
  const navigate = useNavigate();
  const user = useRecoilValue(userLoginState);
  const setLoggedIn = useSetRecoilState(loggedInState);
  const [otp, setOtp] = useRecoilState(otpState);
  const setShowAlert = useSetRecoilState(alertState);
  const resetShowAlert = useResetRecoilState(alertState);
  const setLoadingResponse = useSetRecoilState(loadingResponseState);
  const showOtp = useRecoilValue(showOtpState);
  const setJwtToken = useSetRecoilState(jwtTokenState);
  const userData = useRecoilValue(userDataState);
  const headerJsonFallbackWithCatalog = new Headers({
    "Content-Type": "application/json",
    "X-DB-Catalog": user.dbCatalog,
    "X-OPERATION-ID": generateId(),
  });
  const setIsLogout = useSetRecoilState(isLogoutState);

  const handleOtpChange = (newValue: string) => {
    setOtp(newValue);
  };
  const handleOtpComplete = (finalValue: string) => {
    checkOtp(finalValue);
  };

  const checkOtp = (value: string) => {
    setLoadingResponse(true);
    postWithoutJsonResponse<ValidateOtpB>({
      url: validateOtpUrl(),
      headers: headerJsonFallbackWithCatalog,
      body: {
        otp: value,
        username: userData.userName,
      },
    })
      .then(async (response) => {
        if (response.ok) {
          response.json().then(
            (body: {
              token: string;
              valid: boolean;
              message: string;
              status: string;
            }) => {
              if (body.valid) {
                secureLocalStorage.setItem("jwtToken", JSON.stringify(body.token));
                setJwtToken(body.token);
                setIsLogout(false);
                setLoggedIn(true);
                resetShowAlert();
                navigate("/home");
              } else if (body.message === "OTP is invalid") {
                setShowAlert((prev) => ({
                  ...prev,
                  invalidCodeAlert: {
                    ...prev.invalidCodeAlert,
                    value: true,
                  },
                  currentAlert: "invalidCodeAlert",
                }));
              } else {
                setShowAlert((prev) => ({
                  ...prev,
                  invalidOrExpiredCodeAlert: {
                    ...prev.invalidOrExpiredCodeAlert,
                    value: true,
                  },
                  currentAlert: "invalidOrExpiredCodeAlert",
                }));
              }
            },
          );
        } else {
          setShowAlert((prev) => ({
            ...prev,
            errorAlert: {
              ...prev.errorAlert,
              value: true,
            },
            currentAlert: "errorAlert",
          }));
        }
      })
      .finally(() => setLoadingResponse(false));
  };

  return {
    showOtp,
    handleOtpChange,
    handleOtpComplete,
    otp,
  };
};

export const useRequestResetPassword = () => {
  const user = useRecoilValue(userLoginState);
  //TODO: Rename to user only
  const [userReset, setUserReset] = useRecoilState(userResetState);
  const setShowAlert = useSetRecoilState(alertState);
  const [loadingResponse, setLoadingResponse] =
    useRecoilState(loadingResponseState);
  const [showResetPasswordPrompt, setShowResetPasswordPrompt] = useRecoilState(
    showResetPasswordPromptState,
  );
  const setShowTypeResetPasswordCodePrompt = useSetRecoilState(
    showTypeResetPasswordCodePromptState,
  );
  const headerJsonFallbackWithCatalog = new Headers({
    "Content-Type": "application/json",
    "X-DB-Catalog": user.dbCatalog,
    "X-OPERATION-ID": generateId(),
  });

  const handleSendResetPasswordPrompt = () => {
    setLoadingResponse(true);
    postWithoutJsonResponse<PasswordResetRequest>({
      url: `${accessUrl()}/request-password-reset`,
      headers: headerJsonFallbackWithCatalog,
      body: {
        username: userReset.username,
      },
    }).then((response) => {
      if (response.ok) {
        setShowAlert((prev) => ({
          ...prev,
          emailResetAlert: {
            ...prev.emailResetAlert,
            value: true,
          },
          currentAlert: "emailResetAlert",
        }));
        setShowResetPasswordPrompt(false);
        setShowTypeResetPasswordCodePrompt(true);
        setLoadingResponse(false);
      } else {
        setShowAlert((prev) => ({
          ...prev,
          errorAlert: {
            ...prev.errorAlert,
            value: true,
          },
          currentAlert: "errorAlert",
        }));
        setLoadingResponse(false);
      }
    });
  };

  const handleTextFieldChange2 =
    <T extends keyof typeof userReset>(key: T): TextFieldProps["onChange"] =>
    (e) => {
      setUserReset(() => ({
        ...userReset,
        [key]: e.target.value,
      }));
    };

  return {
    loadingResponse,
    showResetPasswordPrompt,
    handleSendResetPasswordPrompt,
    userReset,
    handleTextFieldChange2,
  };
};

export const useCompleteResetPassword = () => {
  const user = useRecoilValue(userLoginState);
  //TODO: Rename to user only
  const [userReset, setUserReset] = useRecoilState(userResetState);
  const setShowAlert = useSetRecoilState(alertState);
  const [loadingResponse, setLoadingResponse] =
    useRecoilState(loadingResponseState);
  const [showTypeResetPasswordCodePrompt, setShowTypeResetPasswordCodePrompt] =
    useRecoilState(showTypeResetPasswordCodePromptState);
  const headerJsonFallbackWithCatalog = new Headers({
    "Content-Type": "application/json",
    "X-DB-Catalog": user.dbCatalog,
    "X-OPERATION-ID": generateId(),
  });

  const handleTextFieldChange2 =
    <T extends keyof typeof userReset>(key: T): TextFieldProps["onChange"] =>
    (e) => {
      setUserReset(() => ({
        ...userReset,
        [key]: e.target.value,
      }));
    };

  const handleSendResetPasswordCode = () => {
    setLoadingResponse(true);
    postWithoutJsonResponse<PasswordResetRequestWithToken>({
      url: `${accessUrl()}/reset-password`,
      headers: headerJsonFallbackWithCatalog,
      body: {
        username: userReset.username,
        newPassword: userReset.newPassword,
        token: userReset.token,
      },
    }).then((response) => {
      if (response.ok) {
        setShowAlert((prev) => ({
          ...prev,
          emailResetAlert: {
            ...prev.emailResetAlert,
            value: false,
          },
          currentAlert: null,
        }));
        setShowTypeResetPasswordCodePrompt(false);
        setLoadingResponse(false);
      } else {
        setShowAlert((prev) => ({
          ...prev,
          errorAlert: {
            ...prev.errorAlert,
            value: true,
          },
          currentAlert: "errorAlert",
        }));
        setLoadingResponse(false);
      }
    });
  };

  return {
    loadingResponse,
    showTypeResetPasswordCodePrompt,
    userReset,
    handleTextFieldChange2,
    handleSendResetPasswordCode,
  };
};
