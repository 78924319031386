import type { layoutLoader } from "~/app/(dashboard)/_components/loader";
import { UseRolesPermissionsServiceGetPermissionsByRoleKeyFn } from "~/queries";
import { RolesPermissionsService } from "~/requests";
import { userAtomState as userState } from "~/app/state/login";
import { useQueries } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { useRouteLoaderData } from "react-router-dom";
import { useUsers } from "./useUsers";

const useRolesPermissions = () => {
  const _initialData = useRouteLoaderData("layout") as Awaited<
    ReturnType<ReturnType<typeof layoutLoader>>
  >;
  const loadedUser = useAtomValue(userState);
  const { data: users } = useUsers();
  const currentUser = users?.find(
    (user) => user.userCode === loadedUser.userName,
  )?.userRole;
  const userRoles = currentUser?.split(",");
  const userRolesPermissions = useQueries({
    queries: userRoles
      ? userRoles.map((userRole) => {
          return {
            queryKey: UseRolesPermissionsServiceGetPermissionsByRoleKeyFn({
              roleId: userRole,
              xDbCatalog: loadedUser.dbCatalog,
            }),
            queryFn: () =>
              RolesPermissionsService.getPermissionsByRole({
                roleId: userRole,
                xDbCatalog: loadedUser.dbCatalog,
              }),
          };
        })
      : [], // if users is undefined, an empty array will be returned
    combine: (results) => {
      return {
        data: results
          .flatMap((userRolePermissions) => userRolePermissions.data)
          .filter((data) => data !== undefined && data !== null),
        isLoading: results.some(
          (userRolePermissions) => userRolePermissions.isLoading,
        ),
        error: results
          .filter((userRolePermissions) => userRolePermissions.error)
          .map((userRolePermissions) => userRolePermissions.error),
      };
    },
  });

  const permissions = userRolesPermissions.data;

  return { permissions };
};

export const useCheckPermission = (permissionId: number) => {
  const { permissions } = useRolesPermissions();

  const hasPermission = permissions.some(
    (permission) => permission.permissionId === permissionId,
  );

  return {
    hasPermission,
  };
};
