import { useHeader } from "~/app/useLogin";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

function Component() {
  const { showOtp, showResetPasswordPrompt, showTypeResetPasswordCodePrompt } =
    useHeader();
  const { t } = useTranslation("login");

  return (
    <>
      {!(
        showOtp ||
        showResetPasswordPrompt ||
        showTypeResetPasswordCodePrompt
      ) && (
        <>
          <Typography
            component="h1"
            variant="h5"
            fontWeight="bold"
            align="left"
          >
            {t("title")}
          </Typography>
          <Typography component="p" align="left">
            {t("subtitle")}
          </Typography>
        </>
      )}
    </>
  );
}

Component.displayName = "Header";
export const Header = Component;
