import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import Skeleton from "@mui/material/Skeleton";
import { ErrorBoundary } from "@sentry/react";
import { Suspense } from "react";
import { useLocation } from "react-router-dom";
import { FallbackComponent } from "~/components/FallbackComponent";
import type { LocationProps } from "~/types/props";

const Form = reactLazyWithRetry(async () => {
  return {
    default: (await import("./Form")).Form,
  };
});

export const SafeForm = () => {
  const location = useLocation() satisfies LocationProps;
  const isClientFinder = location.pathname === "/client-finder";

  if (isClientFinder) {
    return null;
  }

  return (
    <ErrorBoundary
      beforeCapture={(scope) => {
        scope.setTag("component", "form");
      }}
      fallback={FallbackComponent}
    >
      <Suspense
        fallback={<Skeleton width="100%" height={40} color="primary" />}
      >
        <Form />
      </Suspense>
    </ErrorBoundary>
  );
};
