import "~/config/scan";
import "dayjs/locale/en";
import "dayjs/locale/es";
import "~/config/i18n";
import "~/config/wdyr";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "react-toastify/ReactToastify.min.css";
import "./styles.css";
import { registerSW } from "virtual:pwa-register";
import CssBaseline from "@mui/material/CssBaseline";
import createTheme from "@mui/material/styles/createTheme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  captureConsoleIntegration,
  captureException,
  contextLinesIntegration,
  extraErrorDataIntegration,
  httpClientIntegration,
  reportingObserverIntegration,
} from "@sentry/browser";
import {
  browserTracingIntegration,
  init,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
  wrapCreateBrowserRouter,
} from "@sentry/react";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { type ReactNode, useEffect, useMemo } from "react";
import { createRoot } from "react-dom/client";
import { useTranslation } from "react-i18next";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  type RouteObject,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { RecoilRoot } from "recoil";
import { DebugObserver } from "./components/DebugObserver";
import { FallbackRouter } from "./components/FallbackRouter";
import { Component as Layout } from "./app/(dashboard)/_components/Default";
import {
  adapterLocaleObj,
  coreLangObj,
  darkTheme,
  datePickerLangObj,
  gridLangObj,
  theme,
} from "./config/theme";
import { NuqsAdapter } from "nuqs/adapters/react-router";
import { useConfiguration } from "./app/(dashboard)/configuration/useConfiguraction";
import { Component as FieldsMaintenance } from "~/app/(dashboard)/fields-maintenance/page";
import { Component as PersonalizationMaintenace } from "./app/(dashboard)/personalization-maintenance/page";
import { Component as PauseMaintenance } from "~/app/(dashboard)/pause-maintenance/page";
import { Component as ActiveAssignedFlow } from "./app/(dashboard)/active-assigned-flow/page";
import { Component as ActiveAssignedFlowJuridical } from "./app/(dashboard)/active-assigned-flow-juridical/page";
import { Component as AssignLists } from "./app/(dashboard)/assign-lists/page";
import { Component as AssignedStrategies } from "./app/(dashboard)/assigned-strategies/page";
import { Component as ClientFinder } from "./app/(dashboard)/client-finder/page";
import { Component as ClientManagement } from "./app/(dashboard)/client-management/page";
import { Component as CreateFlow } from "./app/(dashboard)/create-flow/page";
import { Component as CreateFlowJuridical } from "./app/(dashboard)/create-flow-juridical/page";
import { Component as ErrorPage } from "./app/Error";
import { Component as AppErrorPage } from "./app/AppError";
import { Component as ExecutiveMaintenance } from "./app/(dashboard)/executive-maintenance/page";
import { Component as FlowMaintenance } from "./app/(dashboard)/flow_maintenance/page";
import { Component as GroupMaintenance } from "./app/(dashboard)/group-maintenance/page";
import { Component as Home } from "./app/(dashboard)/home/page";
import { Component as Login } from "./app/Login";
import { Component as MarkClient } from "./app/(dashboard)/mark-client/page";
import { Component as MetricsDashboard } from "./app/(dashboard)/metrics-dashboard/page";
import { Component as MetricsReport } from "./app/(dashboard)/metrics-reports/page";
import { Component as Strategies } from "./app/(dashboard)/strategies/page";
import { Component as StrategyMaintenance } from "./app/(dashboard)/strategy-maintenance/page";
import { PagePaths } from "./types";
import { clientSearchLoader } from "./app/(dashboard)/_hooks/clientSearchLoader";
import { version } from "./package.json";
import CacheBuster from "react-cache-buster";
import { Loading } from "./components/Loading";
import { layoutLoader } from "./app/(dashboard)/_components/loader";
import { homeloader } from "./app/(dashboard)/home/loader";
import { clientManagementLoader } from "./app/(dashboard)/client-management/clientManagementloader";
import { Toaster } from "react-hot-toast";
import secureLocalStorage from "react-secure-storage";
import { loader as CatalogsLoader } from "./app/(dashboard)/catalogs/_loader";
import { Component as CatalogsComponent } from "./app/(dashboard)/catalogs/route";
import { loader as CatalogsCatalogLoader } from "./app/(dashboard)/catalogs.catalog/_loader";
import { action as CatalogsCatalogAction } from "./app/(dashboard)/catalogs.catalog/_action";
import { Component as CatalogsCatalogComponent } from "./app/(dashboard)/catalogs.catalog/route";

registerSW({ immediate: true });

const clientErrorRegex = /HTTP Client Error with status code: \d+/;

init({
  dsn: "https://b7aaa2cdbddc7f12e8460df2dd37eec3@o4506057370173440.ingest.us.sentry.io/4506057372401664",
  integrations: import.meta.env.DEV
    ? undefined
    : [
        httpClientIntegration({
          failedRequestStatusCodes: [[402, 599]],
          failedRequestTargets: [/.*/],
        }),
        extraErrorDataIntegration(),
        captureConsoleIntegration(),
        contextLinesIntegration(),
        reportingObserverIntegration(),
        browserTracingIntegration(),
        reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
          maskAllInputs: false,
          networkDetailAllowUrls: [
            window.location.origin,
            /^https:\/\/(paymetest|paymedemo)\.azurewebsites\.net/,
            /\.paymesoft\.com/,
            /^https:\/\/(paymetest|paymedemo)\.azurewebsites\.net\/api/,
            /\.paymesoft\.com\/api/,
          ],
        }),
      ],
  enableTracing: true,
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  // This option is required for capturing headers and cookies.
  sendDefaultPii: true,
  attachStacktrace: true,
  release: version,
  tracePropagationTargets: [
    /^https:\/\/(paymetest|paymedemo)\.azurewebsites\.net/,
    /\.paymesoft\.com/,
    /^https:\/\/(paymetest|paymedemo)\.azurewebsites\.net\/api/,
    /\.paymesoft\.com\/api/,
  ],
  beforeSend: (event, hint) => {
    const exception = hint.originalException;
    // @ts-ignore
    if (exception?.message?.match(clientErrorRegex)) {
      // @ts-ignore
      event.fingerprint = [`${exception.message} ${hint.data.url}`];
    }
    return event;
  },
  ignoreErrors: [
    "antifingerprint not defined yet. will try and handle event after its ready...",
    "TypeError: Failed to fetch",
    "ApiError: Generic Error: status: 401; status text: ; body: undefined",
    /^ReportingObserver [deprecation]:/,
  ],
});

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);
const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (err) => {
      if (
        (
          err as {
            response?: {
              status: number;
            };
          }
        ).response?.status === 401
      ) {
        window.location.replace("/");
      } else {
        captureException(err);
      }
    },
  }),
  defaultOptions: {
    queries: {
      retry: (failureCount, err) => {
        if (
          (
            err as {
              response?: {
                status: number;
              };
            }
          ).response?.status === 401
        ) {
          return false;
        }

        const defaultRetry = new QueryClient().getDefaultOptions().queries
          ?.retry;

        return Number.isSafeInteger(defaultRetry)
          ? failureCount < (Number(defaultRetry) ?? 0)
          : false;
      },
    },
    mutations: {
      onError: (err) => {
        if (
          (
            err as {
              response?: {
                status: number;
              };
            }
          ).response?.status === 401
        ) {
          window.location.replace("/");
        } else {
          captureException(err);
        }
      },
    },
  },
});

const routes = [
  {
    path: "/",
    element: <Login />,
    loader: () => {
      const body = JSON.parse(import.meta.env.VITE_COMPANY_CATALOG_JSON);
      secureLocalStorage.removeItem("jwtToken");
      return body ?? [];
    },
    hydrateFallbackElement: <FallbackRouter />,
    errorElement: <ErrorPage />,
  },
  {
    element: <Layout />,
    errorElement: <AppErrorPage />,
    loader: layoutLoader(queryClient),
    hydrateFallbackElement: <FallbackRouter />,
    id: "layout",
    children: [
      {
        path: PagePaths.Home,
        element: <Home />,
        loader: homeloader(queryClient),
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.Clients,
        loader: clientManagementLoader(),
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.ClientFinder,
        element: <ClientFinder />,
        loader: clientSearchLoader(queryClient),
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.ClientManagement,
        element: <ClientManagement />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.Strategies,
        element: <Strategies />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.AssignedStrategies,
        element: <AssignedStrategies />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.AssignLists,
        element: <AssignLists />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.MarkClient,
        element: <MarkClient />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.StrategyMaintenance,
        element: <StrategyMaintenance />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.FormatMaintenance,
        async lazy() {
          const { loader, Component } = await import(
            "~/app/(dashboard)/formats/route"
          );
          return {
            loader: loader(queryClient),
            Component,
          };
        },
        hydrateFallbackElement: <FallbackRouter />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: PagePaths.FormatMaintenanceChannel,
            async lazy() {
              const { loader, Component } = await import(
                "~/app/(dashboard)/formats.$channelId/route"
              );
              return {
                loader: loader(queryClient),
                Component,
              };
            },
            hydrateFallbackElement: <FallbackRouter />,
            errorElement: <ErrorPage />,
            children: [
              {
                index: true,
                async lazy() {
                  const { loader, Component } = await import(
                    "~/app/(dashboard)/formats.$channelId.$formatId/route"
                  );
                  return {
                    loader: loader(queryClient),
                    Component,
                  };
                },
                hydrateFallbackElement: <FallbackRouter />,
                errorElement: <ErrorPage />,
              },
              {
                path: PagePaths.FormatMaintenanceChannelFormat,
                async lazy() {
                  const { loader, Component } = await import(
                    "~/app/(dashboard)/formats.$channelId.$formatId/route"
                  );
                  return {
                    loader: loader(queryClient),
                    Component,
                  };
                },
                hydrateFallbackElement: <FallbackRouter />,
                errorElement: <ErrorPage />,
              },
            ],
          },
        ],
      },
      {
        path: PagePaths.CatalogsMaintenance,
        loader: CatalogsLoader(queryClient),
        element: <CatalogsComponent/>,
        hydrateFallbackElement: <FallbackRouter />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: PagePaths.CatalogsMaintenanceCatalog,
            loader: CatalogsCatalogLoader(queryClient),
            element: <CatalogsCatalogComponent />,
            hydrateFallbackElement: <FallbackRouter />,
            errorElement: <ErrorPage />,
            action: CatalogsCatalogAction(queryClient),
          },
        ],
      },
      {
        path: PagePaths.PauseMaintenance,
        element: <PauseMaintenance />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.HolidayMaintenance,
        async lazy() {
          const { loader, Component } = await import(
            "~/app/(dashboard)/holidays/route"
          );
          return {
            loader: loader(queryClient),
            Component,
          };
        },
        hydrateFallbackElement: <FallbackRouter />,
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            async lazy() {
              const { Component } = await import(
                "~/app/(dashboard)/holidays.$id/route"
              );
              return {
                Component,
              };
            },
            hydrateFallbackElement: <FallbackRouter />,
            errorElement: <ErrorPage />,
          },
          {
            path: PagePaths.HolidayMaintenanceHoliday,
            async lazy() {
              const { Component } = await import(
                "~/app/(dashboard)/holidays.$id/route"
              );
              return {
                Component,
              };
            },
            hydrateFallbackElement: <FallbackRouter />,
            errorElement: <ErrorPage />,
          },
        ],
      },
      {
        path: PagePaths.ExecutiveMaintenance,
        element: <ExecutiveMaintenance />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.FlowMaintenance,
        element: <FlowMaintenance />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.GroupMaintenance,
        element: <GroupMaintenance />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.LoadMaintenance,
        lazy: () => import("./app/(dashboard)/load-maintenance/page"),
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.Metrics,
      },
      {
        path: PagePaths.MetricsReports,
        element: <MetricsReport />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.MetricsDashboard,
        element: <MetricsDashboard />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.Configuration,
        lazy: () => import("./app/(dashboard)/configuration/page"),
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.Flows,
      },
      {
        path: PagePaths.ActiveAssignedFlows,
        element: <ActiveAssignedFlow />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.CreateFlow,
        element: <CreateFlow />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.Juridical,
      },
      {
        path: PagePaths.ActiveAssignedFlowsJuridical,
        element: <ActiveAssignedFlowJuridical />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.CreateFlowJuridical,
        element: <CreateFlowJuridical />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.FieldsMaintenance,
        element: <FieldsMaintenance />,
        errorElement: <ErrorPage />,
      },
      {
        path: PagePaths.IntegrationMaintenance,
        async lazy() {
          const { loader, Component } = await import(
            "~/app/(dashboard)/integrations/route"
          );
          return {
            loader: loader(queryClient),
            Component,
          };
        },
        errorElement: <ErrorPage />,
        hydrateFallbackElement: <FallbackRouter />,
        children: [
          {
            index: true,
            element: <></>,
          },
          {
            path: PagePaths.VitalPbxIntegration,
            async lazy() {
              const { loader, Component } = await import(
                "~/app/(dashboard)/integrations.vital-pbx/route"
              );
              return {
                loader: loader(queryClient),
                Component,
              };
            },
            errorElement: <ErrorPage />,
            hydrateFallbackElement: <FallbackRouter />,
          },
          {
            path: PagePaths.UcontactIntegration,
            async lazy() {
              const { loader, Component } = await import(
                "~/app/(dashboard)/integrations.u-contact/route"
              );
              return {
                loader: loader(queryClient),
                Component,
              };
            },
            errorElement: <ErrorPage />,
            hydrateFallbackElement: <FallbackRouter />,
          },
          {
            path: PagePaths.VicidialIntegration,
            async lazy() {
              const { loader, Component } = await import(
                "~/app/(dashboard)/integrations.vicidial/route"
              );
              return {
                loader: loader(queryClient),
                Component,
              };
            },
            errorElement: <ErrorPage />,
            hydrateFallbackElement: <FallbackRouter />,
          },
          {
            path: PagePaths.DocuwareIntegration,
            async lazy() {
              const { loader, Component } = await import(
                "~/app/(dashboard)/integrations.docuware/route"
              );
              return {
                loader: loader(queryClient),
                Component,
              };
            },
            errorElement: <ErrorPage />,
            hydrateFallbackElement: <FallbackRouter />,
          },
        ],
      },
      {
        path: PagePaths.PersonalizationMaintenance,
        element: <PersonalizationMaintenace />,
        errorElement: <ErrorPage />,
      },
    ],
  },
] satisfies RouteObject[];

const router = sentryCreateBrowserRouter(routes, {
  future: {
    v7_relativeSplatPath: true,
    v7_startTransition: true,
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_skipActionStatusRevalidation: true,
    v7_skipActionErrorRevalidation: true,
  },
});

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);

function App(): ReactNode {
  const { i18n } = useTranslation();
  const { isDarkMode } = useConfiguration();
  const themei18n = useMemo(
    () =>
      createTheme(
        isDarkMode ? darkTheme : theme,
        {},
        coreLangObj[i18n.language],
        gridLangObj[i18n.language],
        datePickerLangObj[i18n.language],
      ),
    [i18n.language, isDarkMode],
  );

  return (
    <ThemeProvider theme={themei18n}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={adapterLocaleObj[i18n.language.split("-")[0]]}
      >
        <CssBaseline />
        <ToastContainer position="bottom-right" newestOnTop={true} />
        <Toaster position="bottom-right" reverseOrder={false} />
        <NuqsAdapter>
          <RouterProvider
            router={router}
            future={{
              v7_startTransition: true,
            }}
          />
        </NuqsAdapter>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

root.render(
  <CacheBuster
    currentVersion={version}
    isEnabled={process.env.NODE_ENV === "production"}
    isVerboseMode={false}
    loadingComponent={<Loading />}
    metaFileDirectory={"."}
    onCacheClear={async (refreshCacheAndReload: () => Promise<void>) => {
      localStorage.clear();
      await refreshCacheAndReload();
    }}
  >
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        {import.meta.env.DEV && <DebugObserver />}
        <App />
      </RecoilRoot>
    </QueryClientProvider>
  </CacheBuster>,
);
