import {
  UseCatalogsServiceGetCatalogsGroupByColumnKeyFn,
  UseCatalogsServiceGetCatalogsKeyFn,
} from "~/queries/common";
import type {
  GetCatalogsData,
  GetCatalogsGroupByColumnData,
  GetCatalogsResponse,
} from "~/requests";
import { apiVersion } from "~/config/api";
import { createQueryFn } from "~/utils/query";

export const getCatalogsByTypeQuery = ({
  type,
  xDbCatalog,
}: GetCatalogsData) => ({
  queryKey: UseCatalogsServiceGetCatalogsKeyFn({ type, xDbCatalog }),
  queryFn: createQueryFn<GetCatalogsResponse>({
    method: "GET",
    url: `/api/v${apiVersion}/Catalogs/{type}`,
    path: {
      type,
    },
    headers: {
      "X-DB-Catalog": xDbCatalog,
    },
    errors: {
      400: "Returns a ResponseBadRequestError object",
      401: "Returns a ResponseUnauthenticatedError object",
      422: "Returns a ResponseUnprocessableEntityError object",
      500: "Returns a ResponseInternalServerError object",
    },
  }),
});

export type ExtendedGetCatalogsGroupByColumnData =
  GetCatalogsGroupByColumnData & {
    search?: string;
  };

export const getCatalogsGroupByColumnQuery = ({
  column,
  xDbCatalog,
}: ExtendedGetCatalogsGroupByColumnData) => ({
  queryKey: UseCatalogsServiceGetCatalogsGroupByColumnKeyFn({
    column,
    xDbCatalog,
  }),
  queryFn: createQueryFn<GetCatalogsResponse>({
    method: "GET",
    url: `/api/v${apiVersion}/Catalogs/GroupByColumn/{column}`,
    path: {
      column,
    },
    headers: {
      "X-DB-Catalog": xDbCatalog,
    },
    errors: {
      400: "Returns a ResponseBadRequestError object",
      401: "Returns a ResponseUnauthenticatedError object",
      422: "Returns a ResponseUnprocessableEntityError object",
      500: "Returns a ResponseInternalServerError object",
    },
  }),
});
