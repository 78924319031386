import { drawerWidth } from "~/config/theme";
import Box from "@mui/material/Box";
import type { SxProps, Theme } from "@mui/material/styles";
import { ErrorBoundary } from "@sentry/react";
import { useEffect } from "react";
import { Outlet, useNavigation } from "react-router-dom";
import { FallbackComponent } from "../../../components/FallbackComponent";
import { ProtectedRoute } from "./ProtectedRoute";
import { Sidebar } from "./Sidebar/Sidebar";
import { TopBar } from "./TopBar";
import { useReschedulesServiceGetReschedulesByDateAndExecutive } from "~/queries";
import dayjs from "dayjs";
import { userState } from "~/app/state/login";
import { useRecoilValue } from "recoil";
import { addNotification } from "~/utils/notify";
import { useQueryClient } from "@tanstack/react-query";
import { showSidebarState } from "../_state/layout";

export function Component() {
  const navigation = useNavigation();
  const user = useRecoilValue(userState);
  const showSidebar = useRecoilValue(showSidebarState);
  const queryClient = useQueryClient();
  const { data: notifications } =
    useReschedulesServiceGetReschedulesByDateAndExecutive(
      {
        date: dayjs().format("YYYY-MM-DD"),
        executive: user.userName,
        xDbCatalog: user.dbCatalog,
      },
      undefined,
      {},
    );

  useEffect(() => {
    const timeouts: NodeJS.Timeout[] = [];
    if (notifications) {
      const now = dayjs();
      for (const notification of notifications) {
        // biome-ignore lint/style/noNonNullAssertion: ignore because we are sure that the value is not null
        let visitDate = dayjs(notification.visitDate!);
        // biome-ignore lint/style/noNonNullAssertion: ignore because we are sure that the value is not null
        const [hours, minutes, seconds] = notification
          .visitTime!.split(":")
          .map(Number);

        visitDate = visitDate.hour(hours).minute(minutes).second(seconds);
        const delay = visitDate.diff(now);

        if (delay > 0) {
          const timeoutId = setTimeout(() => {
            addNotification({
              queryClient,
              messageType: "reschedule",
              // biome-ignore lint/style/noNonNullAssertion: ignore because we are sure that the value is not null
              item: String(notification.client!),
            });
          }, delay);
          timeouts.push(timeoutId);
        }
      }
    }

    return () => {
      timeouts.forEach(clearTimeout);
    };
  }, [notifications, queryClient]);

  const loadingStyles: SxProps<Theme> = {
    opacity: 0.25,
    transition: "opacity 200ms",
    transitionDelay: "200ms",
  };

  return (
    <>
      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("component", "TopBar");
        }}
        fallback={FallbackComponent}
      >
        <TopBar />
      </ErrorBoundary>
      <>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "SideBar");
          }}
          fallback={FallbackComponent}
        >
          <Sidebar />
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "Outlet");
          }}
          fallback={FallbackComponent}
        >
          <Sidebar />
          <Box
            height="fit-content"
            sx={{
              ...(navigation.state === "loading" && loadingStyles),
              width: {
                xs: "calc(100% - 32px - 0px)",
                sm: "calc(100% - 32px - 0px)",
                md: `calc(100% - 32px - ${showSidebar ? drawerWidth : 0}px)`,
              },
              mt: {
                xs: 8,
                sm: 10,
                md: 10,
              },
              ml: {
                xs: 2,
                sm: 2,
                md: showSidebar ? `calc(${drawerWidth}px + 16px)` : 2,
              },
            }}
            display="flex"
            flexDirection="column"
            m={2}
            gap={2}
          >
            <ProtectedRoute>
              <Outlet />
            </ProtectedRoute>
          </Box>
        </ErrorBoundary>
      </>
    </>
  );
}
