import { showSidebarState } from "~/app/(dashboard)/_state/layout";
import { userState } from "~/app/state/login";
import {
  isMenuOpenState,
  openedMenuValueState,
} from "~/app/(dashboard)/_state/sidebar";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useBreakpoints } from "../../../hooks/useBreakpoints";

interface MultiLevelProps {
  item: string;
}

export const useSidebar = () => {
  const [showSidebar, setShowSidebar] = useRecoilState(showSidebarState);
  const handleDrawerToggle = () => {
    setShowSidebar((prev) => !prev);
  };
  const { matches } = useBreakpoints({
    breakpoint: "md",
  });

  return {
    handleDrawerToggle,
    showSidebar,
    matches,
  };
};

export const useAvatar = () => {
  const user = useRecoilValue(userState);

  return {
    user,
  };
};

export const useMultiLevel = (props: MultiLevelProps) => {
  const [menuOpen, setMenuOpen] = useRecoilState(
    isMenuOpenState({ id: props.item }),
  );
  const [openedMenuValue, setOpenedMenuValue] =
    useRecoilState(openedMenuValueState);
  const closeMenu = useSetRecoilState(isMenuOpenState({ id: openedMenuValue }));

  const handleMenuOpen = () => {
    setOpenedMenuValue(props.item);
    setMenuOpen((prev) => !prev);
    if (openedMenuValue !== props.item) {
      closeMenu(false);
    }
  };

  return {
    handleMenuOpen,
    menuOpen,
  };
};
