import Box from "@mui/material/Box";
import { ErrorBoundary } from "@sentry/react";
import { FallbackComponent } from "../../components/FallbackComponent";
import { Alert } from "./Alert";
import { CompleteResetPassword } from "./CompleteResetPassword";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Loader } from "./Loader";
import { Otp } from "./Otp";
import { RequestResetPassword } from "./RequestResetPassword";
import { UserPass } from "./UserPass";

function Component() {
  return (
    <Box
      my={8}
      mx={4}
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      justifyContent="center"
      minHeight={500}
      gap={2}
      width="fill-available"
    >
      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("login", "loader");
        }}
        fallback={FallbackComponent}
      >
        <Loader />
      </ErrorBoundary>
      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("login", "alert");
        }}
        fallback={FallbackComponent}
      >
        <Alert />
      </ErrorBoundary>
      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("login", "header");
        }}
        fallback={FallbackComponent}
      >
        <Header />
      </ErrorBoundary>
      <Box width="fill-available" my={3}>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("login", "user-pass");
          }}
          fallback={FallbackComponent}
        >
          <UserPass />
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("login", "otp");
          }}
          fallback={FallbackComponent}
        >
          <Otp />
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("login", "request-reset-password");
          }}
          fallback={FallbackComponent}
        >
          <RequestResetPassword />
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("login", "complete-reset-password");
          }}
          fallback={FallbackComponent}
        >
          <CompleteResetPassword />
        </ErrorBoundary>
      </Box>
      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("login", "footer");
        }}
        fallback={FallbackComponent}
      >
        <Footer />
      </ErrorBoundary>
    </Box>
  );
}

Component.displayName = "Form";
export const Form = Component;
