import {
  alertState,
  loadingResponseState,
  sessionState,
  showOtpState,
  showResetPasswordPromptState,
  showTypeResetPasswordCodePromptState,
  userState,
} from "~/app/state/login";
import { isLogoutState } from "~/states/pages";
import type { LocationProps } from "~/types/props";
import { sessionInfoUrl } from "~/utils/endpoints";
import { captureException, withProfiler } from "@sentry/react";
import { type ReactElement, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";

const Component = ({ children }: { children: ReactElement }): ReactElement => {
  const session = useRecoilValue(sessionState);
  const user = useRecoilValue(userState);
  const setShowAlert = useSetRecoilState(alertState);
  const { pathname } = useLocation() satisfies LocationProps;
  const isLogout = useRecoilValue(isLogoutState);
  const setIsLogout = useSetRecoilState(isLogoutState);
  const resetSesssion = useResetRecoilState(sessionState);
  const resetShowAlert = useResetRecoilState(alertState);
  const resetShowOtp = useResetRecoilState(showOtpState);
  const resetShowResetPassword = useResetRecoilState(
    showResetPasswordPromptState,
  );
  const resetShowTypeResetPasswordCodePrompt = useResetRecoilState(
    showTypeResetPasswordCodePromptState,
  );
  const resetShowLoadingResponse = useResetRecoilState(loadingResponseState);
  const navigate = useNavigate();

  const logoutAndShowAlert = useCallback(() => {
    resetSesssion();
    resetShowAlert();
    setIsLogout(true);
    resetShowOtp();
    resetShowResetPassword();
    resetShowTypeResetPasswordCodePrompt();
    resetShowLoadingResponse();
    setShowAlert((prev) => ({
      ...prev,
      expiredSessionAlert: {
        ...prev.expiredSessionAlert,
        value: true,
      },
      currentAlert: "expiredSessionAlert",
    }));
    navigate("/", { replace: true });
  }, [
    resetSesssion,
    resetShowAlert,
    setIsLogout,
    resetShowOtp,
    resetShowResetPassword,
    resetShowTypeResetPasswordCodePrompt,
    resetShowLoadingResponse,
    setShowAlert,
    navigate,
  ]);

  const fetchSessionInfo = useCallback(() => {
    return fetch(
      `${sessionInfoUrl()}?${new URLSearchParams({
        name: user.userName,
      })}`,
    )
      .then(async (response) => {
        if (!response) {
          return null;
        }
        const headers = response.headers;
        const contentType = headers.get("Set-Authorization");
        if (contentType !== undefined && contentType !== null) {
          try {
            secureLocalStorage.setItem("jwtToken", JSON.stringify(contentType));
          } catch (_error) {
            secureLocalStorage.removeItem("jwtToken");
          }
        }
        const data = (await response.json()) as string[] | null;
        if (!data || session[1] !== data[1]) {
          logoutAndShowAlert();
        }
      })
      .catch((error) => {
        captureException(error);
        logoutAndShowAlert();
      });
  }, [user, session, logoutAndShowAlert]);

  useEffect(() => {
    if (pathname === "/" || isLogout) {
      return;
    }

    if (!user.userName) {
      logoutAndShowAlert();
      navigate("/", { replace: true });
    }

    fetchSessionInfo();
  }, [
    pathname,
    user,
    navigate,
    isLogout,
    logoutAndShowAlert,
    fetchSessionInfo,
  ]);

  return <>{children}</>;
};

Component.displayName = "ProtectedRoute";
export const ProtectedRoute = withProfiler(Component);
