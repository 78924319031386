import { selectedAssignedStrategyListState } from "~/app/(dashboard)/assigned-strategies/state/assignedStrategies";
import {
  closeContentState,
  flowOptionsValueState,
  loadingState,
  openDialogState,
  rowsContractsState,
  selectedContractDetailsState,
  selectedContractRowState,
  selectedFlowContractDetailsRowState,
  selectedFlowDetailRowState,
  selectedValueState,
  selectsReportsState,
  tasksListState,
  visualizeButtonCounterState,
  enableSegmentationV2State,
} from "~/states/pages";
import { useLazyRecoilValue } from "~/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";
import { useBreakpoints } from "./useBreakpoints";
import { useCheckPermission } from "~/app/_hooks/useRolesPermissions";
import { userState } from "~/app/state/login";
import { useUsers } from "~/app/_hooks/useUsers";

export const useActiveAssignFlows = ({ id }: { id: string }) => {
  const selectedFlowContractDetailsRow = useRecoilValue(
    selectedFlowContractDetailsRowState({ id }),
  );

  return {
    selectedFlowContractDetailsRow,
  };
};

export const useActiveAssignFlowJuridical = () => {
  const { hasPermission: hasViewPermission } = useCheckPermission(43);

  return {
    hasViewPermission,
  };
};

export const useActiveAssignFlow = () => {
  const { hasPermission: hasViewPermission } = useCheckPermission(44);

  return {
    hasViewPermission,
  };
};

export const useStrategies = () => {
  const { hasPermission: hasViewPermission } = useCheckPermission(40);

  return {
    hasViewPermission,
  };
};

export const useAssignedStrategies = () => {
  const selectedList = useRecoilValue(selectedAssignedStrategyListState);
  const { hasPermission: hasViewPermission } = useCheckPermission(41);

  return {
    selectedList,
    hasViewPermission,
  };
};

export const useMarkClient = () => {
  const { hasPermission: hasViewPermission } = useCheckPermission(42);

  return {
    hasViewPermission,
  };
};

export const usePauseMaintenance = () => {
  const { hasPermission: hasViewPermission } = useCheckPermission(51);

  return {
    hasViewPermission,
  };
};

export const useCreateFlow = ({ id }: { id: string }) => {
  const selectedFlowDetailRow = useRecoilValue(
    selectedFlowDetailRowState({ id }),
  );
  const selectedContractRow = useRecoilValue(selectedContractRowState({ id }));
  const contracts = useRecoilValue(rowsContractsState);
  const { hasPermission: hasViewPermission } = useCheckPermission(45);

  return {
    selectedFlowDetailRow,
    selectedContractRow,
    contracts,
    hasViewPermission,
  };
};

export const useFlowMaintenance = () => {
  const selectedValue = useRecoilValue(selectedValueState);
  const tasksList = useRecoilValue(tasksListState);
  const { hasPermission: hasViewPermission } = useCheckPermission(54);
  return { selectedValue, tasksList, hasViewPermission };
};

export const useGroupMaintenance = () => {
  const { hasPermission: hasViewPermission } = useCheckPermission(55);

  return {
    hasViewPermission,
  };
};

export const useMetricsReports = () => {
  const { data } = useUsers();
  const user = useRecoilValue(userState);
  const userId = data?.find((item) => item.userName === user.userName)?.sequential ?? 0;
  const { loading: loadingSelectsReports } = useLazyRecoilValue(
    [],
    selectsReportsState({userId}),
    "selectsMetricsReportKey",
  );
  const loadingData = useRecoilValue(loadingState);
  const visualizeButtonCounter = useRecoilValue(visualizeButtonCounterState);
  const { hasPermission: hasViewPermission } = useCheckPermission(46);

  return {
    loadingData,
    loadingSelectsReports,
    visualizeButtonCounter,
    hasViewPermission,
  };
};

export const useMetricsDashboard = () => {
  const { hasPermission: hasViewPermission } = useCheckPermission(47);

  return {
    hasViewPermission,
  };
};

export const useStrategyMaintenance = () => {
  const showDynamicFieldDataGrid = useRecoilValue(
    openDialogState({ id: "showDataGridDynamicDataFields" }),
  );
  const showFormats = useRecoilValue(
    openDialogState({ id: "showDataGridFormatsStrategyMaintenance" }),
  );
  const enableSegmentationV2 = useRecoilValue(enableSegmentationV2State);
  const { hasPermission: hasViewPermission } = useCheckPermission(48);

  return {
    showDynamicFieldDataGrid,
    showFormats,
    hasViewPermission,
    enableSegmentationV2,
  };
};

export const useSwipeableViews = ({ id }: { id: string }) => {
  const flowOptionsValue = useRecoilValue(flowOptionsValueState({ id }));
  const selectedFlowContractDetailsRow = useRecoilValue(
    selectedFlowContractDetailsRowState({ id }),
  );

  return {
    flowOptionsValue,
    selectedFlowContractDetailsRow,
  };
};

export const useClientManagement = () => {
  const [showWaChatBot, setShowWaChatBot] = useRecoilState(
    closeContentState({ id: "waChatBot" }),
  );
  const selectedContract = useRecoilValue(selectedContractDetailsState);
  const showPaymentsDataGrid = useRecoilValue(
    openDialogState({ id: "showDataGridPayments" }),
  );
  const showGuaranteesDataGrid = useRecoilValue(
    openDialogState({ id: "showDataGridGuarantees" }),
  );
  const showQuotesDataGrid = useRecoilValue(
    openDialogState({ id: "showDataGridQuotes" }),
  );
  const showDebtDistributionDataGrid = useRecoilValue(
    openDialogState({ id: "showDataGridDebtDistribution" }),
  );
  const showOrderItemsDataGrid = useRecoilValue(
    openDialogState({ id: "showDataGridOrderItems" }),
  );
  const showCardAccountStatement = useRecoilValue(
    openDialogState({ id: "showCardAccountStatement" }),
  );
  const showArticlesCard = useRecoilValue(
    openDialogState({ id: "showCardArticles" }),
  );
  const showMonthlyPaymentsCard = useRecoilValue(
    openDialogState({ id: "showCardMonthlyPayments" }),
  );
  const showExplanationsCard = useRecoilValue(
    openDialogState({ id: "showCardExplanations" }),
  );
  const showAddendumCard = useRecoilValue(
    openDialogState({ id: "showCardAddendum" }),
  );
  const { matches } = useBreakpoints({
    breakpoint: "lg",
  });
  const { hasPermission: hasViewPermission } = useCheckPermission(4);
  const { hasPermission: hasViewPermissionContractDetails } =
    useCheckPermission(64);
  const { hasPermission: hasViewPermissionContractData } =
    useCheckPermission(65);
  const { hasPermission: hadQuanticButtonsViewPermission } =
    useCheckPermission(77);
  const { hasPermission: hasViewAdvancedContractDetailsPermission } =
    useCheckPermission(118);

  const handleShowWaChatBot = () => {
    setShowWaChatBot((prev) => !prev);
  };

  return {
    selectedContract,
    showWaChatBot,
    handleShowWaChatBot,
    showGuaranteesDataGrid,
    showQuotesDataGrid,
    showPaymentsDataGrid,
    showDebtDistributionDataGrid,
    showOrderItemsDataGrid,
    showCardAccountStatement,
    showArticlesCard,
    showMonthlyPaymentsCard,
    showExplanationsCard,
    showAddendumCard,
    matches,
    hasViewPermission,
    hasViewPermissionContractDetails,
    hasViewPermissionContractData,
    hadQuanticButtonsViewPermission,
    hasViewAdvancedContractDetailsPermission,
  };
};

export const useExecutiveMaintenance = () => {
  const showRoleEditor = useRecoilValue(
    closeContentState({ id: "showRoleEditorExecutiveMaintenance" }),
  );
  const { hasPermission: hasViewPermission } = useCheckPermission(53);

  return {
    showRoleEditor,
    hasViewPermission,
  };
};

export const useHome = () => {
  const { hasPermission: hasViewPermission } = useCheckPermission(1);
  const { hasPermission: hasTableViewPermission } = useCheckPermission(93);
  const { hasPermission: hasChartViewPermission } = useCheckPermission(94);
  const { hasPermission: hasKpiViewPermission } = useCheckPermission(95);

  return {
    hasViewPermission,
    hasTableViewPermission,
    hasChartViewPermission,
    hasKpiViewPermission,
  };
};

export const useClientFinder = () => {
  const { hasPermission: hasViewPermission } = useCheckPermission(4);
  const { hasPermission: hasViewPermissionClientPersonalInformation } =
    useCheckPermission(105);
  const { hasPermission: hasViewPermissionClientInformationDetails } =
    useCheckPermission(106);
  const { hasPermission: hasViewPermissionContractData } =
    useCheckPermission(107);
  const { hasPermission: hasViewPermissionContractDetails } =
    useCheckPermission(108);

  return {
    hasViewPermission,
    hasViewPermissionClientPersonalInformation,
    hasViewPermissionClientInformationDetails,
    hasViewPermissionContractData,
    hasViewPermissionContractDetails,
  };
};

export const useFieldsMaintenance = () => {
  const { hasPermission: hasViewPermission } = useCheckPermission(63);

  return {
    hasViewPermission,
  };
};
