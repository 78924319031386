import { type MenuItem, PagePaths } from "~/types";
import DonutLarge from "@mui/icons-material/DonutLarge";
import DonutLargeOutlinedIcon from "@mui/icons-material/DonutLargeOutlined";
import GavelIcon from "@mui/icons-material/Gavel";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";
import Home from "@mui/icons-material/Home";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import Person from "@mui/icons-material/Person";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import Psychology from "@mui/icons-material/Psychology";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import {
  default as Speed,
  default as SpeedOutlinedIcon,
} from "@mui/icons-material/SpeedOutlined";
import SupportAgent from "@mui/icons-material/SupportAgent";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import { useTranslation } from "react-i18next";
import { createSearchParams } from "react-router-dom";

export const useMenuItems = (): MenuItem[] => {
  const { t } = useTranslation();
  return [
    {
      icon: <Home htmlColor="inherit" fontSize="inherit" />,
      iconOutlined: <HomeOutlinedIcon htmlColor="inherit" fontSize="inherit" />,
      title: t("topBar.home"),
      to: PagePaths.Home,
      items: [],
    },
    {
      icon: <Person htmlColor="white.main" fontSize="inherit" />,
      iconOutlined: (
        <PersonOutlineOutlinedIcon htmlColor="white.main" fontSize="inherit" />
      ),
      title: t("topBar.clients"),
      to: "#",
      items: [
        {
          title: t("topBar.clientManagement"),
          to: `${PagePaths.ClientFinder}?${createSearchParams({
            filter: "none",
            search: "",
          })}`,
          items: [],
        },
      ],
    },
    {
      icon: <SupportAgent htmlColor="white.main" fontSize="inherit" />,
      iconOutlined: (
        <SupportAgentOutlinedIcon htmlColor="white.main" fontSize="inherit" />
      ),
      title: t("topBar.management"),
      to: "#",
      items: [
        {
          title: t("topBar.strategies"),
          to: PagePaths.Strategies,
          items: [],
        },
        {
          title: t("topBar.assignedStrategies"),
          to: PagePaths.AssignedStrategies,
          items: [],
        },
        {
          title: t("topBar.markClient"),
          to: PagePaths.MarkClient,
          items: [],
        },
        {
          title: t("topBar.assignLists"),
          to: PagePaths.AssignLists,
          items: [],
        },
      ],
    },
    {
      icon: <GavelIcon htmlColor="white.main" fontSize="inherit" />,
      iconOutlined: (
        <GavelOutlinedIcon htmlColor="white.main" fontSize="inherit" />
      ),
      title: t("topBar.juridical"),
      to: "#",
      items: [
        {
          title: t("topBar.activeAssignedFlowsJuridical"),
          to: PagePaths.ActiveAssignedFlowsJuridical,
          items: [],
        },
        {
          title: t("topBar.createFlowJuridical"),
          to: PagePaths.CreateFlowJuridical,
          items: [],
        },
      ],
    },
    {
      icon: <DonutLarge htmlColor="white.main" fontSize="inherit" />,
      iconOutlined: (
        <DonutLargeOutlinedIcon htmlColor="white.main" fontSize="inherit" />
      ),
      title: t("topBar.operatingFlows"),
      to: "#",
      items: [
        {
          title: t("topBar.activeAssignedFlows"),
          to: PagePaths.ActiveAssignedFlows,
          items: [],
        },
        {
          title: t("topBar.createFlow"),
          to: PagePaths.CreateFlow,
          items: [],
        },
      ],
    },
    {
      icon: <Speed htmlColor="white.main" fontSize="inherit" />,
      iconOutlined: (
        <SpeedOutlinedIcon htmlColor="white.main" fontSize="inherit" />
      ),
      title: t("topBar.metrics"),
      to: "#",
      items: [
        {
          title: t("topBar.metricsReports"),
          to: PagePaths.MetricsReports,
          items: [],
        },
        {
          title: t("topBar.metricsDashboard"),
          to: PagePaths.MetricsDashboard,
          items: [],
        },
      ],
    },
    {
      icon: <Psychology htmlColor="white.main" fontSize="inherit" />,
      iconOutlined: (
        <PsychologyOutlinedIcon htmlColor="white.main" fontSize="inherit" />
      ),
      title: t("topBar.master"),
      to: "#",
      items: [
        {
          title: t("topBar.strategyMaintenance"),
          to: PagePaths.StrategyMaintenance,
          items: [],
        },
        {
          title: t("topBar.formatMaintenance"),
          to: PagePaths.FormatMaintenance,
          items: [
            {
              title: t("topBar.formatMaintenanceChannel"),
              to: PagePaths.FormatMaintenanceChannel,
              items: [
                {
                  title: t("topBar.formatMaintenanceChannelFormat"),
                  to: PagePaths.FormatMaintenanceChannelFormat,
                  items: [],
                  hidden: true,
                },
              ],
              hidden: true,
            },
          ],
        },
        {
          title: t("topBar.catalogsMaintenance"),
          to: PagePaths.CatalogsMaintenance,
          items: [
            {
              title: t("topBar.catalogsMaintenanceCatalog"),
              to: PagePaths.CatalogsMaintenanceCatalog,
              items: [],
              hidden: true,
            },
          ],
        },
        {
          title: t("topBar.pauseMaintenance"),
          to: PagePaths.PauseMaintenance,
          items: [],
        },
        {
          title: t("topBar.holidayMaintenance"),
          to: PagePaths.HolidayMaintenance,
          items: [
            {
              title: t("topBar.holidayMaintenanceHoliday"),
              to: PagePaths.HolidayMaintenanceHoliday,
              items: [],
              hidden: true,
            },
          ],
        },
        {
          title: t("topBar.executiveMaintenance"),
          to: PagePaths.ExecutiveMaintenance,
          items: [],
        },
        {
          title: t("topBar.flowMaintenance"),
          to: PagePaths.FlowMaintenance,
          items: [],
        },
        {
          title: t("topBar.groupMaintenance"),
          to: PagePaths.GroupMaintenance,
          items: [],
        },
        {
          title: t("topBar.loadMaintenance"),
          to: PagePaths.LoadMaintenance,
          items: [],
        },
        {
          title: t("topBar.fieldsMaintenance"),
          to: PagePaths.FieldsMaintenance,
          items: [],
        },
        {
          title: t("topBar.integrationsMaintenance"),
          to: PagePaths.IntegrationMaintenance,
          items: [
            {
              title: t("topBar.vitalPbx"),
              to: PagePaths.VitalPbxIntegration,
              items: [],
              hidden: true,
            },
            {
              title: t("topBar.vicidial"),
              to: PagePaths.VicidialIntegration,
              items: [],
              hidden: true,
            },
            {
              title: t("topBar.ucontact"),
              to: PagePaths.UcontactIntegration,
              items: [],
              hidden: true,
            },
            {
              title: t("topBar.docuware"),
              to: PagePaths.DocuwareIntegration,
              items: [],
              hidden: true,
            },
          ],
        },
        {
          title: t("topBar.personalizationMaintenance"),
          to: PagePaths.PersonalizationMaintenance,
          items: [],
        },
      ],
    },
  ] satisfies MenuItem[];
};
