import { FallbackComponent } from "~/components/FallbackComponent";
import { ErrorBoundary } from "@sentry/react";
import { StrictMode, Suspense } from "react";
import Skeleton from "@mui/material/Skeleton";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";

const CurrencyFormat = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/personalization-maintenance/_components/CurrencyFormat"
      )
    ).CurrencyFormat,
  };
});

const DateFormat = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/personalization-maintenance/_components/DateFormat"
      )
    ).DateFormat,
  };
});

const PromiseDateLimit = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/personalization-maintenance/_components/PromiseDateLimit"
      )
    ).PromiseDateLimit,
  };
});

const PaymentAgreementDateLimit = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/personalization-maintenance/_components/PaymentAgreementDateLimit"
      )
    ).PaymentAgreementDateLimit,
  };
});

export function Component() {
  return (
    <StrictMode>
      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("component", "currencyFormat");
        }}
        fallback={FallbackComponent}
      >
        <Suspense fallback={<Skeleton height={130} />}>
          <CurrencyFormat />
        </Suspense>
      </ErrorBoundary>
      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("component", "dateFormat");
        }}
        fallback={FallbackComponent}
      >
        <Suspense fallback={<Skeleton height={123} />}>
          <DateFormat />
        </Suspense>
      </ErrorBoundary>
      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("component", "promiseDateLimit");
        }}
        fallback={FallbackComponent}
      >
        <Suspense fallback={<Skeleton height={97} />}>
          <PromiseDateLimit />
        </Suspense>
      </ErrorBoundary>
      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("component", "paymentAgreementDateLimit");
        }}
        fallback={FallbackComponent}
      >
        <Suspense fallback={<Skeleton height={97} />}>
          <PaymentAgreementDateLimit />
        </Suspense>
      </ErrorBoundary>
    </StrictMode>
  );
}
