import { FallbackComponent } from "~/components/FallbackComponent";
import { Unauthorized } from "~/components/Unauthorized";
import { useAssignedStrategies } from "~/hooks/usePages";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import Skeleton from "@mui/material/Skeleton";
import { ErrorBoundary } from "@sentry/react";
import { Suspense, StrictMode } from "react";

const GroupSelection = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/assigned-strategies/_components/GroupSelection"
      )
    ).GroupSelection,
  };
});

const ContractDetails = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/assigned-strategies/_components/ContractDetails"
      )
    ).ContractDetails,
  };
});

export function Component() {
  const { selectedList, hasViewPermission } = useAssignedStrategies();

  if (!hasViewPermission) {
    return <Unauthorized />;
  }
  return (
    <>
      <StrictMode>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "groupselection");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={81} />}>
            <GroupSelection />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "contractdetails");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={283} />}>
            {selectedList !== "" && <ContractDetails />}
          </Suspense>
        </ErrorBoundary>
      </StrictMode>
    </>
  );
}

Component.displayName = "AssignedStrategies";
