import { FallbackComponent } from "~/components/FallbackComponent";
import { Unauthorized } from "~/components/Unauthorized";
import { useMarkClient } from "~/hooks/usePages";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import Skeleton from "@mui/material/Skeleton";
import { ErrorBoundary } from "@sentry/react";
import { Suspense, StrictMode } from "react";

const Filters = reactLazyWithRetry(async () => {
  return {
    default: (await import("~/app/(dashboard)/mark-client/_components/Filters"))
      .Filters,
  };
});

const Results = reactLazyWithRetry(async () => {
  return {
    default: (await import("~/app/(dashboard)/mark-client/_components/Results"))
      .Results,
  };
});

export function Component() {
  const { hasViewPermission } = useMarkClient();

  if (!hasViewPermission) {
    return <Unauthorized />;
  }
  return (
    <>
      <StrictMode>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "filters");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={91} />}>
            <Filters />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "results");
          }}
          fallback={FallbackComponent}
        >
          <Suspense fallback={<Skeleton height={283} />}>
            <Results />
          </Suspense>
        </ErrorBoundary>
      </StrictMode>
    </>
  );
}

Component.displayName = "MarkClient";
