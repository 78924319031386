import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import Skeleton from "@mui/material/Skeleton";
import { Suspense } from "react";

const SuspendedComponent = reactLazyWithRetry(async () => {
  return {
    default: (await import("~/app/(dashboard)/catalogs/_component"))
      .SuspendedComponent,
  };
});

export const Component = () => {
  return (
    <>
      <Suspense fallback={<Skeleton height={97} width="100%" />}>
        <SuspendedComponent />
      </Suspense>
    </>
  );
};

Component.displayName = "CatalogsMaintenance";
