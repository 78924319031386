import {
  UseRolesPermissionsServiceGetPermissionsByRoleKeyFn,
  UseUsersServiceGetAllUsersKeyFn,
} from "~/queries";
import {
  type GetPermissionsByRoleResponse,
  type OpenAPIConfig,
  RolesPermissionsService,
} from "~/requests";
import type { QueryClient } from "@tanstack/react-query";
import secureLocalStorage from "react-secure-storage";
import { request as __request } from "~/requests/core/request";
import type { GetAllUsersResponse } from "~/requests/types.gen";
import type { CancelablePromise } from "~/requests/core/CancelablePromise";
import { Interceptors } from "~/requests/core/OpenAPI";
import { redirect } from "react-router-dom";
import { apiVersion } from "~/config/api";

export const layoutLoader =
  (queryClient: QueryClient) =>
  async ({
    request,
  }: { request: Request }): Promise<GetPermissionsByRoleResponse[]> => {
    const url = new URL(request.url);
    const searchToken = url.searchParams.get("token");
    const jwtToken = JSON.parse(
      secureLocalStorage.getItem("jwtToken") as string,
    );
    const safeToken = searchToken ?? jwtToken;

    const OpenAPI: OpenAPIConfig = {
      BASE: import.meta.env.API_URL ?? "",
      CREDENTIALS: "include",
      ENCODE_PATH: undefined,
      HEADERS: undefined,
      PASSWORD: undefined,
      TOKEN: safeToken,
      USERNAME: undefined,
      VERSION: "1.0",
      WITH_CREDENTIALS: false,
      interceptors: {
        request: new Interceptors(),
        response: new Interceptors(),
      },
    };
    const loadedUser = JSON.parse(secureLocalStorage.getItem("user") as string);

    if (!loadedUser?.dbCatalog) {
      return redirect("/") as never;
    }

    const users = await queryClient.ensureQueryData({
      queryKey: UseUsersServiceGetAllUsersKeyFn({
        xDbCatalog: loadedUser.dbCatalog,
      }),
      queryFn: (): CancelablePromise<GetAllUsersResponse> => {
        return __request(OpenAPI, {
          method: "GET",
          url: `/api/v${apiVersion}/Users`,
          headers: {
            "X-DB-Catalog": loadedUser.dbCatalog,
          },
          errors: {
            400: "No results found.",
            404: "An error occurred while executing GetAllUsers",
          },
        });
      },
    });

    if (users.length === 0) {
      return redirect("/") as never;
    }

    const currentUser = users?.find(
      (user) => user.userCode === loadedUser.userName,
    )?.userRole;
    const userRoles = currentUser?.split(",");

    return await Promise.all(
      (userRoles ?? []).map((userRole) =>
        queryClient.ensureQueryData({
          queryKey: UseRolesPermissionsServiceGetPermissionsByRoleKeyFn({
            roleId: userRole,
            xDbCatalog: loadedUser.dbCatalog,
          }),
          queryFn: () =>
            RolesPermissionsService.getPermissionsByRole({
              roleId: userRole,
              xDbCatalog: loadedUser.dbCatalog,
            }),
        }),
      ),
    );
  };
