import { useUsersServiceGetAllUsers } from "~/queries";
import { userAtomState as userState } from "~/app/state/login";
import { useAtomValue } from "jotai";

export const useUsers = () => {
  const user = useAtomValue(userState);
  const { data, isLoading } = useUsersServiceGetAllUsers({
    xDbCatalog: user.dbCatalog,
  });
  return { data, isLoading };
};
