import Logo from "~/assets/LogoPayme.svg";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

function Component() {
  const { t } = useTranslation("login");
  return (
    <>
      <Box display="flex">
        <img className="logo" aria-label="logo" src={Logo} height="70px" />
      </Box>
      <Typography>
        {t("footer", { years: `2023 - ${new Date().getFullYear()}` })}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>{t("footer2")}</Typography>
        <Typography>
          <Link href="mailto:info@paymesoft.com" color="inherit">
            info@paymesoft.com
          </Link>
        </Typography>
      </Box>
    </>
  );
}

Component.displayName = "Footer";
export const Footer = Component;
