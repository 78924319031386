import { useAlert } from "~/app/useLogin";
import AlertComponent from "@mui/material/Alert";
import { useTranslation } from "react-i18next";

function Component() {
  const { showAlert } = useAlert();
  const { t } = useTranslation("login");

  return (
    <>
      {showAlert.currentAlert !== null && (
        <AlertComponent severity={showAlert[showAlert.currentAlert].severity}>
          {t(showAlert[showAlert.currentAlert].messageKey)}
        </AlertComponent>
      )}
    </>
  );
}

Component.displayName = "Alert";
export const Alert = Component;
