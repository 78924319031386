import type {
  StrategyListB,
  StrategyListInformationR,
  StrategyListR,
} from "~/types";
import { searchStrategyListUrl } from "~/utils/endpoints";
import { post } from "~/utils/fetchApi";
import { localStorageEffect } from "~/utils/localStorage";
import { atom, selector } from "recoil";
import { headersJsonState, userState } from "../../../state/login";
import { generateId } from "~/utils/generateId";

export const assignedStrategiesListState = selector<StrategyListR[]>({
  key: "assignedStrategiesList",
  get: async ({ get }) => {
    const headersJson = get(headersJsonState);
    const user = get(userState);
    const headersObj = Object.fromEntries(headersJson.entries());
    const body = await post<StrategyListR[], StrategyListB>({
      url: searchStrategyListUrl(),
      headers: new Headers({ ...headersObj, "X-OPERATION-ID": generateId() }),
      body: {
        username: user.userName,
      },
    });
    return body ?? [];
  },
});

export const selectedAssignedStrategyListState = atom<StrategyListR["codigo"]>({
  key: "selectedAssignedStrategy",
  default: "",
  effects: [localStorageEffect("selectedAssignedStrategy")],
});

export const assignedListContractsState = atom<StrategyListInformationR[]>({
  key: "assignedListContracts",
  default: [],
});

export const selectedAsiggnedContractsState = atom<StrategyListInformationR>({
  key: "selectedAsiggnedContracts",
  default: {
    secuencial: 0,
    cliente: 0,
    nombreCliente: "",
    saldoTotal: 0,
    saldoTotalMora: 0,
    diasMoraMax: 0,
    cantidadContratos: 0,
    estadoContrato: "",
    gestiones: 0,
    ultimaFechaGestion: "",
    fechaReagenda: "",
    contrato: "",
  },
});

export const loadingListStrategiesState = atom<boolean>({
  key: "assignedListLoadingListStrategies",
  default: false,
});
