import { FallbackComponent } from "~/components/FallbackComponent";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import Skeleton from "@mui/material/Skeleton";
import { ErrorBoundary } from "@sentry/react";
import { Suspense, StrictMode } from "react";

const Inputs = reactLazyWithRetry(async () => {
  return {
    default: (await import("~/app/(dashboard)/assign-lists/_components/Inputs"))
      .Inputs,
  };
});

const ExecutivesDistribution = reactLazyWithRetry(async () => {
  return {
    default: (
      await import(
        "~/app/(dashboard)/assign-lists/_components/ExecutivesDistribution"
      )
    ).ExecutivesDistribution,
  };
});

const ActiveLists = reactLazyWithRetry(async () => {
  return {
    default: (
      await import("~/app/(dashboard)/assign-lists/_components/ActiveLists")
    ).ActiveLists,
  };
});

const RecurrentLists = reactLazyWithRetry(async () => {
  return {
    default: (
      await import("~/app/(dashboard)/assign-lists/_components/RecurrentLists")
    ).RecurrentLists,
  };
});

export const Component = () => {
  return (
    <>
      <StrictMode>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "inputs");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={90} />}
          >
            <Inputs />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "executivesDistribution");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={125} />}
          >
            <ExecutivesDistribution />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "recurrentLists");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={125} />}
          >
            <RecurrentLists />
          </Suspense>
        </ErrorBoundary>
        <ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag("component", "activeLists");
          }}
          fallback={FallbackComponent}
        >
          <Suspense
            fallback={<Skeleton variant="rounded" width="100%" height={175} />}
          >
            <ActiveLists />
          </Suspense>
        </ErrorBoundary>
      </StrictMode>
    </>
  );
};

Component.displayName = "CatalogsMaintenance";
