import { useOtp } from "~/app/useLogin";
import Typography from "@mui/material/Typography";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useTranslation } from "react-i18next";

function Component() {
  const { showOtp, handleOtpChange, handleOtpComplete, otp } = useOtp();
  const { t } = useTranslation("login");

  return (
    <>
      {showOtp && (
        <>
          <Typography variant="h6">{t("otp")}</Typography>
          <MuiOtpInput
            length={6}
            sx={{
              ".MuiOtpInput-TextField": {
                minWidth: "auto",
              },
            }}
            onComplete={handleOtpComplete}
            value={otp}
            onChange={handleOtpChange}
          />
        </>
      )}
    </>
  );
}

Component.displayName = "Otp";
export const Otp = Component;
