import { useAppPersonalizationsServiceGetAppPersonalizations } from "~/queries";
import { userAtomState as userState } from "~/app/state/login";
import { useAtomValue } from "jotai";

export const useAppPersonalizations = (sequential: number) => {
  const user = useAtomValue(userState);
  const { data, isPending } =
    useAppPersonalizationsServiceGetAppPersonalizations({
      sequential,
      xDbCatalog: user.dbCatalog,
    });
  return { data, isPending };
};
