import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import Skeleton from "@mui/material/Skeleton";
import { Suspense } from "react";

const SuspendedComponent = reactLazyWithRetry(async () => {
  return {
    default: (await import("~/app/(dashboard)/catalogs.catalog/_component"))
      .SuspendedComponent,
  };
});
export const Component = () => {
  return (
    <>
      <Suspense
        fallback={
          <>
            <Skeleton height={316} width="100%" />
            <Skeleton height={181} width="100%" />
          </>
        }
      >
        <SuspendedComponent />
      </Suspense>
    </>
  );
};

Component.displayName = "CatalogsMaintenanceCatalogWord";
